import React, { useState, useEffect } from 'react';
import {
  Fab,
  IconButton,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { Box, Stack } from '@mui/system';
import { useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';
import DownloadIcon from '@mui/icons-material/Download';

import { UserListHead } from '../../sections/user';
import Scrollbar from '../Scrollbar';
import { downloadAttachmentFile, filterAssetChangelog, trackSubscription } from '../../services/Service';
import ErrorToast from '../Toast/Error';
import CustomRating from '../UI/CustomRating';
import { tableCellPStyle } from '../UI/CustomTableView';
import { LightTooltip } from '../../sections/ReportsTabs/LogReport';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #FFF',
  borderRadius: '10px',
  boxShadow: 24,
  p: '30px',
  // overflowY:"Auto"
};

const TrackModal = ({ openTrackModal, onClose, setOpenTrackModal, subName, data, moduleNameType }) => {
  // if (subName === undefined) {
  //   subName = data?.cardId?.type;
  // }

  const [logdata, setLogdata] = useState([]);
  const { user } = useSelector((state) => state.login);

  const matchData = {
    isLiked: 'Safals',
    dateOfBirth: 'Date of Birth ',
    gender: 'Gender',
    firstName: 'First Name',
    lastName: 'Last Name',
    phoneNo: 'Phone Number',
    email: 'Email',
    rating: 'Rating',
    frequency: 'Frequency',
    status: 'Status',
    autoRenewal: 'Auto renewal',
    amount: 'Price',
    currencyAbr: 'Currency',
    startDate: 'Start Date',
    nextBilling: 'Next Billing Date',
    review: 'Review',
    comments: 'Comments',
    attachment: 'Attachment',
  };

  const conveter = (fieldname, val) => {
    switch (fieldname) {
      case 'autoRenewal':
        return val === 'true' ? 'Yes' : 'No';
      case 'isLiked':
        return val === '1' ? 'Liked' : val === '-1' ? 'Disliked' : 'Neutral';
      case 'startDate':
        return moment(val).format('YYYY-MM-DD');
      case 'nextBilling':
        return moment(val).format('YYYY-MM-DD');
      default:
        return val;
    }
  };

  const EventConverter = (edata) => {
    const keys = Object.keys(matchData);
    const data = edata.map((val) => {
      switch (val.ModuleName) {
        case 'login':
          return { ...val, event: 'You logged in' };
        case 'EditSub': {
          const data = {
            ...val,
            event: `You Edited ${val.subName} & changed ${matchData[val.event]}`,
            oldValue: conveter(val.event, val.oldValue),
            newValue: conveter(val.event, val.newValue),
          };
          return data;
        }
        case 'AddSub':
          return { ...val, event: `You added ${val.subName}` };
        case 'DeleteSub':
          return { ...val, event: `You deleted ${val.subName}` };
        case 'changeBudget':
          return { ...val, event: `You changed budget` };
        case 'EditPic':
          return { ...val, event: `You changed profile picture` };
        case 'personalDetails': {
          const data = {
            ...val,
            event: `You changed personal details & changed ${
              keys.includes(val.event) ? matchData[val.event] : val.event
            }`,
            oldValue: conveter(val.event, val.oldValue),
            newValue: conveter(val.event, val.newValue),
          };

          return data;
          // ...val,
          // event: `You changed personal details & changed ${
          //   keys.includes(val.event) ? matchData[val.event] : val.event
          // }`,
        }
        case 'logout':
          return { ...val, event: `You logged out` };
        case 'downloadSubdata':
          // return { ...val, event: 'You downloaded Subscription data' };
          return { ...val, event: val.event };
        case 'downloadReportsdata':
          // return { ...val, event: 'You downloaded Changelog/Activity Report' };
          return { ...val, event: val.event };
        default:
          return { ...val, event: matchData[val.event] };
      }
    });

    setLogdata(data);
  };

  useEffect(() => {
    if (!data) {
      trackSubscription({ subName })
        .then((res) => {
          if (res.data?.success === true) {
            EventConverter(res.data?.data);
          }
        })
        .catch((err) => {
          console.log(err);
          ErrorToast('error while fetching data');
        });
    }
    if (moduleNameType === 'SafalWarranty' && openTrackModal) {
      filterAssetChangelog({ ModuleName: 'SafalWarranty', id: data?._id })
        .then((res) => {
          if (res.data?.success === true) {
            EventConverter(res.data?.data);
          }
        })
        .catch((err) => {
          console.log(err);
          ErrorToast('error while fetching data');
        });
    } else if (data && openTrackModal) {
      const ModuleNameX =
        data?.cardId?.type === 'loyalty'
          ? 'loyalty card'
          : data?.cardId?.type === 'gift'
          ? 'gift card'
          : 'reward certificate';
      filterAssetChangelog({ ModuleName: ModuleNameX, id: data?._id })
        .then((res) => {
          if (res.data?.success === true) {
            EventConverter(res.data?.data);
          }
        })
        .catch((err) => {
          console.log(err);
          ErrorToast('error while fetching data');
        });
    }
  }, [openTrackModal, subName, data, moduleNameType]);

  useEffect(() => {
    if (openTrackModal && data?.uniqueId) {
      fetchDataChangelog();
    }
  }, [openTrackModal, data?.cardId?.type]);

  const handleDownload = (url) => {
    console.log('url', url);
    downloadAttachmentFile({ url })
      .then((res) => {
        console.log('this is attachment download => ', res.data);
        if (res.data.success === true) {
          const link = document.createElement('a');
          link.href = res.data.data;
          link.setAttribute('download', `file`);
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch((err) => {
        console.log('this is attachment err => ', err);
        ErrorToast(err.response.data.message || 'Something went wrong !!');
      });
  };

  const fetchDataChangelog = async () => {
    try {
      const ModuleNameX =
        data?.cardId?.type === 'loyalty'
          ? 'loyalty card'
          : data?.cardId?.type === 'gift'
          ? 'gift card'
          : 'reward certificate';
      // const res = await filterAssetChangelog({ "event": data?.uniqueId });
      const res = await filterAssetChangelog({ ModuleName: ModuleNameX, id: data?._id });
      if (res.data?.success === true) {
        EventConverter(res.data?.data);
      }
    } catch (err) {
      console.log(err);
      ErrorToast('error while fetching data changelog');
    }
  };

  const handleClose = () => {
    if (setOpenTrackModal) {
      setOpenTrackModal(false);
    }
    if (onClose) onClose();
  };

  const TABLE_HEAD = [
    { id: 'changeField', label: 'Change Field', alignRight: true },
    { id: 'fromValue', label: 'From Value ', alignRight: false },
    { id: 'toValue', label: 'To Value', alignRight: false },
    { id: 'dateTime', label: 'Date & Time', alignRight: false },
  ];

  const renderNewValue = (row) => {
    if (row?.message?.includes('Safals') && row?.newValue === '1') {
      return <img src="/assets/images/Like.png" alt="Liked" />;
    }
    if (row?.message?.includes('Safals') && row?.newValue === '-1') {
      return <img src="/assets/images/disLike.png" alt="Disliked" />;
    }
    if (row?.message?.includes('Safals') && row?.newValue === '0') {
      return (
        <div style={{ display: 'flex' }}>
          <img src="/assets/images/Like.png" alt="Like Symbol" style={{ filter: 'grayscale(100%)' }} />
          <img src="/assets/images/disLike.png" alt="DisLike Symbol" style={{ filter: 'grayscale(100%)' }} />
        </div>
      );
    }
    if (row?.message?.includes('changed Rating')) {
      return <CustomRating precision={0.5} size="small" readOnly value={row?.newValue} />;
    }
    if (row?.message?.includes('changed Attachment')) {
      const filesLink = row?.newValue?.split(',');
      const filterFilesLink = filesLink.filter((ex) => ex.includes('https://'));
      if (filterFilesLink.length === 0) return row?.newValue;
      return (
        <>
          {filterFilesLink.map((e) => {
            const filename = e.split('/')[e.split('/').length - 1];
            const newFileName = filename.split('-').slice(1).join('-');
            return (
              <>
                {newFileName}
                <IconButton size="small" onClick={() => handleDownload(e)}>
                  <DownloadIcon />
                </IconButton>
              </>
            );
          })}
        </>
      );
    }
    if (row?.newValue === '') return 'N/A';
    return row?.newValue;
  };
  const renderOldValue = (row) => {
    if (row?.message?.includes('Safals') && row?.oldValue === '1') {
      return <img src="/assets/images/Like.png" alt="Liked" />;
    }
    if (row?.message?.includes('Safals') && row?.oldValue === '-1') {
      return <img src="/assets/images/disLike.png" alt="Disliked" />;
    }
    if (row?.message?.includes('Safals') && row?.oldValue === '0') {
      return (
        <div style={{ display: 'flex' }}>
          <img src="/assets/images/Like.png" alt="Like Symbol" style={{ filter: 'grayscale(100%)' }} />
          <img src="/assets/images/disLike.png" alt="DisLike Symbol" style={{ filter: 'grayscale(100%)' }} />
        </div>
      );
    }
    if (row?.message?.includes('changed Rating')) {
      return <CustomRating precision={0.5} size="small" readOnly value={row?.oldValue} />;
    }
    if (row?.message?.includes('changed Attachment')) {
      const filesLink = row?.oldValue?.split(',');
      const filterFilesLink = filesLink.filter((ex) => ex.includes('https://'));
      if (filterFilesLink.length === 0) return row?.oldValue;
      return (
        <>
          {filterFilesLink.map((e) => {
            const filename = e.split('/')[e.split('/').length - 1];
            const newFileName = filename.split('-').slice(1).join('-');
            return newFileName;
          })}
        </>
      );
    }
    if (row?.oldValue === '') return 'N/A';
    return row?.oldValue;
  };

  return (
    <>
      <Modal
        open={openTrackModal}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        onClose={handleClose}
      >
        <Box
          id="scrollbar"
          sx={{ ...style, maxHeight: '700px', width: { xs: '100%', sm: '500px', md: '800px', lg: '1000px' } }}
        >
          <Stack direction="row" justifyContent="space-between">
            <Box>
              <Typography variant="h4" gutterBottom sx={{ fontSize: '30px', fontWeight: 700, color: '#3D71FF' }}>
                Changelog of {subName || data?.companyId?.name}
              </Typography>
            </Box>
            <Fab onClick={handleClose} size="small" color="primary" aria-label="add">
              <CloseIcon />
            </Fab>
          </Stack>

          <Box sx={{ maxHeight: '500px' }}>
            <TableContainer sx={{ mt: 2 }}>
              <Scrollbar sx={{ maxHeight: '480px', paddingRight: '12px' }}>
                <Table stickyHeader sx={{ borderCollapse: 'separate', borderSpacing: '0 10px' }}>
                  <UserListHead isCheckListRemove="true" headLabel={TABLE_HEAD} sx={{ position: 'sticky' }} />
                  <TableBody>
                    {logdata.length > 0 ? (
                      // logdata.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                      logdata.map((row, index) => (
                        <TableRow hover key={index} tabIndex={-1} role="checkbox">
                          <TableCell
                            align="left"
                            sx={{
                              backgroundColor: '#FFFFFF',
                            }}
                          >
                            <p style={tableCellPStyle}>{row?.message}</p>
                          </TableCell>
                          <LightTooltip title={row?.oldValue || 'N/A'} placement="bottom">
                            <TableCell
                              align="left"
                              sx={{
                                backgroundColor: '#FFFFFF',
                              }}
                            >
                              <p style={tableCellPStyle}>{renderOldValue(row)}</p>
                            </TableCell>
                          </LightTooltip>
                          <LightTooltip title={row?.newValue || 'N/A'} placement="bottom">
                            <TableCell
                              align="left"
                              sx={{
                                backgroundColor: '#FFFFFF',
                              }}
                            >
                              <p style={tableCellPStyle}>{renderNewValue(row)}</p>
                            </TableCell>
                          </LightTooltip>
                          <TableCell
                            align="left"
                            sx={{
                              backgroundColor: '#FFFFFF',
                            }}
                          >
                            <p style={tableCellPStyle}>
                              {moment(row?.createdAt).format(
                                user.country === 'India' ? 'DD/MM/yyyy, hh:mm A' : 'MM/DD/yyyy, hh:mm A'
                              )}
                            </p>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableCell align="center" colSpan={16} sx={{ borderRadius: '35px', backgroundColor: '#FFFFFF' }}>
                        No change log found
                      </TableCell>
                    )}
                  </TableBody>
                </Table>
              </Scrollbar>
            </TableContainer>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default TrackModal;
