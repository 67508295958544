import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  IconButton,
  ListItemText,
  Typography,
  useTheme,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
} from '@mui/material';
import moment from 'moment';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ListItemIcon from '@mui/material/ListItemIcon';
import MailOutlineIcon from '@mui/icons-material/MailOutline'; // Replace with the appropriate icon

import { useDispatch, useSelector } from 'react-redux';
import RemoveIcon from '@mui/icons-material/Remove';
import { Close } from '@mui/icons-material';
import Scrollbar from '../../components/Scrollbar';
import DismissModal from '../../components/Modals/DismissModal';
import { MarkAsReadNotification } from '../../services/Service';
import SuccessToast from '../../components/Toast/Success';
import ErrorToast from '../../components/Toast/Error';
import { getNotifications } from '../../store/loginSlice';
import EmailIcon from '../../assets/svgs/Notification/EmailVerifyIcon.svg';
import BudgetExceedIcon from '../../assets/svgs/Notification/BudgetExceedIcon.png';
import General from '../../assets/svgs/Notification/General.svg';
import Feedback from '../../assets/svgs/Notification/Feedback.svg';
import PrimaryEmailverify from '../../assets/svgs/Notification/PrimaryEmailverify.svg';
import assetIconNew from '../../assets/svgs/Notification/asset.png';
import offersIconNew from '../../assets/offer_blue_icon.png';
import Promotions from '../../assets/svgs/Notification/Promotions.png';
import Subscription from '../../assets/svgs/Notification/Subscription.svg';
import UserChat from '../../assets/svgs/Notification/UserChat.svg';
import UserCall from '../../assets/svgs/Notification/UserCall.png';
import SafalLeaderBoard_Call_WHITE from '../../assets/SafalLeaderBoard_Call_WHITE.png';
import BulkUpload from '../../assets/svgs/Notification/BulkUpload.png';
import FaqModal from '../../components/Modals/FaqModal';
import leaderBoard_black from '../../assets/svgs/Notification/leader.png';
import buddy_blue from '../../assets/svgs/Notification/safalBuddy.png';

export default function Notifications() {
  const user = useSelector((state) => state?.login?.user);
  const { notification } = useSelector((state) => state?.login);

  const dispatch = useDispatch();
  const [openDismiss, setOpenDismiss] = useState(false);
  const [notificationIDs, setNotificationIDs] = useState([]);
  const theme = useTheme();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    const ids = Object.keys(notification).map((val) => notification[val]._id);
    setNotificationIDs(ids);
  }, [notification, notification]);

  const handleRead = (id) => {
    MarkAsReadNotification({ id: id }).then((res) => {
      if (res.data.success === true) {
        SuccessToast('dismissed');
        dispatch(getNotifications()).unwrap();
      } else {
        ErrorToast(res.data.message);
      }
    });
  };

  return (
    <div>
      <Box
        sx={{
          height: '100%',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            height: '100%',
            width: '60%',
            '@media (max-width: 600px)': {
              width: '100%',
            },
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontSize: '24px',
              alignItems: 'center',
              display: 'flex',
              fontWeight: 700,
              color: theme.palette.secondary.title,
            }}
          >
            Notification <FaqModal module="Settings - Notification" color="#3D71FF" />
          </Typography>
          <Box sx={{ width: '100%', height: '719px', backgroundColor: '#FFFFFF', borderRadius: '10px', mt: 3 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '700px', p: 3 }}>
              <Button
                onClick={() => {
                  setOpenDismiss(true);
                }}
                variant="standard"
                startIcon={<RemoveIcon sx={{ border: '2.5px solid', borderRadius: '4.5px' }} />}
                sx={{
                  color: theme.palette.secondary.title,
                  fontWeight: 700,
                  textTransform: 'none',
                  alignSelf: 'flex-end',
                }}
              >
                Dismiss All
              </Button>
              <Scrollbar>
                <NotificationAccordion
                  expanded={expanded === 'panel1'}
                  onChange={handleChange('panel1')}
                  title="Addon Email Verify"
                  type="addonEmailVerify"
                  handleRead={handleRead}
                  notification={notification}
                  user={user}
                />
                <NotificationAccordion
                  expanded={expanded === 'panel2'}
                  onChange={handleChange('panel2')}
                  title="Budget Exceed"
                  type="budgetExceed"
                  handleRead={handleRead}
                  notification={notification}
                  user={user}
                />
                <NotificationAccordion
                  expanded={expanded === 'panel3'}
                  onChange={handleChange('panel3')}
                  title="Bulk Upload"
                  type="bulkUpload"
                  handleRead={handleRead}
                  notification={notification}
                  user={user}
                />
                <NotificationAccordion
                  expanded={expanded === 'panel5'}
                  onChange={handleChange('panel5')}
                  title="Subscriptions"
                  type="expiredOrRenew"
                  handleRead={handleRead}
                  notification={notification}
                  user={user}
                />
                <NotificationAccordion
                  expanded={expanded === 'panel6'}
                  onChange={handleChange('panel6')}
                  title="Feedback"
                  type="feedback"
                  handleRead={handleRead}
                  notification={notification}
                  user={user}
                />
                <NotificationAccordion
                  expanded={expanded === 'panel8'}
                  onChange={handleChange('panel8')}
                  title="Primary Email Verify"
                  type="primaryEmailVerify"
                  handleRead={handleRead}
                  notification={notification}
                  user={user}
                />
                <NotificationAccordion
                  expanded={expanded === 'panel9'}
                  onChange={handleChange('panel9')}
                  title="Promotions"
                  type="promotions"
                  handleRead={handleRead}
                  notification={notification}
                  user={user}
                />
                <NotificationAccordion
                  expanded={expanded === 'panel10'}
                  onChange={handleChange('panel10')}
                  title="User Chat"
                  type="userChat"
                  handleRead={handleRead}
                  notification={notification}
                  user={user}
                />
                <NotificationAccordion
                  expanded={expanded === 'panel16'}
                  onChange={handleChange('panel16')}
                  title="User Call"
                  type="userCall"
                  handleRead={handleRead}
                  notification={notification}
                  user={user}
                />
                <NotificationAccordion
                  expanded={expanded === 'panel11'}
                  onChange={handleChange('panel11')}
                  title="General"
                  type="general"
                  handleRead={handleRead}
                  notification={notification}
                  user={user}
                />
                <NotificationAccordion
                  expanded={expanded === 'panel12'}
                  onChange={handleChange('panel12')}
                  title="Assets"
                  type="expireCard"
                  handleRead={handleRead}
                  notification={notification}
                  user={user}
                />
                <NotificationAccordion
                  expanded={expanded === 'panel13'}
                  onChange={handleChange('panel13')}
                  title="Offers"
                  type="offersExpire"
                  handleRead={handleRead}
                  notification={notification}
                  user={user}
                />
                <NotificationAccordion
                  expanded={expanded === 'panel14'}
                  onChange={handleChange('panel14')}
                  title="SafalBuddy"
                  type="safalBuddy"
                  handleRead={handleRead}
                  notification={notification}
                  user={user}
                />
                <NotificationAccordion
                  expanded={expanded === 'panel15'}
                  onChange={handleChange('panel15')}
                  title="SafalLeaderBoard"
                  type="safalLeaderBoard"
                  handleRead={handleRead}
                  notification={notification}
                  user={user}
                />
                <NotificationAccordion
                  expanded={expanded === 'panel16'}
                  onChange={handleChange('panel16')}
                  title="Warranty"
                  type="warranty"
                  handleRead={handleRead}
                  notification={notification}
                  user={user}
                />
              </Scrollbar>
            </Box>
          </Box>
        </Box>
      </Box>
      <DismissModal openDismiss={openDismiss} setOpenDismiss={setOpenDismiss} ids={notificationIDs} />
    </div>
  );
}

const NotificationAccordion = ({ notification, type, onChange, expanded, handleRead, user, title }) => {
  const notificationKeys = Object.keys(notification).filter((data) => notification[data].type === type);
  const notificationArray = notificationKeys.map((e) => notification[e]);
  const notificationArrayFilter = notificationArray.filter((e) => !e.isRead);
  const iconMap = {
    addonEmailVerify: EmailIcon,
    budgetExceed: BudgetExceedIcon,
    bulkUpload: BulkUpload,
    general: General,
    feedback: Feedback,
    primaryEmailVerify: PrimaryEmailverify,
    promotions: Promotions,
    expiredOrRenew: Subscription,
    userChat: UserChat,
    userCall: UserCall,
    expireCard: assetIconNew,
    offersExpire: offersIconNew,
    safalBuddy: buddy_blue,
    safalLeaderBoard: leaderBoard_black,
    warranty: leaderBoard_black,
  };

  return (
    <Accordion expanded={expanded} onChange={onChange}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
        <>
          {iconMap[type] && (
            <img src={iconMap[type]} alt="img" style={{ height: '40px', width: '40px', borderRadius: '999px' }} />
          )}
        </>
        <Typography
          sx={{
            fontSize: 14,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: '10px',
            '@media (max-width: 600px)': {
              fontSize: 12,
            },
          }}
        >
          {title} <span style={{ fontSize: 12 }}>({notificationArrayFilter.length})</span>
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          fontSize: 18,
          '@media (max-width: 600px)': {
            fontSize: 8,
          },
        }}
      >
        <List sx={{ maxHeight: '100%', height: 'auto', overflowY: 'auto' }}>
          <Scrollbar>
            {notificationArrayFilter.map((val, index) => (
              <ListItem
                key={index}
                secondaryAction={
                  <IconButton
                    onClick={() => handleRead(val._id)}
                    aria-label="close"
                    sx={{
                      color: 'red',
                      '@media (max-width: 600px)': {
                        top: -10,
                        left: 10,
                      },
                    }}
                  >
                    <Close
                      sx={{
                        fontSize: 18,
                        '@media (max-width: 600px)': {
                          fontSize: 18,
                        },
                      }}
                    />
                  </IconButton>
                }
              >
                {val?.logo && (
                  <ListItemAvatar>
                    <Avatar>
                      <img src={val?.logo} alt="Company Logo" />
                    </Avatar>
                  </ListItemAvatar>
                )}
                <ListItemText primary={val.message} secondary={moment(val.createdAt).format('LLLL')} />
              </ListItem>
            ))}
          </Scrollbar>
        </List>
      </AccordionDetails>
    </Accordion>
  );
};
