import React, { useEffect, useState } from 'react';
import {
  Fab,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Button,
  FormControl,
  InputLabel,
  NativeSelect,
} from '@mui/material';
import _ from 'lodash';
import Scrollbar from '../../components/Scrollbar';
import { getNotificationConfig, updateNotificationConfig, notifyEachUser } from '../../services/Service';
import SuccessToast from '../../components/Toast/Success';

const BusinessNotifications = () => {
  const TABLE_BODY_INIT = [
    {
      id: 'budgetExceed',
      catagory: 'Event',
      desc: 'Over the Budget',
      email: true,
      text: true,
      whatsapp: true,
      mobileApp: true,
      notification: true,
      banner: true,
    },
    {
      id: 'subscriptionExpire',
      catagory: 'Event',
      desc: 'Subscription about to expire in (7 days)',
      email: true,
      text: true,
      whatsapp: true,
      mobileApp: true,
      notification: true,
      banner: true,
    },
    {
      id: 'subscriptionRenewal',
      catagory: 'Event',
      desc: 'Subscription is due for renewal ',
      email: true,
      text: true,
      whatsapp: true,
      mobileApp: true,
      notification: true,
      banner: true,
    },
    {
      id: 'primaryEmailVerify',
      catagory: 'User',
      desc: 'Primary Email Not Verified ',
      email: true,
      text: true,
      whatsapp: true,
      mobileApp: true,
      notification: true,
      banner: true,
    },
    {
      id: 'addonEmailVerify',
      catagory: 'User',
      desc: 'Add-On Emails Not Verified',
      email: true,
      text: true,
      whatsapp: true,
      mobileApp: true,
      notification: true,
      banner: true,
    },
    {
      id: 'inActiveUser',
      catagory: 'User',
      desc: 'Not login into your account since 45 days',
      email: true,
      text: true,
      whatsapp: true,
      mobileApp: true,
      notification: true,
      banner: true,
    },
    {
      id: 'userChat',
      catagory: 'User',
      desc: 'User chat',
      email: true,
      text: true,
      whatsapp: true,
      mobileApp: true,
      notification: true,
      banner: true,
    },
    {
      id: 'promotion',
      catagory: 'Information',
      desc: 'Promotion(s) available for Subscription  in your account',
      email: true,
      text: true,
      whatsapp: true,
      mobileApp: true,
      notification: true,
      banner: true,
    },
    {
      id: 'offersExpire',
      catagory: 'User',
      desc: 'Offers expire in (7 days)',
      email: false,
      text: false,
      whatsapp: false,
      mobileApp: false,
      notification: false,
      banner: false,
    },
    {
      id: 'giftCard',
      catagory: 'User',
      desc: 'Gift card expire in (7 days)',
      email: true,
      text: true,
      whatsapp: true,
      mobileApp: true,
      notification: true,
      banner: true,
    },
    {
      id: 'rewardPoint',
      catagory: 'User',
      desc: 'Reward certificate expire in (7 days)',
      email: true,
      text: true,
      whatsapp: true,
      mobileApp: true,
      notification: true,
      banner: true,
    },
    // {
    //   id: 'warranty',
    //   catagory: 'User',
    //   desc: 'Warranty Reminder',
    //   email: true,
    //   text: true,
    //   whatsapp: true,
    //   mobileApp: true,
    //   notification: true,
    //   banner: true,
    // },
  ];
  const [data, setData] = useState([]);
  const [tier, setTier] = useState('paidTier');
  const [tableBody, setTableBody] = useState();
  const [toast, setToast] = useState(false);
  const [media, setMedia] = useState('');
  const [isEnableMedia, setIsEnableMedia] = useState('');
  const [mediaDesc, setMediaDesc] = useState('');

  useEffect(() => {
    submitChanges();
  }, [tableBody]);

  useEffect(() => {
    getNotificationConfig().then((res) => {
      setData(res.data[tier]);
      const tableData = [];
      Object.keys(res?.data[tier]).map((d) => {
        console.log(d);
        let modifyObj = TABLE_BODY_INIT.find((e) => e.id === d);

        if (modifyObj) {
          modifyObj = {
            ...modifyObj,
            ...res.data[tier][d],
          };
          tableData.push(modifyObj);

          return true;
        }
        return false;
      });
      setTableBody(tableData);
    });
  }, [tier]);

  const handleChange = (e, val) => {
    console.log(val);
    // eslint-disable-line no-use-before-define
    const isEnabled = e.target.value !== 'on';
    console.log(isEnabled);
    const modifyObj = {
      ...val,
      [e.target.name]: e.target.checked,
    };
    const tableData = tableBody;
    tableData[tableData.findIndex((d) => d.id === val.id)] = modifyObj;
    console.log(tableData);
    setToast(true);
    setTableBody([...tableData]);
    setMedia(e.target.name);
    setIsEnableMedia(e.target.checked);
    setMediaDesc(val.desc);
  };

  const submitChanges = async () => {
    console.log(tableBody);
    let updatedChanges = {};
    tableBody.map((d) => {
      updatedChanges = {
        ...updatedChanges,
        [d.id]: _.omit(d, ['catagory', 'desc', 'id']),
      };
      return null;
    });
    const request = {
      [tier]: updatedChanges,
    };
    const response = await updateNotificationConfig(request);
    console.log(response.status);
    if (response.status === 200) {
      const payload = {
        tier: tier,
        event: mediaDesc,
        medium: media,
        isEnabled: isEnableMedia,
      };

      await notifyEachUser(payload);

      toast && SuccessToast('Updated Successfully!');
      setToast(false);
    } else {
      console.log('Update failed!');
      // Handle the failed update case
    }
  };

  return (
    <>
      <FormControl style={{ width: '20%', marginBottom: 30 }}>
        <InputLabel variant="standard" htmlFor="uncontrolled-native">
          User Type
        </InputLabel>
        <NativeSelect
          onChange={(e) => {
            setTier(e.target.value);
          }}
          defaultValue={'payTier'}
          inputProps={{
            name: 'Status',
            id: 'uncontrolled-native',
          }}
        >
          <option value={'paidTier'}>Basic User</option>
          <option value={'premiumTier'}>Premium User</option>
          <option value={'freeTier'}>Free User</option>
          <option value={'betaTier'}>Beta User</option>
        </NativeSelect>
      </FormControl>
      <TableContainer sx={{ minWidth: 800, overflowX: 'auto', maxHeight: '90vh' }}>
        <Scrollbar>
          <Table stickyHeader aria-label="sticky table" sx={{ borderCollapse: 'separate', borderSpacing: '0 10px' }}>
            {/* <UserListHead
            order={order}
            orderBy={orderBy}
            headLabel={TABLE_HEAD}
            // rowCount={filteredSubs.length}
            rowCount={10}
            numSelected={selected.length}
            onRequestSort={handleRequestSort}
            onSelectAllClick={handleSelectAllClick}
          /> */}
            <TableHead style={{ padding: '20px' }}>
              <TableRow>
                <TableCell
                  rowSpan={2}
                  sx={{
                    backgroundColor: '#EAFFEA',
                    color: '#027C34',
                    borderBottomLeftRadius: '35px',
                    borderTopLeftRadius: '35px',
                    fontWeight: 700,
                  }}
                >
                  Notifications Category
                </TableCell>
                <TableCell
                  // rowSpan={2}
                  sx={{
                    backgroundColor: '#EAFFEA',
                    color: '#027C34',
                    fontWeight: 700,
                  }}
                >
                  Notification Description
                </TableCell>
                <TableCell
                  sx={{
                    backgroundColor: '#EAFFEA',
                    color: '#027C34',
                    fontWeight: 700,
                  }}
                >
                  Email
                </TableCell>
                <TableCell sx={{ backgroundColor: '#EAFFEA', color: '#027C34', fontWeight: 700 }}>Text</TableCell>
                <TableCell sx={{ backgroundColor: '#EAFFEA', color: '#027C34', fontWeight: 700 }}>Whatsapp</TableCell>
                <TableCell sx={{ backgroundColor: '#EAFFEA', color: '#027C34', fontWeight: 700 }}>Mobile App</TableCell>
                <TableCell sx={{ backgroundColor: '#EAFFEA', color: '#027C34', fontWeight: 700 }}>
                  Notif. Screen
                </TableCell>
                <TableCell
                  sx={{
                    backgroundColor: '#EAFFEA',
                    color: '#027C34',
                    fontWeight: 700,
                  }}
                >
                  Notif. Banner
                </TableCell>
                <TableCell sx={{ backgroundColor: '#EAFFEA', color: '#027C34', fontWeight: 700 }}>Slack</TableCell>
                <TableCell sx={{ backgroundColor: '#EAFFEA', color: '#027C34', fontWeight: 700 }}>Twitter</TableCell>
                <TableCell sx={{ backgroundColor: '#EAFFEA', color: '#027C34', fontWeight: 700 }}>Instagram</TableCell>
                <TableCell sx={{ backgroundColor: '#EAFFEA', color: '#027C34', fontWeight: 700 }}>Messenger</TableCell>
                <TableCell sx={{ backgroundColor: '#EAFFEA', color: '#027C34', fontWeight: 700 }}>Snapchat</TableCell>
                <TableCell sx={{ backgroundColor: '#EAFFEA', color: '#027C34', fontWeight: 700 }}>LinkedIn</TableCell>
                <TableCell
                  sx={{
                    backgroundColor: '#EAFFEA',
                    color: '#027C34',
                    fontWeight: 700,
                    borderBottomRightRadius: '35px',
                    borderTopRightRadius: '35px',
                  }}
                >
                  Discord
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {/* {const isItemSelected = selected.indexOf(row._id) !== -1;} */}
              {tableBody?.map((val) => (
                <TableRow
                  hover
                  key={1}
                  tabIndex={-1}
                  // role="checkbox"
                  // selected={isItemSelected}
                  // aria-checked={isItemSelected}
                  style={{
                    backgroundColor: '#FFFFFF',
                    borderBottomLeftRadius: '200px',
                    borderTopLeftRadius: '200px',
                    paddingLeft: '30px',
                    borderRadius: '200px',
                    boxShadow: 'rgb(100 100 111 / 23%) 0px 0px 8px 2px',
                  }}
                >
                  <TableCell
                    padding="none"
                    sx={{
                      backgroundColor: '#FFFFFF',
                      borderBottomLeftRadius: '35px',
                      borderTopLeftRadius: '35px',
                      paddingTop: 0,
                      paddingBottom: 0,
                      whiteSpace: 'nowrap',
                      paddingRight: 0,
                      paddingLeft: 0,
                    }}
                  >
                    <Typography variant="subtitle2" noWrap sx={{ marginLeft: 5 }}>
                      {val.catagory}
                    </Typography>
                  </TableCell>
                  <TableCell component="th" scope="row" padding="none" sx={{ backgroundColor: '#FFFFFF', p: 0 }}>
                    {val.desc}
                  </TableCell>

                  <TableCell component="th" scope="row" padding="none" sx={{ backgroundColor: '#FFFFFF', p: 0 }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Typography variant="subtitle2" noWrap>
                        <Switch
                          name={'email'}
                          onChange={(e) => handleChange(e, val)}
                          sx={{ m: 1 }}
                          checked={val.email}
                          color="success"
                        />
                      </Typography>
                    </div>
                  </TableCell>
                  <TableCell component="th" scope="row" padding="none" sx={{ backgroundColor: '#FFFFFF', p: 0 }}>
                    <Typography variant="subtitle2" noWrap>
                      <Switch
                        name={'text'}
                        onChange={(e) => handleChange(e, val)}
                        sx={{ m: 1 }}
                        checked={val.text}
                        color="success"
                      />
                    </Typography>
                  </TableCell>
                  <TableCell component="th" scope="row" padding="none" sx={{ backgroundColor: '#FFFFFF', p: 0 }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Typography variant="subtitle2" noWrap>
                        <Switch
                          name={'whatsapp'}
                          onChange={(e) => handleChange(e, val)}
                          sx={{ m: 1 }}
                          checked={val.whatsapp}
                          color="success"
                        />
                      </Typography>
                    </div>
                  </TableCell>
                  <TableCell component="th" scope="row" padding="none" sx={{ backgroundColor: '#FFFFFF', p: 0 }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Typography variant="subtitle2" noWrap>
                        <Switch
                          name={'mobileApp'}
                          onChange={(e) => handleChange(e, val)}
                          sx={{ m: 1 }}
                          checked={val.mobileApp}
                          color="success"
                        />
                      </Typography>
                    </div>
                  </TableCell>
                  <TableCell component="th" scope="row" padding="none" sx={{ backgroundColor: '#FFFFFF', p: 0 }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Switch
                        name={'notification'}
                        onChange={(e) => handleChange(e, val)}
                        checked={val.notification}
                        color="success"
                      />
                    </div>
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      backgroundColor: '#FFFFFF',
                    }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Switch
                        name={'banner'}
                        onChange={(e) => handleChange(e, val)}
                        checked={val.banner}
                        color="success"
                      />
                    </div>
                  </TableCell>
                  <TableCell component="th" scope="row" padding="none" sx={{ backgroundColor: '#FFFFFF', p: 0 }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Switch
                        name={'slack'}
                        onChange={(e) => handleChange(e, val)}
                        checked={val.slack}
                        color="success"
                      />
                    </div>
                  </TableCell>
                  <TableCell component="th" scope="row" padding="none" sx={{ backgroundColor: '#FFFFFF', p: 0 }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Switch
                        name={'twitter'}
                        onChange={(e) => handleChange(e, val)}
                        checked={val.twitter}
                        color="success"
                      />
                    </div>
                  </TableCell>
                  <TableCell component="th" scope="row" padding="none" sx={{ backgroundColor: '#FFFFFF', p: 0 }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Switch
                        name={'instagram'}
                        onChange={(e) => handleChange(e, val)}
                        checked={val.instagram}
                        color="success"
                      />
                    </div>
                  </TableCell>
                  <TableCell component="th" scope="row" padding="none" sx={{ backgroundColor: '#FFFFFF', p: 0 }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Switch
                        name={'messenger'}
                        onChange={(e) => handleChange(e, val)}
                        checked={val.messenger}
                        color="success"
                      />
                    </div>
                  </TableCell>
                  <TableCell component="th" scope="row" padding="none" sx={{ backgroundColor: '#FFFFFF', p: 0 }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Switch
                        name={'snapchat'}
                        onChange={(e) => handleChange(e, val)}
                        checked={val.snapchat}
                        color="success"
                      />
                    </div>
                  </TableCell>
                  <TableCell component="th" scope="row" padding="none" sx={{ backgroundColor: '#FFFFFF', p: 0 }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Switch
                        name={'linkedin'}
                        onChange={(e) => handleChange(e, val)}
                        checked={val.linkedin}
                        color="success"
                      />
                    </div>
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    padding="none"
                    sx={{
                      backgroundColor: '#FFFFFF',
                      p: 0,
                      borderBottomRightRadius: '35px',
                      borderTopRightRadius: '35px',
                    }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Switch
                        name={'discord'}
                        onChange={(e) => handleChange(e, val)}
                        checked={val.discord}
                        color="success"
                      />
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Scrollbar>
      </TableContainer>
    </>
  );
};

export default BusinessNotifications;
