/* eslint-disable react/jsx-boolean-value */

import React, { useEffect, useState } from 'react';
import RefreshIcon from '@mui/icons-material/Refresh';
import { IconButton, Tooltip } from '@mui/material';
import moment from 'moment';
import ReactECharts from 'echarts-for-react';
import { registerMap } from 'echarts';

import { getWarrantyReports } from '../../services/Service';
import Inlineloader from '../../components/Loader/InlineLoader';
import useUserTimeZone from '../../hooks/useUserTimeZone';
import geoWorld from '../../assets/geo/world.json';

function getColorByValue(value) {
  if (value > 0) return '#204a8e';
  return '#eef0f8'; // Less than 20k
  // if (value >= 100) return '#006837';
  // if (value >= 90) return '#1a9850';
  // if (value >= 80) return '#66bd63';
  // if (value >= 70) return '#a6d96a';
  // if (value >= 60) return '#d9ef8b';
  // if (value >= 50) return '#ffffbf';
  // if (value >= 30) return '#fee08b';
  // if (value >= 10) return '#fdae61';
  // return '#f46d43'; // Less than 20k
}

const WarrantyReport = () => {
  const { formatDate } = useUserTimeZone();
  const [isLoading, setIsLoading] = useState(true);
  const [metadata, setMetadata] = useState({
    active: 0,
    total: 0,
    expire30days: 0,
    expire60days: 0,
    expire90days: 0,
    expired: 0,
  });
  const [activeExpiryChart, setActiveExpiryChart] = useState([]);
  const [warrantyAgeing, setWarrantyAgeing] = useState([]);
  const [warrantyByTypeChart, setWarrantyByTypeChart] = useState([]);
  const [warrantyByCategoryChart, setWarrantyByCategoryChart] = useState([]);
  const [storeByRegion, setStoreByRegion] = useState([]);
  const [upcomingWarranty, setUpcomingWarranty] = useState([]);
  const [selectedYearActiveExpiry, setSelectedYearActiveExpiry] = useState(moment().year());

  const currentActiveExpiryChart = activeExpiryChart.find((item) => item.year === selectedYearActiveExpiry);
  useEffect(() => {
    fetchReportData();
    registerMap('world', geoWorld);
  }, []);

  const fetchReportData = async () => {
    setIsLoading(true);
    try {
      const res = await getWarrantyReports();
      setMetadata(res.data.metadata);
      setActiveExpiryChart(res.data.activeExpiryChart);
      setWarrantyAgeing(res.data.warrantyAgeing);
      setWarrantyByTypeChart(res.data.warrantyByTypeChart);
      setWarrantyByCategoryChart(res.data.warrantyByCategoryChart);
      setStoreByRegion(res.data.storeByRegion);
      setUpcomingWarranty(res.data.upcomingWarranty);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const activeExpiryChartOption = {
    title: {
      text: 'Warranty active and expiry chart',
      left: 'left',
      textStyle: {
        color: '#3D71FF',
        lineHeight: 16,
      },
    },
    toolbox: {
      show: true,
      feature: {
        saveAsImage: { show: true },
      },
    },
    legend: {
      data: ['Active', 'Expired'],
      top: '20px',
    },
    tooltip: {
      trigger: 'item',
    },

    xAxis: [
      {
        type: 'category',
        axisTick: { show: false },
        data:
          currentActiveExpiryChart?.data?.length > 0
            ? currentActiveExpiryChart?.data?.map((e) => e.month)
            : ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        axisLabel: {
          color: '#3D71FF', // Green color for x-axis labels
          fontWeight: 'bold',
        },
        axisLine: {
          show: false, // Remove x-axis line
        },
      },
    ],
    yAxis: [
      {
        type: 'value',
        axisLabel: {
          color: '#3D71FF', // Green color for x-axis labels
        },
      },
    ],
    series: [
      {
        name: 'Active',
        type: 'bar',
        // label: labelOption,
        emphasis: {
          focus: 'series',
        },
        data:
          currentActiveExpiryChart?.data?.length > 0
            ? currentActiveExpiryChart?.data?.map((e) => e.active)
            : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        itemStyle: {
          color: '#47a563',
          borderColor: '#47a563',
          borderWidth: 1,
          borderRadius: 4,
        },
      },
      {
        name: 'Expired',
        type: 'bar',
        barGap: 0,
        // label: labelOption,
        emphasis: {
          focus: 'series',
        },
        data:
          currentActiveExpiryChart?.data?.length > 0
            ? currentActiveExpiryChart?.data?.map((e) => e.expired)
            : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        itemStyle: {
          color: '#0b8fd9',
          borderColor: '#0b8fd9',
          borderWidth: 1,
          borderRadius: 4,
        },
      },
    ],
  };

  const warrantyAgeingOption = {
    title: {
      text: 'Warranty Ageing',
      // left: 'left', // Align title to the left
      textStyle: {
        color: '#3D71FF', // Green color for the title
        lineHeight: 16, // Reduced line height
      },
    },
    toolbox: {
      show: true,
      feature: {
        saveAsImage: { show: true },
      },
    },
    tooltip: {
      trigger: 'item',
      formatter: '{b}: {c} ({d}%)', // Tooltip shows name, value, and percentage
    },
    legend: {
      type: 'scroll',
      orient: 'vertical',
      left: 'right', // Position legend to the right
      top: 'center', // Vertically center the legend
      formatter: (name) => {
        const data = warrantyAgeingOption.series[0].data;
        const total = data.reduce((sum, item) => sum + item.value, 0);
        const item = data.find((d) => d.name === name);
        const percentage = ((item.value / total) * 100).toFixed(2); // Calculate percentage

        return `${name} (${percentage}%)`; // Display name with percentage
      },
    },
    series: [
      {
        name: 'Warranty Ageing',
        type: 'pie',
        radius: '75%',
        center: ['40%', '50%'], // Move the pie chart to the left
        data: warrantyAgeing?.map((e) => ({ value: e?.count, name: e?.title })),
        itemStyle: {
          color: (params) => {
            const colors = ['#0b8fd9', '#d05759', '#fd8744', '#ffb95a', '#82b572'];
            return colors[params.dataIndex % colors.length]; // Apply custom colors
          },
        },
      },
    ],
  };

  const warrantyByTypeChartOption = {
    title: {
      text: 'Warranty by Type',
      // left: 'left', // Align title to the left
      textStyle: {
        color: '#3D71FF', // Green color for the title
        lineHeight: 16, // Reduced line height
      },
    },
    toolbox: {
      show: true,
      feature: {
        saveAsImage: { show: true },
      },
    },
    tooltip: {
      trigger: 'item',
      formatter: '{b}: {c} ({d}%)', // Tooltip shows name, value, and percentage
    },
    legend: {
      type: 'scroll',
      orient: 'vertical',
      left: 'right', // Position legend to the right
      top: 'center', // Vertically center the legend
      formatter: (name) => {
        const data = warrantyByTypeChartOption.series[0].data;
        const total = data.reduce((sum, item) => sum + item.value, 0);
        const item = data.find((d) => d.name === name);
        const percentage = ((item.value / total) * 100).toFixed(2); // Calculate percentage

        return `${name} (${percentage}%)`; // Display name with percentage
      },
    },
    series: [
      {
        name: 'Warranty by Type',
        type: 'pie',
        radius: '75%',
        center: ['40%', '50%'], // Move the pie chart to the left
        data: warrantyByTypeChart?.map((e) => ({ value: e?.count, name: e?.name })),
        itemStyle: {
          color: (params) => {
            const colors = ['#0b8fd9', '#d05759', '#fd8744', '#ffb95a', '#82b572'];
            return colors[params.dataIndex % colors.length]; // Apply custom colors
          },
        },
      },
    ],
  };

  const warrantyByCategoryChartOption = {
    title: {
      text: 'Warranty by product category',
      // left: 'left', // Align title to the left
      textStyle: {
        color: '#3D71FF', // Green color for the title
        lineHeight: 16, // Reduced line height
      },
    },
    toolbox: {
      show: true,
      feature: {
        saveAsImage: { show: true },
      },
    },
    tooltip: {
      trigger: 'item',
      formatter: '{b}: {c} ({d}%)', // Tooltip shows name, value, and percentage
    },
    legend: {
      type: 'scroll',
      orient: 'vertical',
      left: 'right', // Position legend to the right
      top: 'center', // Vertically center the legend
      formatter: (name) => {
        const data = warrantyByCategoryChartOption.series[0].data;
        const total = data.reduce((sum, item) => sum + item.value, 0);
        const item = data.find((d) => d.name === name);
        const percentage = ((item.value / total) * 100).toFixed(2); // Calculate percentage

        return `${name} (${percentage}%)`; // Display name with percentage
      },
    },
    series: [
      {
        name: 'Warranty by product category',
        type: 'pie',
        radius: '75%',
        center: ['40%', '50%'], // Move the pie chart to the left
        data: warrantyByCategoryChart?.map((e) => ({ value: e?.count, name: e?.name })),
        itemStyle: {
          color: (params) => {
            const colors = ['#0b8fd9', '#d05759', '#fd8744', '#ffb95a', '#82b572'];
            return colors[params.dataIndex % colors.length]; // Apply custom colors
          },
        },
      },
    ],
  };

  const worldChartOption = {
    title: {
      text: 'Store by region',
      left: 'left',
      textStyle: {
        color: '#3D71FF',
        lineHeight: 16,
      },
    },
    toolbox: {
      show: true,
      feature: {
        saveAsImage: { show: true },
      },
    },
    tooltip: {
      trigger: 'item',
    },
    visualMap: {
      min: 0, // Minimum value for the data
      max: 100, // Maximum value for the data
      text: ['High', 'Low'], // Labels for the color range
      realtime: false, // Disable real-time updates during interaction
      calculable: true, // Show drag handles for visual range
      inRange: {
        color: ['#9da7d5', '#18448b'], // Range of colors from min to max
      },
    },
    series: [
      {
        name: 'Store by region',
        type: 'map',
        map: 'world',
        roam: false, // Disable zoom and pan
        zoom: 1, // Set default zoom to 120%
        emphasis: {
          itemStyle: {
            areaColor: '#3D71FF', // Hover color
          },
        },
        itemStyle: {
          areaColor: '#eef0f8', // Default fill color for areas
          borderColor: '#eef0f8', // Optional: Add a border color
          borderWidth: 0.5, // Optional: Set border width
        },
        nameProperty: 'ISO_A3',
        data: storeByRegion?.map((e) => ({
          name: e?.country,
          value: e?.count,
          itemStyle: {
            color: getColorByValue(e?.count), // Assign a color based on value
          },
        })), // Use abbreviation-based data
      },
    ],
  };

  if (isLoading) return <Inlineloader />;
  return (
    <>
      <div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div />
          <div style={{ display: 'flex', gap: '16px' }}>
            <Tooltip title="Refresh">
              <IconButton
                sx={{
                  color: '#FFFFFF',
                  backgroundColor: '#3D71FF',
                  '&:hover': { backgroundColor: '#3D71FF', color: '#FFFFFF' },
                }}
                onClick={fetchReportData}
              >
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <div style={{ display: 'flex', gap: '16px', flexDirection: 'column', marginTop: '16px' }}>
          <div style={{ display: 'flex', gap: '16px', justifyContent: 'space-evenly', flexWrap: 'wrap' }}>
            <TopCard name="Active" value={`${metadata.active} nos`} />
            <TopCard name="Expiry > 30 days" value={`${metadata.expire30days} nos`} />
            <TopCard name="Expiry > 60 days" value={`${metadata.expire60days} nos`} />
            <TopCard name="Expiry > 90 days" value={`${metadata.expire90days} nos`} />
            <TopCard name="Expired" value={`${metadata.expired} nos`} />
          </div>
          <div style={{ display: 'flex', gap: '16px', flexWrap: 'wrap' }}>
            {/* activeExpiryChartOption */}
            <div
              style={{
                padding: '12px',
                borderRadius: '16px',
                backgroundColor: '#ffffff',
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                flex: 1,
                minWidth: '500px',
              }}
            >
              <select
                value={selectedYearActiveExpiry}
                onChange={(e) => setSelectedYearActiveExpiry(parseInt(e.target.value, 10))}
                style={{ position: 'absolute', right: '52px', top: '18px', zIndex: 1 }}
              >
                {activeExpiryChart?.map((e) => (
                  <option value={e?.year}>{e?.year}</option>
                ))}
              </select>
              <ReactECharts
                notMerge={true}
                lazyUpdate={true}
                option={activeExpiryChartOption}
                showLoading={isLoading}
                // style={{ height: '400px', minHeight: '300px' }}
              />
              {/* <div
        style={{
          display: 'flex',
          gap: '12px',
          position: 'absolute',
          bottom: '12px',
          left: '50%',
          transform: 'translateX(-50%)',
        }}
      >
        <LegendItem color="#468b47" text="Planed MRR" />
        <LegendItem color="#90ee90" text="Actual MRR" />
      </div> */}
            </div>
            {/* warrantyAgeingOption */}
            <div
              style={{
                padding: '12px',
                borderRadius: '16px',
                backgroundColor: '#ffffff',
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                flex: 1,
                minWidth: '500px',
              }}
            >
              <ReactECharts
                notMerge={true}
                lazyUpdate={true}
                option={warrantyAgeingOption}
                showLoading={isLoading}
                // style={{ height: '400px', minHeight: '300px' }}
              />
            </div>
          </div>
          <div style={{ display: 'flex', gap: '16px', flexWrap: 'wrap' }}>
            <div
              style={{
                padding: '12px',
                borderRadius: '16px',
                backgroundColor: '#ffffff',
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                flex: 1,
                minWidth: '500px',
              }}
            >
              <ReactECharts
                notMerge={true}
                lazyUpdate={true}
                option={warrantyByTypeChartOption}
                showLoading={isLoading}
                // style={{ height: '400px', minHeight: '300px' }}
              />
            </div>
            <div
              style={{
                padding: '12px',
                borderRadius: '16px',
                backgroundColor: '#ffffff',
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                flex: 1,
                minWidth: '500px',
              }}
            >
              <ReactECharts
                notMerge={true}
                lazyUpdate={true}
                option={warrantyByCategoryChartOption}
                showLoading={isLoading}
                // style={{ height: '400px', minHeight: '300px' }}
              />
            </div>
          </div>

          <div style={{ display: 'flex', gap: '16px', flexWrap: 'wrap' }}>
            <div
              style={{
                padding: '12px',
                borderRadius: '16px',
                backgroundColor: '#ffffff',
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                flex: 1,
                minWidth: '500px',
              }}
            >
              <span style={{ fontSize: '20px', fontWeight: 'bold', color: '#3D71FF' }}>Upcoming warranty</span>
              <div style={{ display: 'flex', gap: '6px', flexDirection: 'column', marginTop: '16px' }}>
                <div
                  style={{
                    display: 'flex',
                    gap: '12px',
                    color: '#3D71FF',
                    justifyContent: 'space-between',
                    borderBottom: '2px solid #3D71FF',
                    fontWeight: 'bold',
                  }}
                >
                  <p style={{ flex: 'none', width: '140px' }}>SafalWarranty Id</p>
                  <p style={{ flex: '1' }}>Product Name</p>
                  <p style={{ flex: 'none', width: '120px' }}>Expiry Date</p>
                </div>
                {upcomingWarranty?.map((e, index) => (
                  <div
                    key={index}
                    style={{
                      display: 'flex',
                      gap: '12px',
                      justifyContent: 'space-between',
                      borderBottom: index === upcomingWarranty.length - 1 ? 'none' : '1px solid #e0e6f1',
                    }}
                  >
                    <p style={{ flex: 'none', width: '140px' }}>{e?.safalWarrantyNumber || ''}</p>
                    <p style={{ flex: '1' }}>{e?.productName}</p>
                    <p style={{ flex: 'none', width: '120px' }}>{formatDate(e?.warrantyDetailsExpireDate)}</p>
                  </div>
                ))}
              </div>
            </div>
            <div
              style={{
                padding: '12px',
                borderRadius: '16px',
                backgroundColor: '#ffffff',
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                flex: 1,
                minWidth: '500px',
              }}
            >
              <ReactECharts
                notMerge={true}
                lazyUpdate={true}
                option={worldChartOption}
                showLoading={isLoading}
                // style={{ height: '400px', minHeight: '300px' }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WarrantyReport;

const TopCard = ({ name, value }) => {
  return (
    <div
      style={{
        height: '80px',
        width: '220px',
        border: '1px solid #ffffff',
        // border: '1px solid #bcceff',
        boxShadow: '0px 4px 4px 0px #00000040',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        padding: '8px',
        justifyContent: 'space-between',
        backgroundColor: '#ffffff',
        // backgroundColor: '#bcceff',
        position: 'relative',
      }}
    >
      <span style={{ color: '#001E6F', fontWeight: 'bold' }}>{name}</span>
      <span style={{ color: '#001E6F' }}>{value}</span>
      <img
        style={{
          width: '50px',
          height: '50px',
          objectFit: 'contain',
          position: 'absolute',
          bottom: '50%',
          transform: 'translateY(50%)',
          right: '10px',
          opacity: '0.15',
        }}
        src="/assets/images/safalwarranty_black.png"
        alt="safalwarranty_black"
      />
    </div>
  );
};
