import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useSelector } from 'react-redux';
import { InputAdornment, TextField } from '@mui/material';
import moment from 'moment';

const DateField = ({
  disabled,
  value,
  onChange,
  label,
  variant,
  width,
  focused,
  disablePast,
  disableFuture,
  error,
  helperText,
}) => {
  const userProfile = useSelector((state) => state.login);

  const _value = value !== null && value !== undefined && value !== '' ? moment(value) : null;

  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale={userProfile.user.country === 'India' ? 'en-gb' : 'en'}
    >
      <DatePicker
        value={_value}
        onChange={onChange}
        // name="expiryDate"
        type="date"
        label={label}
        disablePast={disablePast}
        size="small"
        fullWidth
        variant={variant}
        disabled={disabled}
        InputProps={{
          startAdornment: <InputAdornment position="start" />,
          readOnly: true,
        }}
        maxDate={disableFuture && new Date()}
        InputLabelProps={{ style: { marginLeft: '10px' } }}
        renderInput={(props) => (
          <TextField
            {...props}
            sx={{ width: width, mb: '8px', my: '10px' }}
            size="small"
            focused={focused}
            variant={variant}
            error={error}
            helperText={helperText}
            disabled={disabled}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default DateField;
