import Iconify from '../../components/Iconify';

const getIcon = (name) => <img src={name} alt={name} width={15} height={15} />;
const getIconBusiness = (name) => (
  <img
    style={{ filter: 'hue-rotate(-20deg) brightness(0.6) contrast(1.3)' }}
    src={name}
    alt={name}
    width={15}
    height={15}
  />
);

const navConfig = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    icon: getIcon('/assets/images/dashboard.png'),
    roles: ['user'],
  },
  {
    title: 'Subscriptions',
    path: '/subscription',
    icon: getIcon('/assets/images/subscription.png'),
    roles: ['user'],
  },
  {
    title: 'Assets',
    path: '/asset',
    icon: getIcon('/assets/images/assetIconNew.png'),
    roles: ['user'],
  },
  {
    title: 'Warranty',
    path: '/warranty',
    icon: getIcon('/assets/images/safalwarranty_black.png'),
    roles: ['user'],
  },
  {
    title: 'Offers',
    path: '/offers',
    icon: getIcon('/assets/images/offers_icon.png'),
    roles: ['user'],
  },
  {
    title: 'SafalRoom',
    path: '/safalroom',
    icon: getIcon('/assets/images/safalRoom.png'),
    roles: ['user'],
  },
  {
    title: 'SafalLeaderBoard',
    path: '/safalleaderboard',
    icon: getIcon('/assets/images/leaderBoard_black.png'),
    roles: ['user'],
  },
  {
    title: 'Calendar',
    path: '/calendar',
    icon: getIcon('/assets/images/calendar.png'),
    roles: ['user'],
  },
  {
    title: 'Reports',
    path: '/reports',
    icon: getIcon('/assets/images/reports.png'),
    roles: ['user'],
  },
  //  {
  //   title: 'Notifications',
  //   path: '/notifications',
  //   icon: getIcon('/assets/images/notification.png'),
  //   roles: ['user'],
  // },
  {
    title: 'Companies',
    path: '/business/companieslist',
    icon: <Iconify icon="mdi:company" />,
    roles: ['business'],
    category: 'company',
  },
  {
    title: 'Support chat',
    path: '/business/chat',
    icon: <Iconify icon="material-symbols:support-agent-rounded" />,
    roles: ['business'],
    category: 'support',
  },
  {
    title: 'Users Feedback',
    path: '/business/feedback',
    icon: <Iconify icon="fluent:person-feedback-24-filled" />,
    roles: ['business'],
    category: 'support',
  },
  {
    title: 'Manage Notifications',
    path: '/business/notifications',
    icon: <Iconify icon="material-symbols:edit-notifications" />,
    roles: ['business'],
    category: 'userType',
  },
  // Payments Nav Menu
  {
    title: 'Manage Plans',
    path: '/business/plans',
    roles: ['business'],
    category: 'payment',
  },
  {
    title: 'Plan Features List',
    path: '/business/plan_feature',
    roles: ['business'],
    category: 'payment',
  },
  {
    title: 'Manage Prices',
    path: '/business/prices',
    roles: ['business'],
    category: 'payment',
  },
  {
    title: 'Payment Mode',
    path: '/business/payment_mode',
    roles: ['business'],
    category: 'payment',
  },
  {
    title: 'Card Scheme',
    path: '/business/card_scheme',
    roles: ['business'],
    category: 'payment',
  },
  {
    title: 'Payment Instrument',
    path: '/business/payment_instrument',
    roles: ['business'],
    category: 'payment',
  },
  {
    title: 'Promotions',
    path: '/business/promotions',
    icon: <Iconify icon="ep:promotion" width={20} height={20} />,
    roles: ['business'],
    category: 'self',
  },
  {
    title: 'Promotions List',
    path: '/business/promotionslist',
    icon: <Iconify icon="ep:promotion" width={20} height={20} />,
    roles: ['business'],
    category: 'self',
  },

  {
    title: 'Custom Company',
    path: '/business/custom_company',
    icon: <Iconify icon="mdi:user-details" width={20} height={20} />,
    roles: ['business'],
    category: 'company',
  },
  {
    title: 'Company Type List',
    path: '/business/company_type',
    roles: ['business'],
    category: 'company',
  },
  {
    title: 'Manage Tiers',
    path: '/business/managememory',
    icon: <Iconify icon="fluent:steps-24-filled" width={20} height={20} />,
    roles: ['business'],
    category: 'userType',
  },
  {
    title: 'Module Configuration',
    path: '/business/module_configuration',
    roles: ['business'],
    category: 'userType',
  },
  {
    title: 'Change Log',
    path: '/business/change_log',
    roles: ['business'],
    category: 'userType',
  },
  {
    title: 'Asset Module',
    path: '/business/asset_module',
    roles: ['business'],
    category: 'userType',
  },
  {
    title: 'Asset Module Custom',
    path: '/business/asset_module_custom',
    roles: ['business'],
    category: 'userType',
  },
  {
    title: 'Manage Colors & BG',
    path: '/business/manage_color',
    roles: ['business'],
    category: 'userType',
  },
  {
    title: 'RSS Link List',
    path: '/business/rss',
    roles: ['business'],
    category: 'userType',
  },
  {
    title: 'Banner List',
    path: '/business/banner',
    roles: ['business'],
    category: 'userType',
  },
  {
    title: 'Initial Feedback',
    path: '/business/subscription-feedback',
    roles: ['business'],
    category: 'userType',
  },
  {
    title: 'Offers List',
    path: '/business/offers',
    roles: ['business'],
    category: 'userType',
  },
  {
    title: 'FAQ List',
    path: '/business/faq',
    roles: ['business'],
    category: 'userType',
  },
  {
    title: 'General Key Value List',
    path: '/business/kv',
    roles: ['business'],
    category: 'userType',
  },
  {
    title: 'PDF Scan Type',
    path: '/business/pdf_scan_type',
    roles: ['business'],
    category: 'userType',
  },
  {
    title: 'Privacy Policy/Term & Condition',
    path: '/business/privacy-policy',
    roles: ['business'],
    category: 'userType',
  },
  {
    title: 'Module List for Help Video',
    path: '/business/help-video-module',
    roles: ['business'],
    category: 'userType',
  },
  {
    title: 'Help Video Inventory',
    path: '/business/help-video',
    roles: ['business'],
    category: 'userType',
  },
  {
    title: 'Application List',
    path: '/business/application-list',
    roles: ['business'],
    category: 'userType',
  },
  {
    title: 'Global Share Links',
    path: '/business/global-share',
    roles: ['business'],
    category: 'userType',
  },
  {
    title: 'SafalLeaderBoard Config Master',
    path: '/business/safal-leader-config-master',
    roles: ['business'],
    category: 'leaderBoard',
  },
  {
    title: 'SafalLeaderBoard Frequency Config',
    path: '/business/safal-leader-frequency-config',
    roles: ['business'],
    category: 'leaderBoard',
  },
  {
    title: 'SafalLeaderBoard',
    path: '/business/safal-leader-board',
    roles: ['business'],
    category: 'leaderBoard',
  },
  {
    title: 'SafalLeaderBoard Countries',
    path: '/business/safal-leader-board-countries',
    roles: ['business'],
    category: 'leaderBoard',
  },
  {
    title: 'SafalLeaderBoard Activities Config Master',
    path: '/business/safal-leader-activities-config-master',
    roles: ['business'],
    category: 'leaderBoard',
  },
  {
    title: 'SafalGift Master Inventory',
    path: '/business/safal-gift-master-inventory',
    roles: ['business'],
    category: 'leaderBoard',
  },
  {
    title: 'SafalGift Orders',
    path: '/business/safal-gift-order',
    roles: ['business'],
    category: 'leaderBoard',
  },
  {
    title: 'SafalRoom Community Forums',
    path: '/business/safal-room-forums',
    roles: ['business'],
    category: 'leaderBoard',
  },
  {
    title: 'SafalRoom Private',
    path: '/business/safal-room-private',
    roles: ['business'],
    category: 'leaderBoard',
  },
  {
    title: 'SafalBuddy Activities Master Config',
    path: '/business/safal-buddy-master-config',
    roles: ['business'],
    category: 'safalBuddy',
  },
  {
    title: 'Manage Support Call',
    path: '/business/manage_call',
    // icon: getIcon('eva:people-fill'),
    roles: ['business'],
    category: 'support',
  },
  // {
  //   title: 'Add Companies',
  //   path: '/business/companies',
  //   // icon: getIcon('eva:people-fill'),
  //   roles: ['business'],
  // },
  {
    title: 'Admin',
    path: '/admin/dashboard',
    // icon: getIcon('eva:people-fill'),
    roles: ['admin'],
  },
];

// if subMenu there then link not working, default submenu open
export const navConfigBusiness = [
  // Master Data
  {
    title: 'Home',
    icon: getIconBusiness('/assets/images/bnav/home.png'),
    link: '/business',
    subMenu: [],
  },
  {
    title: 'Master Data',
    icon: getIconBusiness('/assets/images/bnav/MasterData.png'),
    subMenu: [
      {
        title: 'Company Type List',
        icon: getIconBusiness('/assets/images/bnav/CompanyTypeList.png'),
        link: '/business/company_type',
        subMenu: [],
      },
      {
        title: 'Subscription Companies',
        icon: getIconBusiness('/assets/images/bnav/SubscriptionCompanies.png'),
        // link:""
        subMenu: [
          {
            title: 'Approved Companies',
            link: '/business/companieslist',
          },
        ],
      },
      {
        title: 'Asset Companies',
        icon: getIconBusiness('/assets/images/bnav/AssetCompanies.png'),
        // link:""
        subMenu: [
          {
            title: 'Asset Module',
            link: '/business/asset_module',
          },
        ],
      },
      {
        title: 'Notification Management',
        icon: getIconBusiness('/assets/images/bnav/NotificationManagement.png'),
        link: '/business/notifications',
        subMenu: [],
      },
      {
        title: 'Dynamic Menu Configuration',
        icon: getIconBusiness('/assets/images/bnav/DynamicMenuConfiguration.png'),
        link: '/business/module_configuration',
        subMenu: [],
      },
      {
        title: 'General Key value List',
        icon: getIconBusiness('/assets/images/bnav/GeneralKeyValueList.png'),
        link: '/business/kv',
        subMenu: [],
      },
      {
        title: 'Privacy Policy / Terms & Condition',
        icon: getIconBusiness('/assets/images/bnav/PrivacyPolicyTermsCondition.png'),
        link: '/business/privacy-policy',
        subMenu: [],
      },
      {
        title: 'Application cross country settings',
        icon: getIconBusiness('/assets/images/bnav/SafalVirApplication.png'),
        link: '/business/application-list',
        subMenu: [],
      },
      {
        title: 'Global Share Link',
        icon: getIconBusiness('/assets/images/bnav/GlobalShareLink.png'),
        link: '/business/global-share',
        subMenu: [],
      },
      {
        title: 'Card Scheme',
        icon: getIconBusiness('/assets/images/bnav/CardSc.png'),
        link: '/business/card_scheme',
        subMenu: [],
      },
      {
        title: 'Payment Instrument',
        icon: getIconBusiness('/assets/images/bnav/VideoInc.png'),
        link: '/business/payment_instrument',
        subMenu: [],
      },
      {
        title: 'User Permission Toggle Setting',
        icon: getIcon('/assets/images/bnav/userPermissionToggleSetting.png'),
        link: '/business/user-permission-toggle-setting',
        subMenu: [],
      },
      {
        title: 'Advertisement',
        icon: getIcon('/assets/images/bnav/advertisement.png'),
        subMenu: [
          {
            title: 'Advertisement List',
            link: '/business/advertisement',
          },
          {
            title: 'Advertisement Pages',
            link: '/business/advertisement-page',
          },
        ],
      },
      {
        title: 'SafalWarranty',
        icon: getIcon('/assets/images/bnav/safalwarranty_b.png'),
        subMenu: [
          {
            title: 'Warranty Notification Master',
            link: '/business/safal-warranty-notification',
          },
          {
            title: 'Product Category',
            link: '/business/safal-warranty-product-category',
          },
          {
            title: 'Product Sub-Category',
            link: '/business/safal-warranty-product-sub-category',
          },
          {
            title: 'Store/Retailer',
            link: '/business/safal-warranty-retailer',
          },
          {
            title: 'Warranty Type',
            link: '/business/safal-warranty-type',
          },
        ],
      },
    ],
  },
  // Module Configuration
  {
    title: 'Module Configuration',
    icon: getIconBusiness('/assets/images/bnav/ModuleConfiguration.png'),
    subMenu: [
      {
        title: 'Subscription',
        icon: getIconBusiness('/assets/images/bnav/Subscription.png'),
        // link:""
        subMenu: [
          {
            title: 'IntelliScan Configuration',
            link: '/business/pdf_scan_type',
          },
        ],
      },
      {
        title: 'Asset',
        icon: getIconBusiness('/assets/images/bnav/AssetCompanies.png'),
        // link:""
        subMenu: [
          {
            title: 'Manage Colors & BG',
            link: '/business/manage_color',
          },
        ],
      },
      {
        title: 'Offers',
        icon: getIconBusiness('/assets/images/bnav/Offers.png'),
        // link:""
        subMenu: [
          {
            title: 'Offer List',
            link: '/business/offers',
          },
        ],
      },
      {
        title: 'SafalLeaderBoard',
        icon: getIconBusiness('/assets/images/bnav/SafalLeaderBoard.png'),
        // link:""
        subMenu: [
          {
            title: 'SafalLeaderBoard config master',
            link: '/business/safal-leader-config-master',
          },
          {
            title: 'SafalLeaderBoard frequency config',
            link: '/business/safal-leader-frequency-config',
          },
          {
            title: 'SafalLeaderBoard countries',
            link: '/business/safal-leader-board-countries',
          },
          {
            title: 'SafalLeaderBoard activity config master',
            link: '/business/safal-leader-activities-config-master',
          },
        ],
      },
      {
        title: 'SafalBuddy',
        icon: getIconBusiness('/assets/images/bnav/SafalBuddy.png'),
        // link:""
        subMenu: [
          {
            title: 'SafalBuddy Activity Master Config',
            link: '/business/safal-buddy-master-config',
          },
        ],
      },
      {
        title: 'SafalGift',
        icon: getIconBusiness('/assets/images/bnav/SafalGift.png'),
        // link:""
        subMenu: [
          {
            title: 'SafalGift Master Inventory',
            link: '/business/safal-gift-master-inventory',
          },
        ],
      },
      {
        title: 'Promotion',
        icon: getIconBusiness('/assets/images/bnav/Promotion.png'),
        // link:""
        subMenu: [
          {
            title: 'RSS Link List',
            link: '/business/rss',
          },
          {
            title: 'Banner List',
            link: '/business/banner',
          },
          {
            title: 'Promotions',
            link: '/business/promotions',
          },
          {
            title: 'Promotions List',
            link: '/business/promotionslist',
          },
        ],
      },
      // {
      //   title: 'Report',
      //   icon: getIconBusiness('/assets/images/bnav/Report.png'),
      //   link: '/business/change_log',
      //   subMenu: [],
      // },
    ],
  },
  {
    title: 'Request',
    icon: getIcon('/assets/images/bnav/Request.png'),
    subMenu: [
      {
        title: 'Subscription',
        icon: getIconBusiness('/assets/images/bnav/Subscription.png'),
        subMenu: [
          {
            title: 'Custom Company',
            link: '/business/custom_company',
          },
        ],
      },
      {
        title: 'Asset',
        icon: getIconBusiness('/assets/images/bnav/AssetCompanies.png'),
        // link:""
        subMenu: [
          {
            title: 'Asset Module Custom',
            link: '/business/asset_module_custom',
          },
        ],
      },
      {
        title: 'SafalRoom',
        icon: getIconBusiness('/assets/images/bnav/SafalRoom.png'),
        // link:""
        subMenu: [
          {
            title: 'SafalRoom community forums',
            link: '/business/safal-room-forums',
          },
          {
            title: 'SafalRoom private',
            link: '/business/safal-room-private',
          },
        ],
      },
      {
        title: 'SafalGift',
        icon: getIconBusiness('/assets/images/bnav/SafalGift.png'),
        // link:""
        subMenu: [
          {
            title: 'SafalGift Orders',
            link: '/business/safal-gift-order',
          },
        ],
      },
    ],
  },
  // Payment Plan & Pricing
  {
    title: 'Plan & Pricing',
    icon: getIconBusiness('/assets/images/bnav/PaymentPlanPricing.png'),
    subMenu: [
      {
        title: 'Manage Plan',
        icon: getIconBusiness('/assets/images/bnav/ManagePlan.png'),
        link: '/business/plans',
        subMenu: [],
      },
      {
        title: 'Plan Feature List',
        icon: getIconBusiness('/assets/images/bnav/PlanFeatureList.png'),
        link: '/business/plan_feature',
        subMenu: [],
      },
      {
        title: 'Manage Price',
        icon: getIconBusiness('/assets/images/bnav/ManagePrice.png'),
        link: '/business/prices',
        subMenu: [],
      },
      {
        title: 'Manage Payment Tiers',
        icon: getIconBusiness('/assets/images/bnav/ManagePaymentTiers.png'),
        link: '/business/managememory',
        subMenu: [],
      },
      {
        title: 'Payment Transactions',
        icon: getIconBusiness('/assets/images/bnav/ManagePaymentTiers.png'),
        link: '/business/stripe-transactions',
        subMenu: [],
      },
    ],
  },
  // Support Services
  {
    title: 'Support Services',
    icon: getIconBusiness('/assets/images/bnav/SupportC.png'),
    subMenu: [
      {
        title: 'Support Chat',
        icon: getIconBusiness('/assets/images/bnav/SupportChat.png'),
        link: '/business/chat',
        subMenu: [],
      },
      {
        title: 'User Feedback',
        icon: getIconBusiness('/assets/images/bnav/UserFeedback.png'),
        link: '/business/feedback',
        subMenu: [],
      },
      {
        title: 'Manage Support Call',
        icon: getIconBusiness('/assets/images/bnav/ManageSupportCall.png'),
        link: '/business/manage_call',
        subMenu: [],
      },
      {
        title: 'FAQ',
        icon: getIcon('/assets/images/bnav/FAQ.png'),
        link: '/business/faq',
        subMenu: [],
      },
      {
        title: 'Initial Feedback',
        icon: getIconBusiness('/assets/images/bnav/InitialFeedback.png'),
        link: '/business/subscription-feedback',
        subMenu: [],
      },
      {
        title: 'Module List for Help Video',
        icon: getIconBusiness('/assets/images/bnav/ModuleListforHelpVideo.png'),
        link: '/business/help-video-module',
        subMenu: [],
      },
      {
        title: 'Help Video Inventory',
        icon: getIconBusiness('/assets/images/bnav/ManagePaymentTiers.png'),
        link: '/business/help-video',
        subMenu: [],
      },
      {
        title: 'User Assessment',
        icon: getIconBusiness('/assets/images/bnav/userAcc.png'),
        link: '/business/user-assessment-master',
        subMenu: [],
      },
    ],
  },
  // Reports
  {
    title: 'Reports',
    icon: getIconBusiness('/assets/images/bnav/ReportNav.png'),
    subMenu: [
      {
        title: 'Business User Change Log',
        icon: getIconBusiness('/assets/images/bnav/Log_Out.png'),
        link: '/business/change_log',
        subMenu: [],
      },
      {
        title: 'SafalLeaderBoard',
        icon: getIconBusiness('/assets/images/bnav/SafalLeaderBoard.png'),
        link: '/business/safal-leader-board',
        subMenu: [],
      },
    ],
  },
];

export default navConfig;
