import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getGeneralKeyValueList } from '../../services/Service';

const TemplatesTab = () => {
  const [fileLink, setFileLink] = useState('');
  const [warrantyLink, setWarrantyLink] = useState('');
  const user = useSelector((state) => state.login.user);

  const fetchData = async () => {
    try {
      const res = await getGeneralKeyValueList();
      if (user?.country === 'United States') {
        const findFile = res?.data?.data?.find((ex) => ex?.flag === 'SUBSCRIPTIONS-BULK-UPLOAD-TEMPLATE-USA');
        if (findFile) setFileLink(findFile?.data);
      } else {
        const findFile = res?.data?.data?.find((ex) => ex?.flag === 'SUBSCRIPTIONS-BULK-UPLOAD-TEMPLATE-IND');
        if (findFile) setFileLink(findFile?.data);
      }
      const findWarrantyFile = res?.data?.data?.find((ex) => ex?.flag === 'WARRANTY-BULK-UPLOAD');
      setWarrantyLink(findWarrantyFile?.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
      <div style={{ display: 'flex', gap: '16px' }}>
        <p style={{ width: '500px' }}>
          This is template for bulk Upload for your subscriptions.
          <br />
          Please find following CSV template for reference, Download from following link
        </p>
        <a href={fileLink} download>
          Download Template
        </a>
      </div>
      <div style={{ height: '2px', width: '60%', backgroundColor: '#e2e2e2' }} />
      <div style={{ display: 'flex', gap: '16px' }}>
        <p style={{ width: '500px' }}>
          This is template for bulk Upload for your warranty.
          <br />
          Please find following CSV template for reference, Download from following link
        </p>
        <a href={warrantyLink} download>
          Download Template
        </a>
      </div>
    </div>
  );
};

export default TemplatesTab;
