import * as React from 'react';

import Box from '@mui/material/Box';
import { Image } from 'antd';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Swal from 'sweetalert2';
import { MenuItem, TextField } from '@mui/material';
import toast from 'react-hot-toast';
import useUserTimeZone, { getTimeZoneAbbreviation } from '../../../hooks/useUserTimeZone';
import { updateAdvertisementListFromVendor } from '../../../services/Service';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '8px',
  boxShadow: 24,
  displey: 'flex',
  flexDirection: 'column',
  gap: '12px',
  py: 2,
  px: 4,
  maxHeight: '95vh',
  overflow: 'auto',
  '@media (max-width: 640px)': {
    width: '95vw',
  },
};

export default function StripeTransactionsBusinessModal({ open, onClose, data }) {
  const { formatDate } = useUserTimeZone();
  const handleClose = (flag) => {
    onClose(flag);
  };

  return (
    <Modal open={open}>
      <Box sx={style}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography
            variant="h4"
            gutterBottom
            sx={{ marginBottom: '8px', fontSize: '28px', fontWeight: 700, color: '#19c25f' }}
          >
            Payment Transaction
          </Typography>
          <div />
        </div>
        <div style={{ display: 'flex', gap: '8px' }}>
          <div style={{ display: 'flex', flexDirection: 'column', flex: 1, gap: '8px' }}>
            <div style={{ display: 'flex', flexDirection: 'column', border: '1px solid #19c25f' }}>
              <span
                style={{
                  color: '#19c25f',
                  fontSize: '18px',
                  fontWeight: 'bold',
                  padding: '4px 8px',
                  fontStyle: 'italic',
                }}
              >
                User
              </span>
              <KeyValueBox title={'Full Name'} value={data?.fullName} />
              <KeyValueBox title={'Email'} value={data?.email} alt />
              <KeyValueBox title={'UserID'} value={data?.userID} />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', border: '1px solid #19c25f' }}>
              <span
                style={{
                  color: '#19c25f',
                  fontSize: '18px',
                  fontWeight: 'bold',
                  padding: '4px 8px',
                  fontStyle: 'italic',
                }}
              >
                Charge
              </span>
              <KeyValueBox title={'Charge Id'} value={data?.chargeId} />
              <KeyValueBox
                title={'Charge Amount'}
                value={`${data?.charge_amount !== '' ? data?.charge_amount / 100 : ''} ${data?.charge_currency}`}
                alt
              />
              <KeyValueBox title={'Status'} value={data?.charge_status} />
              <KeyValueBox title={'Payment Intent'} value={data?.charge_payment_intent} alt />
              <KeyValueBox title={'Payment Method'} value={data?.charge_payment_method} />
              <KeyValueBox
                title={'Receipt Url'}
                value={
                  <a target="_blank" rel="noreferrer" href={data?.charge_receipt_url}>
                    View
                  </a>
                }
                alt
              />
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', flex: 1 }}>
            <div style={{ display: 'flex', flexDirection: 'column', border: '1px solid #19c25f' }}>
              <span
                style={{
                  color: '#19c25f',
                  fontSize: '18px',
                  fontWeight: 'bold',
                  padding: '4px 8px',
                  fontStyle: 'italic',
                }}
              >
                Invoice
              </span>
              <KeyValueBox title={'Invoice Id'} value={data?.invoiceId} />
              <KeyValueBox title={'Invoice Number'} value={data?.invoice_number} alt />
              <KeyValueBox
                title={'Amount Due'}
                value={`${data?.invoice_amount_due !== '' ? data?.invoice_amount_due / 100 : ''} ${
                  data?.invoice_currency
                }`}
              />
              <KeyValueBox
                title={'Amount Paid'}
                value={`${data?.invoice_amount_paid !== '' ? data?.invoice_amount_paid / 100 : ''} ${
                  data?.invoice_currency
                }`}
                alt
              />
              <KeyValueBox title={'Billing Reason'} value={data?.invoice_billing_reason} />
              <KeyValueBox title={'Collection Method'} value={data?.invoice_collection_method} alt />
              <KeyValueBox title={'Status'} value={data?.invoice_status} />
              <KeyValueBox
                title={'Hosted Invoice URL'}
                value={
                  <a target="_blank" rel="noreferrer" href={data?.invoice_hosted_invoice_url}>
                    View
                  </a>
                }
                alt
              />
              <KeyValueBox
                title={'Invoice PDF'}
                value={
                  <a target="_blank" rel="noreferrer" href={data?.invoice_pdf}>
                    Download
                  </a>
                }
              />
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', gap: '12px', marginTop: '12px', justifyContent: 'end' }}>
          <Button
            onClick={handleClose}
            sx={{ backgroundColor: '#19c25f', textTransform: 'capitalize', ':hover': { backgroundColor: '#19c25f' } }}
            variant="contained"
          >
            Close
          </Button>
        </div>
      </Box>
    </Modal>
  );
}

const KeyValueBox = ({ title, value, alt, valueStyle, img }) => {
  const [visible, setVisible] = React.useState(false);
  return (
    <div
      style={{
        display: 'flex',
        gap: '4px',
        backgroundColor: alt ? 'white' : '#eaffea',
        padding: '4px 8px',
        justifyContent: 'space-between',
      }}
    >
      <p style={{ fontWeight: 'bold' }}>{title}</p>

      {img ? (
        <div>
          <button
            style={{
              backgroundColor: '#19c25f',
              padding: '4px 8px',
              color: 'white',
              borderRadius: '4px',
              border: 'none',
              outline: 'none',
            }}
            onClick={() => setVisible(true)}
          >
            Open
          </button>
          <Image
            hidden
            width={10}
            style={{
              display: 'none',
            }}
            src={value}
            preview={{
              zIndex: 1000000,
              visible,
              onVisibleChange: (value) => {
                setVisible(value);
              },
            }}
          />
        </div>
      ) : (
        <p style={{ textAlign: 'right', ...valueStyle }}>{value}</p>
      )}
    </div>
  );
};
