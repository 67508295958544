import * as React from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Menu, MenuItem, IconButton, ListItemIcon, Typography } from '@mui/material';
import toast from 'react-hot-toast';

import Iconify from '../../../components/Iconify';
import VirtualCardModal from './VirtualCardModal';
import { downloadAttachmentFile, getAssetModuleAccountSingle } from '../../../services/Service';
import virtualCardicon from '../../../assets/virtualCardicon1.png';
import { GlobalShareModal } from '../../../components/Modals/GlobalShareModal';

export default function ThreeDotMenu({ onEdit, onDelete, data, onChangeLog }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [virtualCardModalOpen, setVirtualCardModalOpen] = React.useState({
    open: false,
    data: null,
  });
  const [shareModal, setShareModal] = React.useState({ open: false });
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onDisplayClick = () => {
    handleClose();
    onEdit(true);
  };
  const onChangeLogClick = () => {
    handleClose();
    onChangeLog();
  };

  const onEditClick = () => {
    handleClose();
    onEdit(false);
  };

  const onDeleteClick = () => {
    handleClose();
    onDelete();
  };

  const onVirtualCardOpen = () => {
    setVirtualCardModalOpen({
      open: true,
      data: data,
    });
  };

  const onVirtualCardClose = async () => {
    setVirtualCardModalOpen({
      open: false,
      data: null,
    });
  };

  const onShareClick = () => {
    setShareModal((prev) => ({ ...prev, open: true }));
  };
  const onShareClose = () => {
    setShareModal({ open: false });
  };

  const downloadAttachment = [data?.purchaseInvoice, data?.warrantyDetailsWarrantyCertificate].filter((e) => e !== '');

  return (
    <>
      <div>
        <IconButton
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem onClick={onEditClick}>
            <ListItemIcon>
              <Iconify icon="ic:twotone-mode-edit-outline" color="#1877F2" width={22} height={22} />
            </ListItemIcon>
            <Typography variant="inherit">Edit</Typography>
          </MenuItem>
          <MenuItem onClick={onDeleteClick}>
            <ListItemIcon>
              <Iconify icon="ic:twotone-delete" color="#DF3E30" width={22} height={22} />
            </ListItemIcon>
            <Typography variant="inherit">Delete</Typography>
          </MenuItem>
          <MenuItem onClick={onDisplayClick}>
            <ListItemIcon>
              <Iconify icon="vaadin:glasses" color="#1877F2" width={22} height={22} />
            </ListItemIcon>
            <Typography variant="inherit">Display</Typography>
          </MenuItem>
          <MenuItem onClick={onShareClick}>
            <Iconify icon="material-symbols:share" color="#1877F2" width={22} height={22} />
            <span style={{ marginLeft: '8px' }}>Share</span>
          </MenuItem>
          <MenuItem onClick={onChangeLogClick}>
            <Iconify icon="mdi:clipboard-text-search" color="#1877F2" width={22} height={22} />
            <span style={{ marginLeft: '8px' }}>Change log</span>
          </MenuItem>
          <MenuItem onClick={onVirtualCardOpen}>
            <ListItemIcon>
              <img src={virtualCardicon} width={22} height={22} style={{ objectFit: 'fill' }} alt="virtualCardicon" />
            </ListItemIcon>
            <Typography variant="inherit">Virtual Card</Typography>
          </MenuItem>
          {downloadAttachment?.length > 0 && <AttachmentsMenu data={downloadAttachment} />}
        </Menu>
      </div>
      <VirtualCardModal
        onClose={onVirtualCardClose}
        open={virtualCardModalOpen.open}
        data={virtualCardModalOpen.data}
      />
      <GlobalShareModal
        link="https://dev.safalsubscriptions.com/warranty"
        title="Warranty"
        open={shareModal.open}
        onClose={onShareClose}
        safalPointKey="ShareWarranty"
      />
    </>
  );
}

const AttachmentsMenu = ({ data }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onDownload = async (url) => {
    handleClose();
    try {
      const res = await downloadAttachmentFile({ url: url });
      if (res.data.success === true) {
        const link = document.createElement('a');
        link.href = res.data.data;
        link.setAttribute('download', `file`);
        document.body.appendChild(link);
        link.click();
      }
    } catch (err) {
      toast.error(err.response.data.message || 'Something went wrong !!');
    }
  };

  const getFileName = (url) => {
    const attachmentName = url?.includes('safalsubscriptions-assets.s3.amazonaws.com')
      ? url?.match(/[^/-]+$/)[0]
      : url?.split('-').slice(4).join('-');
    return attachmentName;
  };

  return (
    <>
      <MenuItem
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <ListItemIcon>
          <Iconify icon="entypo:attachment" color="#1877F2" width={22} height={22} />
        </ListItemIcon>
        <Typography variant="inherit">Attachments</Typography>
      </MenuItem>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {data?.map((e) => (
          <MenuItem onClick={() => onDownload(e)}>{getFileName(e)}</MenuItem>
        ))}
      </Menu>
    </>
  );
};
