import toast from 'react-hot-toast';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import { Image } from 'antd';
import { Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';

import { downloadAttachmentFile, getSignedURL } from '../../services/Service';

const FileImageView = ({ file, url, onRemove }) => {
  const isLocal = file !== null;
  const splitX = isLocal ? file?.name?.split('.') : url?.split('.');

  const fileExt = splitX.length > 0 ? splitX[splitX.length - 1] : '';
  const imageFileExt = ['jpg', 'jpeg', 'png', 'gif'];

  const [imageX, setImageX] = useState(url ?? '');

  const localImageUrl = isLocal ? URL.createObjectURL(file) : '';
  const attachmentName = isLocal
    ? file?.name
    : url?.includes('safalsubscriptions-assets.s3.amazonaws.com')
    ? url?.match(/[^/-]+$/)[0]
    : url?.split('-').slice(4).join('-');

  const getImage = async () => {
    if (isLocal) return;
    if (url === '') return;
    const photoURL = url;
    if (photoURL.includes('safalsubscriptions-assets.s3.amazonaws.com')) {
      const newRes = await getSignedURL({ url: photoURL });
      setImageX(newRes?.data);
    }
  };

  useEffect(() => {
    getImage();
  }, [url]);

  const handleS3Download = async (e) => {
    e.preventDefault();
    try {
      const res = await downloadAttachmentFile({ url: url });
      if (res.data.success === true) {
        const link = document.createElement('a');
        link.href = res.data.data;
        link.setAttribute('download', `file`);
        document.body.appendChild(link);
        link.click();
      }
    } catch (err) {
      toast.error(err.response.data.message || 'Something went wrong !!');
    }
  };

  if (file === null && url === '') return <></>;

  return imageFileExt.includes(fileExt) ? (
    <div style={{ position: 'relative', zIndex: 30000, width: '60px' }}>
      <Image width={60} height={60} src={isLocal ? localImageUrl : imageX} />
      <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
        {!isLocal && (
          <button
            onClick={handleS3Download}
            style={{
              backgroundColor: '#3d71ff',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '2px',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer',
            }}
          >
            <DownloadIcon style={{ width: '18px', height: '18px', color: 'white' }} />
          </button>
        )}
        {onRemove && (
          <button
            onClick={onRemove}
            style={{
              backgroundColor: '#ff4842',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '2px',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer',
            }}
          >
            <DeleteIcon style={{ width: '18px', height: '18px', color: 'white' }} />
          </button>
        )}
      </div>
    </div>
  ) : (
    <div style={{ position: 'relative', zIndex: 30000, width: '60px' }}>
      <Tooltip placement="top" title={attachmentName}>
        <span
          style={{
            userSelect: 'none',
            height: '59px',
            width: '59px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textTransform: 'uppercase',
            fontWeight: 'bold',
            border: '1px solid #3d71ff',
            borderRadius: '2px',
            marginBottom: '7px',
          }}
        >
          {fileExt}
        </span>
      </Tooltip>
      <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
        {!isLocal && (
          <button
            onClick={handleS3Download}
            style={{
              backgroundColor: '#3d71ff',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '2px',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer',
            }}
          >
            <DownloadIcon style={{ width: '18px', height: '18px', color: 'white' }} />
          </button>
        )}
        {onRemove && (
          <button
            onClick={onRemove}
            style={{
              backgroundColor: '#ff4842',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '2px',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer',
            }}
          >
            <DeleteIcon style={{ width: '18px', height: '18px', color: 'white' }} />
          </button>
        )}
      </div>
    </div>
  );
};

export default FileImageView;
