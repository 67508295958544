import {
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
} from '@mui/material';
import toast from 'react-hot-toast';
import { useEffect, useState } from 'react';
import { getWarrantyNotificationUser, updateWarrantyNotificationUser } from '../../services/Service';
import ErrorToast from '../../components/Toast/Error';

const tableCellPStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '200px',
  maxHeight: '100px',
};

const getNames = (key) => {
  switch (key) {
    case '7days':
      return 'Expiry Reminder 7 Days';
    case '15days':
      return 'Expiry Reminder 15 Days';
    case '30days':
      return 'Expiry Reminder 30 Days';
    case '45days':
      return 'Expiry Reminder 45 Days';
    case '90days':
      return 'Expiry Reminder 90 Days';
    default:
      return key;
  }
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const WarrantyNotificationConfig = ({ value }) => {
  const [rawData, setRawData] = useState([]);
  const [businessData, setBusinessData] = useState([]);
  const [disableTableSwitch, setDisableTableSwitch] = useState(false);

  const fetchData = async () => {
    try {
      const res = await getWarrantyNotificationUser();
      if (res.status === 200) {
        setRawData(res.data.data);
        setBusinessData(res.data?.businessData);
      }
    } catch (err) {
      console.log('getSafalBuddyActivitiesConfig fetchData error', err.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const updateNotificationValue = async (data, key, newValue) => {
    const findBusiness = businessData.find((e) => e?.activityName === data?.activityName);
    if (findBusiness && findBusiness?.notification[key] === false) {
      ErrorToast(
        'This functionality falls outside the scope of your current subscription settings. Please review your existing subscription settings. in setting Notification screen'
      );
      return;
    }
    setDisableTableSwitch(true);
    try {
      const payload = { ...data, notification: { ...data.notification, [key]: newValue } };
      const res = await updateWarrantyNotificationUser(payload);
      if (res.status === 200) {
        toast.success(res.data.message);
        fetchData();
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
    } finally {
      setDisableTableSwitch(false);
    }
  };

  return (
    <TabPanel
      value={value}
      index={3}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        p: 0,
      }}
    >
      <TableContainer
        sx={{
          minWidth: 1250,
          overflowX: 'auto',
          maxHeight: '70vh',
        }}
      >
        <Table stickyHeader aria-label="sticky table" sx={{ borderCollapse: 'separate', borderSpacing: '0 10px' }}>
          <TableHead style={{ padding: '0px' }}>
            <TableRow>
              <TableCell
                rowSpan={2}
                sx={{
                  backgroundColor: '#7B9EFD',
                  color: '#fff',
                  borderBottomLeftRadius: '35px',
                  borderTopLeftRadius: '35px',
                  fontWeight: 700,
                }}
              >
                Activity Name
              </TableCell>
              <TableCell sx={{ backgroundColor: '#7B9EFD', color: '#fff', fontWeight: 700 }}>Email</TableCell>
              <TableCell sx={{ backgroundColor: '#7B9EFD', color: '#fff', fontWeight: 700 }}>Text</TableCell>
              <TableCell sx={{ backgroundColor: '#7B9EFD', color: '#fff', fontWeight: 700 }}>MobileApp</TableCell>
              <TableCell sx={{ backgroundColor: '#7B9EFD', color: '#fff', fontWeight: 700 }}>Notif. Screen</TableCell>
              <TableCell sx={{ backgroundColor: '#7B9EFD', color: '#fff', fontWeight: 700 }}>Notif. Banner</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {rawData?.map((val) => (
              <TableRow
                hover
                key={1}
                tabIndex={-1}
                style={{
                  backgroundColor: '#FFFFFF',
                  borderBottomLeftRadius: '200px',
                  borderTopLeftRadius: '200px',
                  paddingLeft: '0px',
                  borderRadius: '200px',
                  boxShadow: 'rgb(100 100 111 / 23%) 0px 0px 8px 2px',
                }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  padding="none"
                  sx={{
                    backgroundColor: '#FFFFFF',
                    borderBottomLeftRadius: '35px',
                    borderTopLeftRadius: '35px',
                  }}
                >
                  <p style={{ ...tableCellPStyle, paddingLeft: '12px', paddingRight: '12px' }}>
                    {getNames(val.activityName)}
                  </p>
                </TableCell>
                <TableCell component="th" scope="row" padding="none" sx={{ backgroundColor: '#FFFFFF', p: 0 }}>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Switch
                      disabled={disableTableSwitch}
                      name={'email'}
                      onChange={() => updateNotificationValue(val, 'email', !val.notification.email)}
                      checked={val.notification.email}
                      style={{ color: '#2065d1' }}
                    />
                  </div>
                </TableCell>
                <TableCell component="th" scope="row" padding="none" sx={{ backgroundColor: '#FFFFFF', p: 0 }}>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Switch
                      disabled={disableTableSwitch}
                      name={'text'}
                      onChange={() => updateNotificationValue(val, 'text', !val.notification.text)}
                      checked={val.notification.text}
                      style={{ color: '#2065d1' }}
                    />
                  </div>
                </TableCell>
                <TableCell component="th" scope="row" padding="none" sx={{ backgroundColor: '#FFFFFF', p: 0 }}>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Switch
                      disabled={disableTableSwitch}
                      name={'mobileApp'}
                      onChange={() => updateNotificationValue(val, 'mobileApp', !val.notification.mobileApp)}
                      checked={val.notification.mobileApp}
                      style={{ color: '#2065d1' }}
                    />
                  </div>
                </TableCell>
                <TableCell component="th" scope="row" padding="none" sx={{ backgroundColor: '#FFFFFF', p: 0 }}>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Switch
                      disabled={disableTableSwitch}
                      name={'notification'}
                      onChange={() => updateNotificationValue(val, 'notification', !val.notification.notification)}
                      checked={val.notification.notification}
                      style={{ color: '#2065d1' }}
                    />
                  </div>
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  padding="none"
                  sx={{
                    backgroundColor: '#FFFFFF',
                    p: 0,
                    borderBottomRightRadius: '35px',
                    borderTopRightRadius: '35px',
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Switch
                      disabled={disableTableSwitch}
                      name={'banner'}
                      onChange={() => updateNotificationValue(val, 'banner', !val.notification.banner)}
                      checked={val.notification.banner}
                      style={{ color: '#2065d1' }}
                    />
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </TabPanel>
  );
};

export default WarrantyNotificationConfig;
