import { Button, InputAdornment, OutlinedInput, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import './FaqList.css';
import { Helmet } from 'react-helmet-async';
import AddIcon from '@mui/icons-material/Add';
import { useLocation, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useEffect, useState } from 'react';

import { getFaqList, deleteFaq, getHelpVideoModule, getAllFaqDownload, getSignedURL } from '../../../services/Service';
import CustomButton from '../../../components/UI/CustomButton';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import TableView from './TableView';
import AddFaqModal from './AddFaqModal';
import CustomMuiSelect from '../../../components/UI/CustomMuiSelect';
import DownloadMenu from './DownloadMenu';

const moduleArray = [
  'General',
  'Dashboard - Notification',
  'Dashboard - Subscriptions',
  'Dashboard - Asset',
  'Dashboard - Platform Pulse',
  'Subscriptions',
  'Subscriptions - Create',
  'Subscriptions - Label',
  'Asset',
  'Asset - Create - Edit',
  'Report',
  'Calender',
  'Settings',
  'Feedback Log',
  'Upgrade Plan',
  'Settings - Profile',
  'Settings - Security',
  'Settings - Notification',
  'SafalBot',
  'SafalSubscriptions AI',
  'Login',
  'Register',
  'Offer',
  'SafalLeaderBoard',
  'SafalRoom - Private',
  'SafalRoom - Public',
  'SafalBuddy',
  'SafalGift',
  'Warranty',
];

const FaqList = () => {
  const [addModalOpen, setAddModalOpen] = useState({
    open: false,
    data: null,
  });
  const [megaModuleList, setMegaModuleList] = useState([]);
  const [rawData, setRawData] = useState([]);
  const [rawDataFilter, setRawDataFilter] = useState([]);
  const [searchString, setSearchString] = useState('');
  const [selectString, setSelectString] = useState('');
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState({
    open: false,
    data: null,
  });

  const fetchData = async () => {
    try {
      const res = await getFaqList();
      if (res.status === 200) {
        setRawData(res.data.data);
        if (selectString !== '') {
          const newFilter = res.data.data.filter((ex) => ex.module === selectString);
          setRawDataFilter(newFilter);
        } else {
          setRawDataFilter(res.data.data);
        }
      }
    } catch (err) {
      console.log('getFaqList fetchData error', err.message);
    }
  };

  const fetchMegaModuleData = async () => {
    try {
      const res = await getHelpVideoModule();
      if (res.status === 200) {
        setMegaModuleList(res.data.data);
      }
    } catch (err) {
      console.log('getFaqList fetchData error', err.message);
    }
  };

  const onAddModalOpen = () => {
    setAddModalOpen({
      open: true,
      data: null,
    });
  };
  const onAddModalOpenForEdit = (data) => {
    setAddModalOpen({
      open: true,
      data: data,
    });
  };
  const onAddModalClose = () => {
    setAddModalOpen({
      open: false,
      data: null,
    });
    fetchData();
  };

  const onDelete = async (data) => {
    try {
      const res = await deleteFaq(data._id);
      if (res.status === 200) {
        toast.success(res.data.message);
        onDeleteModalReset();
        fetchData();
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message || err.message);
    }
  };

  const onDeleteModalReset = () => {
    setIsDeleteConfirmationModalOpen({
      open: false,
      data: null,
    });
  };

  useEffect(() => {
    fetchData();
    fetchMegaModuleData();
  }, []);

  const onSearchChange = (e) => {
    setSelectString('');
    setSearchString(e.target.value);
    if (e.target.value === '') {
      setRawDataFilter(rawData);
    } else {
      const newFilter = rawData.filter((ex) => ex.question.toLowerCase().includes(e.target.value.toLowerCase()));
      setRawDataFilter(newFilter);
    }
  };
  const onSelectChange = (e) => {
    setSelectString(e);
    setSearchString('');
    if (e === '') {
      setRawDataFilter(rawData);
    } else {
      const newFilter = rawData.filter((ex) => ex.module === e);
      setRawDataFilter(newFilter);
    }
  };

  const downloadTrend = async (type) => {
    try {
      const res = await getAllFaqDownload({ module: 'All', fileType: type });
      const signedURLRes = await getSignedURL({
        url: res.data.url,
      });
      const link = document.createElement('a');
      link.href = signedURLRes.data;
      link.setAttribute('download', `file`);
      document.body.appendChild(link);
      link.click();

      toast.success('download successful');
    } catch (err) {
      const msg = err?.response?.data?.message || 'download failed, please try again';
      toast.error(msg);
    }
  };

  return (
    <>
      <Helmet>
        <title>FAQ List - SafalSubscriptions</title>
      </Helmet>

      <div id="FaqList">
        <div className="title-bar">
          <div className="title-search-div">
            <Typography variant="h4" sx={{ fontSize: '40px', fontWeight: 700, color: '#027c34' }}>
              FAQ List
            </Typography>
          </div>
        </div>
        <div className="toggle-bar">
          <div className="toggle-bar-dix">
            <div style={{ width: '300px' }}>
              <CustomMuiSelect
                placeholder="Filter by Module"
                value={selectString}
                onChange={(e) => onSelectChange(e?.label || '')}
                options={moduleArray.map((e) => ({ label: e }))}
                size="small"
                variant="outlined"
              />
            </div>

            <TextField
              sx={{ width: '200px', ml: '12px' }}
              onChange={onSearchChange}
              value={searchString}
              type="search"
              id="search-input"
              size="small"
              label="Search"
              variant="outlined"
            />
          </div>
          <div className="title-action-div">
            <DownloadMenu downloadFile={downloadTrend} />
            <CustomButton backgroundColor="#027c34" text="Add New" icon={<AddIcon />} onClick={onAddModalOpen} />
          </div>
        </div>
        <TableView
          onDelete={(data) => {
            setIsDeleteConfirmationModalOpen({
              open: true,
              data,
            });
          }}
          onEdit={onAddModalOpenForEdit}
          data={rawDataFilter}
        />
      </div>
      <DeleteConfirmationModal
        open={isDeleteConfirmationModalOpen.open}
        onClose={onDeleteModalReset}
        data={isDeleteConfirmationModalOpen.data}
        onSubmit={onDelete}
      />
      <AddFaqModal
        megaModuleList={megaModuleList}
        moduleArray={moduleArray}
        open={addModalOpen.open}
        data={addModalOpen.data}
        onClose={onAddModalClose}
      />
    </>
  );
};

export default FaqList;
