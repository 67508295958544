import { Box, IconButton, InputAdornment, OutlinedInput, Tab, Tabs, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import WestIcon from '@mui/icons-material/West';
import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import { useSearchParams } from 'react-router-dom';

import Page from '../../components/Page';
import LogReport from '../../sections/ReportsTabs/LogReport';
import SubscriptionTrend from '../../sections/ReportsTabs/SubscriptionTrend';
import subTrendImage from '../../assets/subTrend.png';
import LabelReport from '../../sections/ReportsTabs/LabelReport';
import PaymentTab from '../../sections/SettingTabs/PaymentTab';
import labelReportImg from '../../assets/labelReport.png';
import paymentHistoryImg from '../../assets/paymentHistory.png';
import paymentModeImg from '../../assets/payment_mode_report.png';
import subscriptionSpendImg from '../../assets/sub_spend_report.png';
import SubscriptionSpendReport from '../../sections/ReportsTabs/SubscriptionSpendReport';
import FaqModal from '../../components/Modals/FaqModal';
import PaymentModeReport from '../../sections/ReportsTabs/PaymentModeReport';
import SafalLeaderBoardReport from '../../sections/ReportsTabs/SafalLeaderBoardReport';
import SafalBuddyReport from '../../sections/ReportsTabs/SafalBuddyReport';
import SafalGiftOrderReport from '../../sections/ReportsTabs/SafalGiftOrderReport';
import UserAssessmentReport from '../../sections/ReportsTabs/UserAssessmentReport';
import WarrantyReport from '../../sections/ReportsTabs/WarrantyReport';

// Your existing code

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

// const ReportsOld = () => {
//   const data = useSelector((state) => state.dynamicMenuState);

//   const [value, setValue] = useState(0);
//   const handleChange = (event, newValue) => {
//     setValue(newValue);
//   };

//   const reportsSubmenuItems = [
//     ...(data.find((menuItem) => menuItem.menu === 'reports')?.submenu || []),
//     'SafalGift report',
//   ];

//   function capitalizeFirstLetter(string) {
//     return string.charAt(0).toUpperCase() + string.slice(1);
//   }

//   const nameChangeSwitch = (name) => {
//     switch (name) {
//       case 'subscription report':
//         return capitalizeFirstLetter('Subscriptions Spend');
//       case 'label report':
//         return capitalizeFirstLetter('Subscriptions Label');
//       case 'payment':
//         return capitalizeFirstLetter('Payment History');
//       case 'subscription trend':
//         return capitalizeFirstLetter('Subscriptions Trend');
//       case 'payment mode report':
//         return capitalizeFirstLetter('Payment Type Distribution');
//       case 'SafalLeaderBoard report':
//         return capitalizeFirstLetter('SafalLeaderBoard Activity');
//       case 'SafalBuddy report':
//         return capitalizeFirstLetter('SafalBuddy Activity');
//       case 'SafalGift report':
//         return capitalizeFirstLetter('SafalGift Order History');

//       default:
//         return capitalizeFirstLetter(name);
//     }
//   };

//   return (
//     <Page title="Reports - SafalSubscriptions">
//       <Typography
//         variant="h4"
//         sx={{
//           display: 'flex',
//           alignItems: 'center',
//           fontSize: '24px',
//           fontWeight: 700,
//           color: '#3D71FF',
//           '@media (max-width: 600px)': {
//             pb: 5,
//           },
//         }}
//       >
//         Reports <FaqModal module="Report" color="#3D71FF" />
//       </Typography>

//       <Box
//         sx={{
//           backgroundColor: '#FBFBFB',
//           borderRadius: '30px',
//           mt: 4,
//           position: 'relative',
//         }}
//       >
//         <Box
//           sx={{
//             p: 6,
//             '@media (max-width: 600px)': {
//               p: 0,
//             },
//           }}
//         >
//           <Box sx={{ position: 'absolute', mt: -9, overflow: 'auto', marginLeft: '-20px' }}>
//             <Tabs
//               value={value}
//               onChange={handleChange}
//               aria-label="basic tabs example"
//               sx={{
//                 borderRadius: '30px',
//                 color: 'white',
//                 '& .MuiTabs-flexContainer': {
//                   overflow: 'auto',
//                 },
//                 '& .Mui-selected': {
//                   backgroundColor: '#3D71FF',
//                   color: '#FFFFFF',
//                   img: {
//                     filter: 'invert(1)',
//                   },
//                 },
//                 '& .MuiButtonBase-root.Mui-selected': {
//                   textTransform: 'none',
//                   textDecoration: 'none',
//                   color: '#FFFFFF',
//                   img: {
//                     filter: 'invert(1)',
//                   },
//                 },
//                 '& .MuiTabs-indicator': {
//                   display: 'none',
//                 },
//               }}
//             >
//               {reportsSubmenuItems.map((submenuItem, index) => (
//                 <Tab
//                   key={index}
//                   label={nameChangeSwitch(submenuItem)}
//                   {...a11yProps(index)}
//                   icon={
//                     submenuItem === 'subscription trend' ? (
//                       <img
//                         style={{ height: '18px', width: '18px', objectFit: 'cover' }}
//                         src={subTrendImage}
//                         alt="Subscription Trend"
//                       />
//                     ) : submenuItem === 'activity log' ? (
//                       <img
//                         style={{ height: '18px', width: '18px', objectFit: 'cover' }}
//                         src="/assets/svgs/LogReport.svg"
//                         alt="Log Report"
//                       />
//                     ) : submenuItem === 'label report' ? (
//                       <img
//                         style={{ height: '18px', width: '18px', objectFit: 'cover' }}
//                         src={labelReportImg}
//                         alt="label report"
//                       />
//                     ) : submenuItem === 'payment' ? (
//                       <img
//                         style={{ height: '18px', width: '18px', objectFit: 'cover' }}
//                         src={paymentHistoryImg}
//                         alt="payment history"
//                       />
//                     ) : submenuItem === 'payment mode report' ? (
//                       <img
//                         style={{ height: '18px', width: '18px', objectFit: 'cover' }}
//                         src={paymentModeImg}
//                         alt="payment mode report"
//                       />
//                     ) : submenuItem === 'subscription report' ? (
//                       <img
//                         style={{ height: '18px', width: '18px', objectFit: 'contain' }}
//                         src={subscriptionSpendImg}
//                         alt="subscription report"
//                       />
//                     ) : submenuItem === 'SafalLeaderBoard report' ? (
//                       <img
//                         style={{ height: '18px', width: '18px', objectFit: 'contain' }}
//                         src="/assets/images/leaderBoard_black.png"
//                         alt="SafalLeaderBoard report"
//                       />
//                     ) : submenuItem === 'SafalBuddy report' ? (
//                       <img
//                         style={{ height: '18px', width: '18px', objectFit: 'contain' }}
//                         src="/assets/images/safalBuddyIcon.png"
//                         alt="SafalBuddy report"
//                       />
//                     ) : submenuItem === 'SafalGift report' ? (
//                       <img
//                         style={{ height: '18px', width: '18px', objectFit: 'contain' }}
//                         src="/assets/images/safalGiftIcon.png"
//                         alt="SafalGift report"
//                       />
//                     ) : (
//                       <img
//                         style={{ height: '18px', width: '18px', objectFit: 'cover' }}
//                         src="/assets/svgs/Feedback.svg"
//                         alt="Feedback List"
//                       />
//                     )
//                   }
//                   iconPosition="start"
//                   sx={{
//                     fontSize: '13px',
//                     minHeight: '50px',
//                     textTransform: 'none',
//                     paddingLeft: '6px',
//                     paddingRight: '6px',
//                     ':hover': {
//                       backgroundColor: '#3D71FF',
//                       color: '#FFFFFF',
//                       img: {
//                         filter: 'invert(1)',
//                       },
//                     },
//                     backgroundColor: '#FFFFFF',
//                     '@media (max-width: 640px)': {
//                       padding: '4px',
//                     },
//                   }}
//                 />
//               ))}
//             </Tabs>
//           </Box>

//           {reportsSubmenuItems.map((submenuItem, index) => (
//             <TabPanel key={index} value={value} index={index}>
//               {submenuItem === 'activity log' ? <LogReport /> : null}
//               {submenuItem === 'subscription trend' ? <SubscriptionTrend /> : null}
//               {submenuItem === 'label report' ? <LabelReport /> : null}
//               {submenuItem === 'payment' ? <PaymentTab /> : null}
//               {submenuItem === 'subscription report' ? <SubscriptionSpendReport /> : null}
//               {submenuItem === 'payment mode report' ? <PaymentModeReport /> : null}
//               {submenuItem === 'SafalLeaderBoard report' ? <SafalLeaderBoardReport /> : null}
//               {submenuItem === 'SafalBuddy report' ? <SafalBuddyReport /> : null}
//               {submenuItem === 'SafalGift report' ? <SafalGiftOrderReport /> : null}
//             </TabPanel>
//           ))}
//         </Box>
//       </Box>
//     </Page>
//   );
// };

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 300,
  height: 40,
  backgroundColor: '#FFFFFF',
  borderRadius: '30px',
  borderColor: '#FFFFFF',
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

const Reports = () => {
  const data = useSelector((state) => state.dynamicMenuState);
  const [searchParams, setSearchParams] = useSearchParams();
  const statusParam = searchParams.get('page');

  const [value, setValue] = useState(statusParam ?? null);
  const [searchString, setSearchString] = useState('');

  // Check if there are any items in the "reports" submenu
  const reportsSubmenuItems = [
    ...(data.find((menuItem) => menuItem.menu === 'reports')?.submenu || []),
    // 'payment mode report',
    // 'SafalLeaderBoard report',
    // 'SafalBuddy report',
  ];

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const nameChangeSwitch = (name) => {
    switch (name) {
      case 'subscription report':
        return capitalizeFirstLetter('Subscriptions Spend');
      case 'label report':
        return capitalizeFirstLetter('Subscriptions Label');
      case 'payment':
        return capitalizeFirstLetter('Payment History');
      case 'subscription trend':
        return capitalizeFirstLetter('Subscriptions Trend');
      case 'payment mode report':
        return capitalizeFirstLetter('Payment Type Distribution');
      case 'SafalLeaderBoard report':
        return capitalizeFirstLetter('SafalLeaderBoard Activity');
      case 'SafalBuddy report':
        return capitalizeFirstLetter('SafalBuddy Activity');
      case 'SafalGift report':
        return capitalizeFirstLetter('SafalGift Order History');
      case 'warranty':
        return capitalizeFirstLetter('Warranty Report');
      default:
        return capitalizeFirstLetter(name);
    }
  };
  const iconChangeSwitch = (name) => {
    switch (name) {
      case 'subscription report':
        return '/assets/images/NewSubscriptionSpend.png';
      case 'label report':
        return '/assets/images/NewSubscriptionLabel.png';
      case 'payment':
        return '/assets/images/NewPaymentHistory.png';
      case 'subscription trend':
        return '/assets/images/NewSubscriptionTrend.png';
      case 'payment mode report':
        return '/assets/images/NewPaymentDistribution.png';
      case 'SafalLeaderBoard report':
        return '/assets/images/NewLeaderboard.png';
      case 'SafalBuddy report':
        return '/assets/images/NewSafalBuddy.png';
      case 'SafalGift report':
        return '/assets/images/NewSafalGift.png';
      case 'User Assessment':
        return '/assets/images/userAcc_black.png';
      case 'warranty':
        return '/assets/images/safalwarranty_black.png';
      default:
        return '/assets/images/NewLog.png';
    }
  };

  const categoryList = reportsSubmenuItems.map((submenuItem) => ({
    name: nameChangeSwitch(submenuItem),
    icon: iconChangeSwitch(submenuItem),
    key: submenuItem,
  }));

  const onChangeValue = (newValue) => {
    setValue(newValue);
  };

  const onChangeValueClose = () => {
    setValue(null);
  };

  const onChangeSearchValue = (e) => {
    const value = e.target.value;
    setSearchString(value);
  };

  const newCategoryList = categoryList?.filter((e) => e?.name?.toLowerCase()?.match(searchString?.toLowerCase()));

  return (
    <>
      <Page title="Reports - SafalSubscriptions">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '12px',
            marginTop: '16px',
            height: '36px',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
            {value !== null && (
              <IconButton
                onClick={onChangeValueClose}
                title="Back to Report Category"
                id="Back_to_Report_Category"
                sx={{
                  color: 'white',
                  backgroundColor: '#3D71FF',
                  ':hover': {
                    color: 'white',
                    backgroundColor: '#315acc',
                  },
                }}
                size="small"
                variant="contained"
              >
                <WestIcon />
              </IconButton>
            )}
            <Typography
              variant="h4"
              sx={{
                marginLeft: value === null && '46px',
                display: 'flex',
                alignItems: 'center',
                fontSize: '24px',
                fontWeight: 700,
                color: '#3D71FF',
                '@media (max-width: 600px)': {
                  pb: 5,
                },
              }}
            >
              {value === null ? 'Reports' : `Reports - ${nameChangeSwitch(value)}`}{' '}
              <FaqModal module="Report" color="#3D71FF" />
            </Typography>
          </div>
          {value === null && (
            <SearchStyle
              onChange={onChangeSearchValue}
              value={searchString}
              sx={{ marginRight: '100px' }}
              placeholder="Search Reports"
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon sx={{ color: '#3D71FF', width: 20, height: 20 }} />
                </InputAdornment>
              }
            />
          )}
        </div>
        {value === null && (
          <div
            style={{
              flex: 1,
              display: 'flex',
              overflow: 'auto',
              flexWrap: 'wrap',
              gap: '16px',
              justifyContent: 'center',
              padding: '20px 0px',
            }}
          >
            {newCategoryList.map((e, index) => (
              <CategoryCard onClick={() => onChangeValue(e?.key)} key={index} name={e?.name} icon={e?.icon} />
            ))}
          </div>
        )}
        <div style={{ paddingTop: '16px' }}>
          {value === 'activity log' && <LogReport />}
          {value === 'subscription trend' && <SubscriptionTrend />}
          {value === 'label report' && <LabelReport />}
          {value === 'payment' && <PaymentTab />}
          {value === 'subscription report' && <SubscriptionSpendReport />}
          {value === 'payment mode report' && <PaymentModeReport />}
          {value === 'SafalLeaderBoard report' && <SafalLeaderBoardReport />}
          {value === 'SafalBuddy report' && <SafalBuddyReport />}
          {value === 'SafalGift report' && <SafalGiftOrderReport />}
          {value === 'User Assessment' && <UserAssessmentReport />}
          {value === 'warranty' && <WarrantyReport />}
        </div>
      </Page>
    </>
  );
};

export default Reports;

const CategoryCard = ({ onClick, name, icon }) => {
  return (
    <button
      onClick={onClick}
      style={{
        flex: 'none',
        width: '250px',
        height: '180px',
        position: 'relative',
        justifyContent: 'center',
        alignItems: 'flex-end',
        display: 'flex',
        border: '1px solid grey',
        borderRadius: '8px',
        backgroundColor: 'white',
        cursor: 'pointer',
      }}
    >
      <img
        style={{
          width: '50px',
          height: '50px',
          objectFit: 'contain',
          position: 'absolute',
          left: '50%',
          top: '40%',
          transform: 'translate(-50%,-50%)',
        }}
        src={icon}
        alt={name}
      />
      <p style={{ padding: '8px', fontSize: '17px', paddingBottom: '14px', fontWeight: 'bold' }}>{name}</p>
    </button>
  );
};
