/* eslint-disable no-restricted-syntax */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Routes, Route, Navigate } from 'react-router-dom';
import PublicRoutes from './components/auth/PublicRoutes';
import ProtectedRoutes from './components/auth/ProtectedRoutes';
import DashboardLayout from './layouts/dashboard';
import DashBoard from './pages/user/DashBoard';
import Subscription from './pages/user/Subscription';
import Offers from './pages/user/Offers';
import Reports from './pages/Reports';
import Calendar from './pages/user/Calendar';
import Companies from './pages/business/Companies';
import Profile from './pages/common/Profile';
import CompaniesList from './pages/business/CompaniesList';
import AdminTest from './pages/admin/AdminTest';
import Page404 from './pages/common/Page404';
import Login from './pages/common/Login';
import Register from './pages/common/Register';
import BusinessRegister from './pages/common/BusinessRegister';
import ForgetPassword from './pages/common/ForgetPassword';
import VerifyToken from './pages/common/VerifyToken';
import ForgetUserId from './pages/common/ForgetUserId';
import VerifyUserIdToken from './pages/common/VerifyUserIdToken';
import Notifications from './pages/common/Notifications';
import SupportTeamChat from './pages/common/SupportTeamChat';
import AdminRegister from './pages/common/AdminRegister';
import SupportRegister from './pages/common/SupportRegister';
import AdminUserFeedback from './pages/common/AdminUserFeedback';
import BusinessNotifications from './pages/business/BusinessNotifications';
import Promotions from './pages/business/Promotions';
import PromotionsList from './pages/business/PromotionsList';
import CustomCompany from './pages/business/CustomCompany';
import ManageMemory from './pages/business/ManageMemory';
import ManageCall from './pages/business/ManageCall';
import DynamicMenu from './pages/business/dynamicMenu';
import ChangeLog from './pages/business/ChangeLog';
import AssetModuleBusiness from './pages/business/AssetModuleBusiness/AssetModuleBusiness';
import AssetModuleBusinessCustom from './pages/business/AssetModuleBusiness/AssetModuleBusinessCustom';
import AssetModule from './pages/user/AssetModule/AssetModule';
import ManageColorBg from './pages/business/ManageColorBg/ManageColorBg';
import RSSLinkList from './pages/business/RSSLinkList/RSSLinkList';
import BannerList from './pages/business/BannerList/BannerList';
import PaymentModeBusiness from './pages/business/PaymentModeBusiness/PaymentModeBusiness';
import CardSchemeBusiness from './pages/business/CardSchemeBusiness/CardSchemeBusiness';
import PaymentInstrumentBusiness from './pages/business/PaymentInstrumentBusiness/PaymentInstrumentBusiness';
import InitialSubscriptionFeedback from './pages/business/InitialSubscriptionFeedback/InitialSubscriptionFeedback';
import PaymentPlan from './pages/business/PaymentPlan/PaymentPlan';
import PlanFeature from './pages/business/PlanFeature/PlanFeature';
import PaymentPrice from './pages/business/PaymentPrice/PaymentPrice';
import OffersList from './pages/business/OffersList/OffersList';
import UserPlanList from './pages/user/UserPlanList/UserPlanList';
import UserOffersList from './pages/user/UserOffersList/UserOffersList';
import SafalLeaderBoard from './pages/user/SafalLeaderBoard/SafalLeaderBoard';
import CheckoutComplete from './pages/user/CheckoutComplete/CheckoutComplete';
import FaqList from './pages/business/FaqList/FaqList';
import PrivacyPolicyAndTermConditionBusiness from './pages/business/PrivacyPolicyAndTermConditionBusiness/PrivacyPolicyAndTermConditionBusiness';
import CompanyTypeList from './pages/business/CompanyTypeList/CompanyTypeList';
import PdfScanTypeBusiness from './pages/business/PdfScanTypeBusiness/PdfScanTypeBusiness';
import HelpVideoInventoryBusiness from './pages/business/HelpVideoInventoryBusiness/HelpVideoInventoryBusiness';
import HelpVideoModuleBusiness from './pages/business/HelpVideoModuleBusiness/HelpVideoModuleBusiness';
import SafalRoomCommunityForums from './pages/business/SafalRoomCommunityForums/SafalRoomCommunityForums';
import HelpVideo from './pages/user/HelpVideo/HelpVideo';
import GeneralKvBusiness from './pages/business/GeneralKvBusiness/GeneralKvBusiness';
import Page404Offers from './pages/common/Page404Offers';
import SafalLeaderConfigMaster from './pages/business/SafalLeaderConfigMaster/SafalLeaderConfigMaster';
import ApplicationListBusiness from './pages/business/ApplicationListBusiness/ApplicationListBusiness';
import SafalLeaderFrequencyConfig from './pages/business/SafalLeaderFrequencyConfig/SafalLeaderFrequencyConfig';
import SafalLeaderBusiness from './pages/business/SafalLeaderBusiness/SafalLeaderBusiness';
import LeaderBoardMasterCountries from './pages/business/LeaderBoardMasterCountries/LeaderBoardMasterCountries';
import SafalBuddyActivitiesMasterConfig from './pages/business/SafalBuddyActivitiesMasterConfig/SafalBuddyActivitiesMasterConfig';
import SafalLeaderBoardActivitiesMasterConfig from './pages/business/SafalLeaderBoardActivitiesMasterConfig/SafalLeaderBoardActivitiesMasterConfig';
import SafalGiftMasterInventory from './pages/business/SafalGiftMasterInventory/SafalGiftMasterInventory';
import SafalGiftOrder from './pages/business/SafalGiftOrder/SafalGiftOrder';
import SafalGiftPage from './pages/user/SafalGiftPage/SafalGiftPage';
import SafalRoom from './pages/user/SafalRoom/SafalRoom';
import SafalRoomPrivate from './pages/user/SafalRoomPrivate/SafalRoomPrivate';
import SafalRoomPrivateBusiness from './pages/business/SafalRoomPrivateBusiness/SafalRoomPrivateBusiness';
import SafalRoomPrivateInvite from './pages/user/SafalRoomPrivateInvite/SafalRoomPrivateInvite';
import GlobalShareBusiness from './pages/business/GlobalShareBusiness/GlobalShareBusiness';
import SafalBuddyPage from './pages/user/SafalBuddyPage/SafalBuddyPage';
import UserAssessmentBusiness from './pages/business/UserAssessmentBusiness/UserAssessmentBusiness';
import UserPermissionToggleSetting from './pages/business/UserPermissionToggleSetting/UserPermissionToggleSetting';
import BusinessDashboard from './pages/business/BusinessDashboard/BusinessDashboard';
import AdvertisementPageListBusiness from './pages/business/AdvertisementPageListBusiness/AdvertisementPageListBusiness';
import AdvertisementList from './pages/business/AdvertisementList/AdvertisementList';
import SafalWarrantyProductCategory from './pages/business/SafalWarrantyProductCategory/SafalWarrantyProductCategory';
import SafalWarrantyProductSubCategory from './pages/business/SafalWarrantyProductSubCategory/SafalWarrantyProductSubCategory';
import SafalWarrantyStoreRetailer from './pages/business/SafalWarrantyStoreRetailer/SafalWarrantyStoreRetailer';
import SafalWarrantyType from './pages/business/SafalWarrantyType/SafalWarrantyType';
import SafalWarrantyPage from './pages/user/SafalWarrantyPage/SafalWarrantyPage';
import TestPage from './pages/user/TestPage/TestPage';
import WarrantyNotificationMaster from './pages/business/WarrantyNotificationMaster/WarrantyNotificationMaster';
import StripeTransactionsBusiness from './pages/business/StripeTransactionsBusiness/StripeTransactionsBusiness';

const MainRoutes = () => {
  const dynamicMenuState = useSelector((state) => state.dynamicMenuState);

  // console.log('DEBUG dynamicMenuState', dynamicMenuState);

  const subscriptions_DynamicMenuState = dynamicMenuState?.find((e) => e.menu === 'subscriptions');
  const reports_DynamicMenuState = dynamicMenuState?.find((e) => e.menu === 'reports');
  const calendar_DynamicMenuState = dynamicMenuState?.find((e) => e.menu === 'calendar');
  const dashboard_DynamicMenuState = dynamicMenuState?.find((e) => e.menu === 'dashboard');
  const assets_DynamicMenuState = dynamicMenuState?.find((e) => e.menu === 'assets');
  const userOffersList_DynamicMenuState = dynamicMenuState?.find((e) => e.menu === 'offers');
  const safalLeaderBoard_DynamicMenuState = dynamicMenuState?.find((e) => e.menu === 'safalLeaderBoard');
  const safalGift_DynamicMenuState = dynamicMenuState?.find((e) => e.menu === 'safalGift');
  const safalRoom_DynamicMenuState = dynamicMenuState?.find((e) => e.menu === 'safalRoom');
  const userPlanList_DynamicMenuState = dynamicMenuState?.find((e) => e.menu === 'payment');
  const safalWarranty_DynamicMenuState = dynamicMenuState?.find((e) => e.menu === 'warranty');

  return (
    <Routes>
      {/* Business Routes */}
      <Route path="/" element={<ProtectedRoutes roleRequired="business" />}>
        <Route path="/business" element={<DashboardLayout />}>
          <Route path="/business/companies" element={<Companies />} />
          <Route path="/business/chat" element={<SupportTeamChat />} />
          <Route path="/business/feedback" element={<AdminUserFeedback />} />
          {/* <Route path="/business/companies" element={<Companies />} /> */}
          <Route path="/business/notifications" element={<BusinessNotifications />} />
          <Route path="/business/promotions" element={<Promotions />} />
          <Route path="/business/promotionslist" element={<PromotionsList />} />
          <Route path="/business/subscription-feedback" element={<InitialSubscriptionFeedback />} />
          <Route path="/business/custom_company" element={<CustomCompany />} />
          <Route path="/business/companieslist" element={<CompaniesList />} />
          <Route path="/business/managememory" element={<ManageMemory />} />
          <Route path="/business/manage_call" element={<ManageCall />} />
          <Route path="/business/module_configuration" element={<DynamicMenu />} />
          <Route path="/business/change_log" element={<ChangeLog />} />
          <Route path="/business/asset_module" element={<AssetModuleBusiness />} />
          <Route path="/business/asset_module_custom" element={<AssetModuleBusinessCustom />} />
          <Route path="/business/manage_color" element={<ManageColorBg />} />
          <Route path="/business/rss" element={<RSSLinkList />} />
          <Route path="/business/banner" element={<BannerList />} />
          <Route path="/business/plans" element={<PaymentPlan />} />
          <Route path="/business/prices" element={<PaymentPrice />} />
          <Route path="/business/plan_feature" element={<PlanFeature />} />
          <Route path="/business/pdf_scan_type" element={<PdfScanTypeBusiness />} />
          <Route path="/business/payment_mode" element={<PaymentModeBusiness />} />
          <Route path="/business/payment_instrument" element={<PaymentInstrumentBusiness />} />
          <Route path="/business/card_scheme" element={<CardSchemeBusiness />} />
          <Route path="/business/offers" element={<OffersList />} />
          <Route path="/business/faq" element={<FaqList />} />
          <Route path="/business/kv" element={<GeneralKvBusiness />} />
          <Route path="/business/help-video" element={<HelpVideoInventoryBusiness />} />
          <Route path="/business/help-video-module" element={<HelpVideoModuleBusiness />} />
          <Route path="/business/company_type" element={<CompanyTypeList />} />
          <Route path="/business/privacy-policy" element={<PrivacyPolicyAndTermConditionBusiness />} />
          <Route path="/business/safal-leader-config-master" element={<SafalLeaderConfigMaster />} />
          <Route path="/business/safal-leader-frequency-config" element={<SafalLeaderFrequencyConfig />} />
          <Route path="/business/safal-leader-board" element={<SafalLeaderBusiness />} />
          <Route path="/business/safal-buddy-master-config" element={<SafalBuddyActivitiesMasterConfig />} />
          <Route path="/business/safal-leader-board-countries" element={<LeaderBoardMasterCountries />} />
          <Route path="/business/safal-gift-master-inventory" element={<SafalGiftMasterInventory />} />
          <Route path="/business/safal-gift-order" element={<SafalGiftOrder />} />
          <Route path="/business/safal-room-forums" element={<SafalRoomCommunityForums />} />
          <Route path="/business/safal-room-private" element={<SafalRoomPrivateBusiness />} />
          <Route path="/business/global-share" element={<GlobalShareBusiness />} />
          <Route path="/business/user-assessment-master" element={<UserAssessmentBusiness />} />
          <Route path="/business/user-permission-toggle-setting" element={<UserPermissionToggleSetting />} />
          <Route path="/business/advertisement-page" element={<AdvertisementPageListBusiness />} />
          <Route path="/business/advertisement" element={<AdvertisementList />} />
          <Route path="/business/safal-warranty-product-category" element={<SafalWarrantyProductCategory />} />
          <Route path="/business/safal-warranty-product-sub-category" element={<SafalWarrantyProductSubCategory />} />
          <Route path="/business/safal-warranty-retailer" element={<SafalWarrantyStoreRetailer />} />
          <Route path="/business/safal-warranty-type" element={<SafalWarrantyType />} />
          <Route path="/business/safal-warranty-notification" element={<WarrantyNotificationMaster />} />
          <Route path="/business/stripe-transactions" element={<StripeTransactionsBusiness />} />
          <Route
            path="/business/safal-leader-activities-config-master"
            element={<SafalLeaderBoardActivitiesMasterConfig />}
          />
          <Route path="/business/application-list" element={<ApplicationListBusiness />} />
          <Route path="profile" element={<Profile />} />
          <Route path="/business/" element={<BusinessDashboard />} />
        </Route>
        <Route path="/" element={<Navigate replace to="/business/companieslist" />} />
      </Route>

      {/* Admin Routes */}
      <Route path="/" element={<ProtectedRoutes roleRequired="admin" />}>
        <Route path="/admin" element={<DashboardLayout />}>
          <Route path="/admin/dashboard" element={<AdminTest />} />
          <Route path="profile" element={<Profile />} />
          <Route path="/admin/" element={<Navigate replace to="/admin/dashboard" />} />
        </Route>
        <Route path="/" element={<Navigate replace to="/admin/dashboard" />} />
      </Route>

      {/* User Routes */}
      <Route path="/" element={<ProtectedRoutes roleRequired="user" />}>
        <Route path="/" element={<DashboardLayout />}>
          <Route path="dashboard" element={<DashBoard />} />
          {subscriptions_DynamicMenuState && <Route path="subscription" element={<Subscription />} />}
          {reports_DynamicMenuState && <Route path="reports" element={<Reports />} />}
          {calendar_DynamicMenuState && <Route path="calendar" element={<Calendar />} />}
          <Route path="profile" element={<Profile />} />
          <Route path="notifications" element={<Notifications />} />
          {assets_DynamicMenuState && <Route path="asset" element={<AssetModule />} />}
          {userPlanList_DynamicMenuState && <Route path="plans" element={<UserPlanList />} />}
          {userOffersList_DynamicMenuState && <Route path="offers/:type/:id" element={<UserOffersList />} />}
          {userOffersList_DynamicMenuState && <Route path="offers" element={<UserOffersList />} />}
          {safalLeaderBoard_DynamicMenuState && <Route path="safalleaderboard" element={<SafalLeaderBoard />} />}
          {safalGift_DynamicMenuState && <Route path="safalgift" element={<SafalGiftPage />} />}
          {safalRoom_DynamicMenuState && <Route path="safalroom" element={<SafalRoom />} />}
          {safalWarranty_DynamicMenuState && <Route path="warranty" element={<SafalWarrantyPage />} />}
          <Route path="safalbuddy" element={<SafalBuddyPage />} />
          <Route path="safalroom-private" element={<SafalRoomPrivate />} />
          <Route path="safalroom-private-invite" element={<SafalRoomPrivateInvite />} />
          <Route path="help" element={<HelpVideo />} />
          <Route path="checkout-complete" element={<CheckoutComplete />} />
          <Route path="test" element={<TestPage />} />
          <Route path="/" element={<Navigate replace to="/dashboard" />} />
        </Route>
      </Route>

      {/* Public Routes */}
      <Route path="/" element={<PublicRoutes />}>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgetpassword" element={<ForgetPassword />} />
        <Route path="/forgetuserid" element={<ForgetUserId />} />
        {/* <Route path="/verify/:token" element={<VerifyToken />} /> */}
        {/* <Route path="/verify/userid/:token" element={<VerifyUserIdToken />} />
      <Route path="/business-register" element={<BusinessRegister />} /> */}
        <Route path="/admin-register" element={<AdminRegister />} />
        <Route path="/support-register" element={<SupportRegister />} />
        <Route path="/404" element={<Page404 />} />
      </Route>

      <Route path="/verify/:token" element={<VerifyToken />} />
      <Route path="/verify/userid/:token" element={<VerifyUserIdToken />} />
      <Route path="/business-register" element={<BusinessRegister />} />

      {/* Permission denied route */}
      <Route path="offers/:type/:id" element={<Page404Offers />} />
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
};

export default MainRoutes;
