import { MenuItem, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import * as Yup from 'yup';
import { Field, FormikProvider, useFormik } from 'formik';

import CustomSimpleSelect from '../../../components/UI/CustomSimpleSelect';
import DateField from '../../../components/UI/DateField';

const options = [
  { name: 'options 1', _id: 'id01' },
  { name: 'options 2', _id: 'id01' },
  { name: 'options 3', _id: 'id03' },
  { name: 'options 4', _id: 'id04' },
];

const initialValuesX = {
  field01: '',
  field02: '',
  field03: '',
  field04: '',
  field05: '',
  field06: '',
};

const TestPage = () => {
  const [fieldValue, setFieldValue] = useState({ ...initialValuesX });

  const validationSchema = Yup.object({
    field01: Yup.string().required('Please Enter Website'),
    field02: Yup.string().required('Please Select frequency'),
    field03: Yup.date().required('Please Select Contract Start Date').typeError('Please enter a valid date'),
    field04: Yup.string().required('Please Select Auto Renewal'),
  });

  const initialValues = {
    field01: '',
    field02: '',
    field03: '',
    field04: '',
  };
  const formikForm = useFormik({
    enableReinitialize: false,
    initialValues,
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      console.log('DEBUG values', values);
      console.log('DEBUG resetForm in 5sec');
      setTimeout(() => {
        resetForm();
      }, 5000);
    },
  });

  return (
    <div>
      <h1>TestPage</h1>
      <div>
        <h2>Form Test</h2>

        <form onSubmit={formikForm.handleSubmit}>
          <TextField
            label="Field 01"
            size="small"
            type="text"
            fullWidth
            variant="standard"
            InputLabelProps={{
              style: { color: 'black' },
              // shrink: true,
            }}
            inputProps={{
              maxlength: 250,
            }}
            name="field01"
            value={formikForm.values.field01}
            onChange={formikForm.handleChange}
            onBlur={formikForm.handleBlur}
            error={formikForm.touched.field01 && Boolean(formikForm.errors.field01)}
            helperText={formikForm.touched.field01 && formikForm.errors.field01}
          />
          <CustomSimpleSelect
            variant="standard"
            //   disabled={isDisableAll}
            label={
              <Typography sx={{ color: '#B6B6B6' }}>
                Field02 <span style={{ color: 'red' }}>*</span>
              </Typography>
            }
            value={fieldValue.field02}
            onChange={(value) =>
              setFieldValue({
                ...fieldValue,
                field02: value || '',
              })
            }
            valueKey="_id"
            options={options?.map((e) => ({
              label: e?.name,
              ...e,
            }))}
            //   error={fieldValueError?.find((e) => e.key === 'productCategoryId')}
            //   helperText={fieldValueError?.find((e) => e.key === 'productCategoryId')?.error}
          />
          <DateField
            //   disabled={isDisableAll}
            value={fieldValue.field03}
            fullWidth
            variant="standard"
            label={
              <Typography sx={{ color: '#B6B6B6' }}>
                field03 <span style={{ color: 'red' }}>*</span>
              </Typography>
            }
            onChange={(e) => e?.isValid() && setFieldValue((prev) => ({ ...prev, field03: e.toISOString() }))}
            //   error={fieldValueError?.find((e) => e.key === 'purchaseDate')}
            //   helperText={fieldValueError?.find((e) => e.key === 'purchaseDate')?.error}
          />
          <TextField
            //   disabled={isDisableAll}
            value={fieldValue.field04}
            onChange={(e) => setFieldValue({ ...fieldValue, field04: e.target.value })}
            label={
              <Typography sx={{ color: '#B6B6B6' }}>
                Country <span style={{ color: 'red' }}>*</span>
              </Typography>
            }
            size="small"
            type="text"
            fullWidth
            variant="standard"
            InputLabelProps={{
              style: { color: 'black' },
              // shrink: true,
            }}
            // helperText={`${fieldValue.comments.length}/${250}`}
            inputProps={{
              maxlength: 250,
            }}
            select
            //   error={fieldValueError?.find((e) => e.key === 'purchaseLocationCountry')}
            //   helperText={fieldValueError?.find((e) => e.key === 'purchaseLocationCountry')?.error}
          >
            <MenuItem value="USA">United States</MenuItem>
            <MenuItem value="IND">India</MenuItem>
          </TextField>
        </form>
      </div>
    </div>
  );
};

export default TestPage;
