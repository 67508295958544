import { Checkbox, TablePagination, TableCell } from '@mui/material';
import { useSelector } from 'react-redux';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { makeStyles } from '@mui/styles';
import ThreeDotMenu from './ThreeDotMenu';
import {
  CustomTableCellForBody,
  CustomTableCellForNoData,
  CustomTableView,
} from '../../../components/UI/CustomTableView';
import defaultCard from '../../../assets/defaultCard.png';
import useUserTimeZone, { getTimeZoneAbbreviation } from '../../../hooks/useUserTimeZone';
import useSortHook from '../../../hooks/useSortHook';

const tableCellPStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '250px',
  maxHeight: '100px',
};

const TableView = ({
  data,
  paginationData,
  onPageChange,
  onRowsPerPageChange,
  onEdit,
  onDisplay,
  onDelete,
  onSelectedChange,
  selectedAccount,
  onChangeLog,
}) => {
  const { user } = useSelector((state) => state.login);
  const { handleSort, newSortData, sortState } = useSortHook(data, ['purchaseDate']);
  const { expiryCheck, formatDate } = useUserTimeZone();
  const handleDoubleClick = (event, data) => {
    if (event.detail === 2) {
      onDisplay(data);
    }
  };

  const headData = [
    'Actions',
    'SafalWarranty Id',
    'Product Name',
    'Purchase Date',
    'Expiration Date',
    'Warranty Status',
    'Product Price',
    'Extended Warranty',
  ];

  const amountSym = user.country === 'United States' ? '$' : '₹';

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', height: '48px' }}>
        {selectedAccount?.length > 0 ? (
          <div>
            <span>
              <b>{selectedAccount?.length}</b> selected
            </span>
          </div>
        ) : (
          <span />
        )}

        <TablePagination
          component="div"
          size="small"
          count={paginationData?.total ?? 0}
          rowsPerPageOptions={[10, 15, 25, 50, 100]}
          rowsPerPage={paginationData?.limit ?? 15}
          page={paginationData.page - 1}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
        />
      </div>
      <TableContainer sx={{ maxHeight: 600 }}>
        <CustomTableView
          isSort
          sort={{
            order: sortState.order,
            orderBy: sortState.orderBy,
            createSortHandler: handleSort,
            sortHeadMap: [
              '',
              '',
              'productName',
              'purchaseDate',
              'warrantyDetailsExpireDate',
              '',
              'purchasePrice',
              'warrantyDetailsWarrantyTypeId.name',
            ],
          }}
          headData={headData}
        >
          {data.length === 0 && (
            <CustomTableCellForNoData message={`There are no recorded warranties for you. Kindly add a new one.`} />
          )}
          {newSortData?.map((e, index) => {
            const expiryCheckFlag = e?.warrantyDetailsExpireDate ? expiryCheck(e.warrantyDetailsExpireDate) : false;
            return (
              <TableRow key={index} onClick={(ev) => handleDoubleClick(ev, e)}>
                <CustomTableCellForBody align="center" padding="checkbox">
                  <div style={{ display: 'flex' }}>
                    <Checkbox checked={selectedAccount.includes(e._id)} onChange={() => onSelectedChange(e._id)} />
                    <ThreeDotMenu
                      onChangeLog={() => onChangeLog(e)}
                      data={e}
                      onEdit={(flag) => {
                        if (flag) {
                          onDisplay(e);
                        } else {
                          onEdit(e);
                        }
                      }}
                      onDelete={() => onDelete(e)}
                    />
                  </div>
                </CustomTableCellForBody>
                {/* <CustomTableCellForBody align="center">
                  <img
                    src={e?.companyId?.logo ? e?.companyId?.logo : defaultCard}
                    style={{ maxHeight: '50px', objectFit: 'cover', borderRadius: '8px' }}
                    alt="background"
                  />
                </CustomTableCellForBody> */}
                <CustomTableCellForBody align="center">
                  <p style={tableCellPStyle}>{e?.safalWarrantyNumber}</p>
                </CustomTableCellForBody>
                <CustomTableCellForBody align="center">
                  <p
                    // style={tableCellPStyle}
                    style={{
                      ...tableCellPStyle,
                      color: expiryCheckFlag ? '#ff4c00' : e.status === 'Inactive' ? '#a7b1bc' : '#229A16',
                    }}
                  >
                    {e?.productName}
                  </p>
                </CustomTableCellForBody>
                <CustomTableCellForBody align="left">
                  <p style={tableCellPStyle}>{e?.purchaseDate ? `${formatDate(e?.purchaseDate)}` : ''}</p>
                </CustomTableCellForBody>
                <CustomTableCellForBody align="left">
                  <p style={tableCellPStyle}>
                    {e?.warrantyDetailsExpireDate ? `${formatDate(e?.warrantyDetailsExpireDate)}` : ''}
                  </p>
                </CustomTableCellForBody>
                <CustomTableCellForBody align="left">
                  <p style={tableCellPStyle}>{e?.status}</p>
                </CustomTableCellForBody>
                <CustomTableCellForBody align="left">
                  <p style={tableCellPStyle}>{`${amountSym} ${e?.purchasePrice}`}</p>
                </CustomTableCellForBody>
                <CustomTableCellForBody align="left">
                  <p style={tableCellPStyle}>{e?.warrantyDetailsWarrantyTypeId.name}</p>
                </CustomTableCellForBody>
              </TableRow>
            );
          })}
        </CustomTableView>
      </TableContainer>
    </>
  );
};

export default TableView;
