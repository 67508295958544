import { Button, IconButton, InputAdornment, Menu, MenuItem, TextField, Tooltip, Badge } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';

import DateField from '../../../components/UI/DateField';

const initFilter = {
  expireDateTo: '',
  expireDateForm: '',
  purchaseDateTo: '',
  purchaseDateForm: '',
  purchasePriceMin: '',
  purchasePriceMax: '',
  warrantyDetailsWarrantyTypeId: '', // true/false
  status: '', // Active/Inactive
};

const FilterMenu = ({ onFilterReset, filterState, onFilterApply, warrantyTypeData }) => {
  const login = useSelector((state) => state.login);
  const { registered } = login.user;
  const [filterStateLocal, setFilterStateLocal] = useState(initFilter);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    if (!registered) {
      Swal.fire('For added security, we kindly ask you to confirm your primary email before utilizing this feature.');
    } else {
      setFilterStateLocal(filterState);
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setFilterStateLocal(initFilter);
  };

  const handleFilterApply = () => {
    onFilterApply(filterStateLocal);
    handleClose();
  };

  const isThisResetState =
    filterState?.expireDateTo !== '' ||
    filterState?.expireDateForm !== '' ||
    filterState?.purchaseDateTo !== '' ||
    filterState?.purchaseDateForm !== '' ||
    filterState?.purchasePriceMin !== '' ||
    filterState?.purchasePriceMax !== '' ||
    filterState?.warrantyDetailsWarrantyTypeId !== '' ||
    filterState?.status !== '';

  const handleFilterReset = () => {
    isThisResetState && onFilterReset();
    setFilterStateLocal(initFilter);
    handleClose();
  };

  return (
    <div>
      <Tooltip title="Filter">
        <IconButton
          size="small"
          onClick={handleClick}
          sx={{
            display: 'flex',
            alignItems: 'center',
            // ml: 2,
            backgroundColor: '#3D71FF',
            height: '40px',
            width: '40px',
            '&:hover': { backgroundColor: '#3D71FF' },
          }}
        >
          <Badge color="error" variant="dot" invisible={!isThisResetState}>
            <FilterAltIcon sx={{ color: 'white' }} />
          </Badge>
        </IconButton>
      </Tooltip>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          style: {
            width: '350px',
            padding: '0px 8px',
          },
        }}
      >
        <h2>Filter</h2>
        <TextField
          value={filterStateLocal?.status}
          onChange={(e) => setFilterStateLocal((prev) => ({ ...prev, status: e.target.value }))}
          sx={{
            my: '8px',
          }}
          select
          label="Status"
          size="small"
          fullWidth
          variant="outlined"
        >
          <MenuItem value="Active">Active</MenuItem>
          <MenuItem value="Inactive">Inactive</MenuItem>
        </TextField>
        <TextField
          value={filterStateLocal?.warrantyDetailsWarrantyTypeId}
          onChange={(e) => setFilterStateLocal((prev) => ({ ...prev, warrantyDetailsWarrantyTypeId: e.target.value }))}
          sx={{
            my: '8px',
          }}
          select
          label="Extended Warranty"
          size="small"
          fullWidth
          variant="outlined"
        >
          {warrantyTypeData?.map((e, index) => (
            <MenuItem value={e?._id} key={index}>
              {e?.name}
            </MenuItem>
          ))}
        </TextField>
        {/* Mutual Asset */}
        <div style={{ display: 'flex', flexDirection: 'column', gap: '4px', margin: '2px 0px' }}>
          <span className="filter-sub-label-x2" style={{ paddingLeft: '6px' }}>
            Purchase Price
          </span>
          <div style={{ display: 'flex', gap: '8px' }}>
            <TextField
              style={{ marginBottom: '8px' }}
              value={filterStateLocal?.purchasePriceMin}
              onChange={(e) => setFilterStateLocal((prev) => ({ ...prev, purchasePriceMin: e.target.value }))}
              label="Min"
              size="small"
              type="number"
              fullWidth
              variant="outlined"
            />
            <TextField
              style={{ marginBottom: '8px' }}
              value={filterStateLocal?.purchasePriceMax}
              onChange={(e) => setFilterStateLocal((prev) => ({ ...prev, purchasePriceMax: e.target.value }))}
              label="Max"
              size="small"
              type="number"
              fullWidth
              variant="outlined"
            />
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '4px', margin: '2px 0px' }}>
          <span className="filter-sub-label-x2" style={{ paddingLeft: '6px' }}>
            Expire Date
          </span>
          <div style={{ display: 'flex', gap: '8px' }}>
            <DateField
              value={filterStateLocal.expireDateForm}
              fullWidth
              variant="outlined"
              label="From"
              onChange={(e) =>
                e?.isValid() && setFilterStateLocal((prev) => ({ ...prev, expireDateForm: e.toISOString() }))
              }
            />
            <DateField
              value={filterStateLocal.expireDateTo}
              fullWidth
              variant="outlined"
              label="To"
              onChange={(e) =>
                e?.isValid() && setFilterStateLocal((prev) => ({ ...prev, expireDateTo: e.toISOString() }))
              }
            />
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '4px', margin: '2px 0px' }}>
          <span className="filter-sub-label-x2" style={{ paddingLeft: '6px' }}>
            Purchase Date
          </span>
          <div style={{ display: 'flex', gap: '8px' }}>
            <DateField
              value={filterStateLocal.purchaseDateFrom}
              fullWidth
              variant="outlined"
              label="From"
              onChange={(e) =>
                e?.isValid() && setFilterStateLocal((prev) => ({ ...prev, purchaseDateFrom: e.toISOString() }))
              }
            />
            <DateField
              value={filterStateLocal.purchaseDateTo}
              fullWidth
              variant="outlined"
              label="To"
              onChange={(e) =>
                e?.isValid() && setFilterStateLocal((prev) => ({ ...prev, purchaseDateTo: e.toISOString() }))
              }
            />
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'end', gap: '8px' }}>
          <Button
            sx={{
              textTransform: 'capitalize',
              backgroundColor: '#ff4842',
              color: 'white',
              borderRadius: '30px',
              ':hover': { backgroundColor: '#d40700' },
            }}
            onClick={handleClose}
            size="small"
            variant="contained"
          >
            Close
          </Button>
          <Button
            sx={{ textTransform: 'capitalize', borderRadius: '30px' }}
            onClick={handleFilterReset}
            size="small"
            variant="contained"
          >
            Reset
          </Button>
          <Button
            sx={{ textTransform: 'capitalize', borderRadius: '30px' }}
            onClick={handleFilterApply}
            size="small"
            variant="contained"
          >
            Apply
          </Button>
        </div>
      </Menu>
    </div>
  );
};

export default FilterMenu;
