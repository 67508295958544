import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { Switch } from '@mui/material';

import {
  CustomTableCellForBody,
  CustomTableCellForNoData,
  CustomTableView,
} from '../../../components/UI/CustomTableView';

const tableCellPStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '250px',
  maxHeight: '100px',
};

const getNames = (key) => {
  switch (key) {
    case '7days':
      return 'Reminder 7 Days';
    case '15days':
      return 'Reminder 15 Days';
    case '30days':
      return 'Reminder 30 Days';
    case '45days':
      return 'Reminder 45 Days';
    case '90days':
      return 'Reminder 90 Days';
    default:
      return key;
  }
};

const TableView = ({ data, disabled, updateNotificationValue }) => {
  const headData = ['Activity Name', 'Email', 'Text', 'Mobile App', 'Notification Screen', 'Notification Banner'];

  return (
    <TableContainer>
      <CustomTableView headData={headData}>
        {data.length === 0 && <CustomTableCellForNoData message="You Don't have any data." />}
        {data?.map((e, index) => (
          <TableRow key={index}>
            <CustomTableCellForBody align="center">
              <p style={tableCellPStyle}>{getNames(e?.activityName)}</p>
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              <Switch
                disabled={disabled}
                name={'email'}
                onChange={() => updateNotificationValue(e, 'email', !e?.notification.email)}
                checked={e?.notification.email}
                color="success"
              />
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              <Switch
                disabled={disabled}
                name={'text'}
                onChange={() => updateNotificationValue(e, 'text', !e?.notification.text)}
                checked={e?.notification.text}
                color="success"
              />
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              <Switch
                disabled={disabled}
                name={'mobileApp'}
                onChange={() => updateNotificationValue(e, 'mobileApp', !e?.notification.mobileApp)}
                checked={e?.notification.mobileApp}
                color="success"
              />
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              <Switch
                disabled={disabled}
                name={'notification'}
                onChange={() => updateNotificationValue(e, 'notification', !e?.notification.notification)}
                checked={e?.notification.notification}
                color="success"
              />
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              <Switch
                disabled={disabled}
                name={'banner'}
                onChange={() => updateNotificationValue(e, 'banner', !e?.notification.banner)}
                checked={e?.notification.banner}
                color="success"
              />
            </CustomTableCellForBody>
          </TableRow>
        ))}
      </CustomTableView>
    </TableContainer>
  );
};

export default TableView;
