import * as React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { MenuItem, TextField } from '@mui/material';
import toast from 'react-hot-toast';
import { Image } from 'antd';

import { createApplicationList, updateApplicationList, globalImagesUpload } from '../../../services/Service';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '8px',
  boxShadow: 24,
  displey: 'flex',
  flexDirection: 'column',
  gap: '12px',
  p: 4,
  maxHeight: '95vh',
  overflow: 'auto',
  '@media (max-width: 640px)': {
    width: '95vw',
  },
};

const initFieldValue = {
  name: '',
  logo: '',
  logoSquare: '',
  countries: [],
};

export default function AddApplicationListBusinessModal({ open, onClose, data, isDisplay, countriesList }) {
  const [fieldValue, setFieldValue] = React.useState(initFieldValue);
  const [isDisableAll, setIsDisableAll] = React.useState(false);

  const [logoUpload, setLogoUpload] = React.useState(null);
  const [logoSquareUpload, setLogoSquareUpload] = React.useState(null);

  const handleClose = () => {
    setFieldValue(initFieldValue);
    setIsDisableAll(false);
    onClose();
  };

  const onSubmit = async () => {
    setIsDisableAll(true);
    try {
      const payload = { ...fieldValue };
      if (logoUpload) {
        const fileRes = await globalImagesUpload([logoUpload], 'application', 'public');
        if (fileRes.data?.data?.[0]) {
          payload.logo = fileRes.data?.data?.[0];
        }
      }
      if (logoSquareUpload) {
        const fileRes = await globalImagesUpload([logoSquareUpload], 'application', 'public');
        if (fileRes.data?.data?.[0]) {
          payload.logoSquare = fileRes.data?.data?.[0];
        }
      }
      const res = await createApplicationList(payload);
      if (res.status === 200) {
        toast.success(res.data.message);
        handleClose();
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
    } finally {
      setIsDisableAll(false);
    }
  };

  const onSubmitUpdate = async () => {
    setIsDisableAll(true);
    try {
      const payload = { _id: data._id, ...fieldValue };
      if (logoUpload) {
        const fileRes = await globalImagesUpload([logoUpload], 'application', 'public');
        if (fileRes.data?.data?.[0]) {
          payload.logo = fileRes.data?.data?.[0];
        }
      }
      if (logoSquareUpload) {
        const fileRes = await globalImagesUpload([logoSquareUpload], 'application', 'public');
        if (fileRes.data?.data?.[0]) {
          payload.logoSquare = fileRes.data?.data?.[0];
        }
      }

      const res = await updateApplicationList(payload);
      if (res.status === 200) {
        toast.success(res.data.message);
        handleClose();
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
    } finally {
      setIsDisableAll(false);
    }
  };

  React.useEffect(() => {
    if (open === true) {
      const countriesMap = countriesList?.map((e) => ({
        country: e._id,
        isLeaderBoardAllow: false,
        isCrossCountryLeaderBoardAllow: false,
      }));
      if (data !== null) {
        setFieldValue({
          name: data?.name || '',
          logo: data?.logo || '',
          logoSquare: data?.logoSquare || '',
          countries: data?.countries || [],
        });
        setIsDisableAll(isDisplay);
      } else {
        setFieldValue({
          name: '',
          logo: '',
          logoSquare: '',
          countries: countriesMap,
        });
      }
    }
  }, [open, data, isDisplay]);

  const logoUrl = logoUpload ? URL.createObjectURL(logoUpload) : fieldValue?.logo?.length > 1 ? fieldValue?.logo : '';

  const logoSquareUrl = logoSquareUpload
    ? URL.createObjectURL(logoSquareUpload)
    : fieldValue?.logoSquare?.length > 1
    ? fieldValue?.logoSquare
    : '';

  return (
    <Modal open={open}>
      <Box sx={style}>
        <Typography
          variant="h4"
          gutterBottom
          sx={{ marginBottom: '16px', fontSize: '30px', fontWeight: 700, color: '#19c25f' }}
        >
          {data === null ? 'Add Application' : isDisplay ? 'Application Info' : 'Update Application'}
        </Typography>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
          {/* logo */}
          <div
            style={{
              display: 'flex',
              gap: '12px',
              alignItems: 'flex-end',
            }}
          >
            {logoUrl && (
              <div style={{ flex: 'none' }}>
                <Image
                  preview={false}
                  style={{ objectFit: 'cover', width: '50px', height: '50px', flex: 'none', borderRadius: '8px' }}
                  src={logoUrl}
                  alt="logo"
                />
              </div>
            )}
            <TextField
              onChange={(e) => {
                if (!e.target.files[0].name.match(/\.(jpg|jpeg|png)$/)) {
                  toast.error('Please upload image in JPG, JPEG, PNG Format ONLY.');
                } else {
                  setLogoUpload(e.target.files[0]);
                }
              }}
              type="file"
              label="Logo"
              size="small"
              fullWidth
              variant="outlined"
              InputProps={{
                accept: 'image/*',
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          {/* logoSquareUrl */}
          <div
            style={{
              display: 'flex',
              gap: '12px',
              alignItems: 'flex-end',
            }}
          >
            {logoSquareUrl && (
              <div style={{ flex: 'none' }}>
                <Image
                  preview={false}
                  style={{ objectFit: 'cover', width: '50px', height: '50px', flex: 'none', borderRadius: '8px' }}
                  src={logoSquareUrl}
                  alt="logo"
                />
              </div>
            )}
            <TextField
              onChange={(e) => {
                if (!e.target.files[0].name.match(/\.(jpg|jpeg|png)$/)) {
                  toast.error('Please upload image in JPG, JPEG, PNG Format ONLY.');
                } else {
                  setLogoSquareUpload(e.target.files[0]);
                }
              }}
              type="file"
              label="Logo Square"
              size="small"
              fullWidth
              variant="outlined"
              InputProps={{
                accept: 'image/*',
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          {/* name */}
          <TextField
            value={fieldValue.name}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, name: e.target.value }))}
            required
            label="Application Name"
            name="name"
            size="small"
            type="text"
            fullWidth
            variant="outlined"
            disabled={isDisableAll}
          />
          {countriesList?.map((country, index) => (
            <div key={index} style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
              <span>{country?.name}</span>
              <div style={{ display: 'flex', gap: '16px' }}>
                <TextField
                  value={
                    fieldValue.countries?.find((ex) => ex.country === country._id)?.isLeaderBoardAllow === true
                      ? 'Yes'
                      : 'No'
                  }
                  onChange={(e) =>
                    setFieldValue((prev) => {
                      const newPrev = { ...prev };
                      const findX = newPrev.countries?.findIndex((ex) => ex?.country === country?._id);
                      if (findX !== -1) {
                        newPrev.countries[findX].isLeaderBoardAllow = e.target.value === 'Yes';
                      } else {
                        const payload = {
                          country: country._id,
                          isLeaderBoardAllow: e.target.value === 'Yes',
                          isCrossCountryLeaderBoardAllow: false,
                        };
                        newPrev.countries.push(payload);
                      }
                      return newPrev;
                    })
                  }
                  required
                  select
                  label="SafalLeaderBoard Allow ?"
                  size="small"
                  disabled={isDisableAll}
                  fullWidth
                  variant="outlined"
                  sx={{ flex: '1' }}
                >
                  <MenuItem value="Yes">Yes</MenuItem>
                  <MenuItem value="No">No</MenuItem>
                </TextField>
                <TextField
                  value={
                    fieldValue.countries?.find((ex) => ex.country === country._id)?.isCrossCountryLeaderBoardAllow ===
                    true
                      ? 'Yes'
                      : 'No'
                  }
                  onChange={(e) =>
                    setFieldValue((prev) => {
                      const newPrev = { ...prev };
                      const findX = newPrev?.countries?.findIndex((ex) => ex.country === country._id);
                      if (findX !== -1) {
                        newPrev.countries[findX].isCrossCountryLeaderBoardAllow = e.target.value === 'Yes';
                      } else {
                        const payload = {
                          country: country._id,
                          isLeaderBoardAllow: false,
                          isCrossCountryLeaderBoardAllow: e.target.value === 'Yes',
                        };
                        newPrev.countries.push(payload);
                      }
                      return newPrev;
                    })
                  }
                  required
                  select
                  label="Is Cross Country Leader Board Allow ?"
                  size="small"
                  disabled={isDisableAll}
                  fullWidth
                  variant="outlined"
                  sx={{ flex: '1' }}
                >
                  <MenuItem value="Yes">Yes</MenuItem>
                  <MenuItem value="No">No</MenuItem>
                </TextField>
              </div>
            </div>
          ))}
        </div>
        <div style={{ display: 'flex', gap: '12px', marginTop: '16px', justifyContent: 'end' }}>
          <Button
            disabled={isDisplay ? false : isDisableAll}
            onClick={handleClose}
            sx={{ backgroundColor: '#19c25f', textTransform: 'capitalize', ':hover': { backgroundColor: '#19c25f' } }}
            variant="contained"
          >
            Cancel
          </Button>
          {!isDisplay && (
            <Button
              disabled={isDisableAll}
              onClick={data === null ? onSubmit : onSubmitUpdate}
              sx={{ backgroundColor: '#19c25f', textTransform: 'capitalize', ':hover': { backgroundColor: '#19c25f' } }}
              variant="contained"
            >
              {data === null ? 'Create' : 'Update'}
            </Button>
          )}
        </div>
      </Box>
    </Modal>
  );
}
