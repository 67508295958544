/* eslint-disable */
import queryString from 'query-string';
import axios from 'axios';
import FileDownload from 'js-file-download';
import { forEach } from 'lodash';
import { convertJSONToFormData } from '../utils/fn';
import ErrorToast from '../components/Toast/Error';
import { getTimeZoneAbbreviation } from '../hooks/useUserTimeZone';

export const LoginResponse = (values) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/login`,
    { ...values, device: 'Web' },
    {
      withCredentials: true,
    }
  );
};

export const deleteAccountConfig = () =>
  axios.delete(`${process.env.REACT_APP_API_URL}/deleteuser`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });

export const RegisterResponse = (values) => axios.post(`${process.env.REACT_APP_API_URL}/registerwithfirebase`, values);
// export const RegisterResponse = (values) => axios.post(`${process.env.REACT_APP_API_URL}/register`, values);

export const GetUserResponse = () => {
  const token = localStorage.getItem('Jtoken');
  // if (token) {
  return axios.get(`${process.env.REACT_APP_API_URL}/getuser`, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
  // }
};

export const getAvailableSupportUserId = () => {
  const token = localStorage.getItem('Jtoken');
  return axios.get(`${process.env.REACT_APP_API_URL}/get-available-support-user-id`, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
};

export const SaveCompanyResponse = (values) =>
  axios.post(`${process.env.REACT_APP_API_URL}/savecompany`, values, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
      'Content-Type': 'multipart/form-data',
    },
  });

// ************************************************

export const ValidateUserId = (values) =>
  axios.post(`${process.env.REACT_APP_API_URL}/validate-userid`, values, {
    // headers: {
    //   authorization: `Bearer ${localStorage.getItem('JtokSen')}`,
    //   'Content-Type': 'multipart/form-data',
    // },
  });

export const getUserCallLimit = (id) =>
  axios.get(`${process.env.REACT_APP_API_URL}/user/storage/call/${id}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('JtokSen')}`,
    },
  });
export const getCallLogs = (id, secondaryId, from, to, type) => {
  let query = `?id=${id}`;
  if (secondaryId) query += `&secondaryId=${secondaryId}`;
  if (from) query += `&from=${from}`;
  if (to) query += `&to=${to}`;
  if (type) query += `&type=${type}`;
  return axios.get(`${process.env.REACT_APP_API_URL}/call/${query}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('JtokSen')}`,
    },
  });
};

export const getChatLimit = async () => {
  const callOthers = await axios.get(`${process.env.REACT_APP_API_URL}/storage/chatAttachement`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
  return callOthers;
};

export const updateChatLimit = async (data) => {
  const update = await axios.patch(`${process.env.REACT_APP_API_URL}/storage/chatAttachement`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
  return update;
};

export const getUserChatLimit = async (id) => {
  const chatLimit = await axios.get(`${process.env.REACT_APP_API_URL}/user/storage/chatAttachement?id=${id}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });

  return chatLimit;
};

export const getCallLimit = (values) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/storage/call`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const updateCallLimit = (values) =>
  axios.patch(`${process.env.REACT_APP_API_URL}/storage/call`, values, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });

export const ValidateEmailId = (values) =>
  axios.post(`${process.env.REACT_APP_API_URL}/validate-emailid`, values, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });

export const getNotificationConfig = () =>
  axios.get(`${process.env.REACT_APP_API_URL}/notification-config`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });

export const updateNotificationConfig = (data) => {
  console.log(data);
  const response = axios.patch(`${process.env.REACT_APP_API_URL}/notification-config`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });

  return response;
};

export const notifyEachUser = (data) => {
  const response = axios.post(`${process.env.REACT_APP_API_URL}/notification-config/media-status-update`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });

  return response;
};

export const updateNotificationConfigPremium = async (data) => {
  console.log(data);
  const res = await axios.patch(`${process.env.REACT_APP_API_URL}/notification-config/premium`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
  return res;
};

//  ******************************************************

export const GetcountiesResponse = () => axios.get(`${process.env.REACT_APP_API_URL}/countries`);

export const GetcompaniesResponse = () =>
  axios.get(`${process.env.REACT_APP_API_URL}/getcompanies`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });

export const GetCustomCompaniesResponse = () =>
  axios.get(`${process.env.REACT_APP_API_URL}/getcustomcompanies`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });

export const GetcompaniesCountry = (country) =>
  axios.get(`${process.env.REACT_APP_API_URL}/getcompanies/${country}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });

export const GetsubsResponse = (page = 1, limit = 5, orderBy = null, order = null, parametersObject = {}) => {
  const payload = {
    // startDate: parametersObject.startDate,
    nextBillingFrom: parametersObject.nextBillingFrom,
    nextBillingTo: parametersObject.nextBillingTo,
    expiryDateFrom: parametersObject.expiryDateFrom,
    expiryDateTo: parametersObject.expiryDateTo,
    startDateFrom: parametersObject.startDateFrom,
    startDateTo: parametersObject.startDateTo,
    subscriptionName: parametersObject.subscriptionName,
    amountMin: parametersObject.amountMin,
    amountMax: parametersObject.amountMax,
    frequency: parametersObject.frequency,
    status: parametersObject.status,
    isLiked: parametersObject.isLiked,
    rating: parametersObject.rating,
    subType: parametersObject.subType,
    label: parametersObject.label,
  };

  Object.keys(payload).forEach((key) => {
    if (payload[key] === null || payload[key] === undefined) {
      delete payload[key];
    }
  });

  const queryParams = new URLSearchParams(payload).toString();

  return axios.get(
    `${process.env.REACT_APP_API_URL}/getsubs?page=${page}&limit=${limit}&orderby=${orderBy}&order=${order}&${queryParams}`,
    {
      headers: {
        authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
      },
    }
  );
};

export const PromotionResponse = (values) =>
  axios.post(`${process.env.REACT_APP_API_URL}/notification-config/promotion`, values, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });

export const getPromotionConfig = (search = '') =>
  axios.get(`${process.env.REACT_APP_API_URL}/promotions?search=${search}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });

export const deletePromotion = (id) =>
  axios.delete(`${process.env.REACT_APP_API_URL}/promotion/${id}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });

export const getPresignedURL = async (values) =>
  axios.post(`${process.env.REACT_APP_API_URL}/firebase/generatePreSignedURL`, values, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });

export const getSignedURL = async (values) =>
  axios.post(`${process.env.REACT_APP_API_URL}/firebase/generateSignedURL`, values, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });

export const deleteAttachment = (values) => {
  axios.post(`${process.env.REACT_APP_API_URL}/firebase/deleteAttachment`, values, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const deleteAttachmentByURL = (values) => {
  axios.post(`${process.env.REACT_APP_API_URL}/firebase/deleteAttachment-url`, values, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const uploadAttachment = async (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/firebase/uploadAttachment`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const AttachmentResponse = (e) =>
  axios.post(
    `${process.env.REACT_APP_API_URL}/attachment`,
    { attachment: e.target.files[0] },
    {
      headers: {
        authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
        'Content-Type': 'multipart/form-data',
      },
    }
  );

export const SavesubsResponse = (values) =>
  axios.post(
    `${process.env.REACT_APP_API_URL}/savesubs`,
    { ...values, device: values?.device ? values?.device : 'Web' },
    {
      headers: {
        authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
        'Content-Type': 'multipart/form-data',
      },
    }
  );

export const SavesubsResponseForAlready = (values) =>
  axios.post(`${process.env.REACT_APP_API_URL}/savesubs-already`, values, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
      'Content-Type': 'multipart/form-data',
    },
  });

export const SavesubsBulkResponse = (values) =>
  axios.post(`${process.env.REACT_APP_API_URL}/savebulk`, values, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
      'Content-Type': 'multipart/form-data',
    },
  });

// export const EditsubsResponse = (id, { attachment, ...values }) =>
export const EditsubsResponse = (id, { ...values }) => {
  const form = new FormData();
  form.append('id', id);
  const formdata = convertJSONToFormData(values, form);
  if (values.attachment.length) {
    values.attachment.forEach((file) => {
      if (file instanceof File) {
        formdata.append('attachment', file); // File
      }
    });
  } else {
    formdata.append('attachment', JSON.stringify([])); // Object
  }

  return axios.post(
    `${process.env.REACT_APP_API_URL}/editsub`,
    // { id, attachment, event: `You Edited ${values.subscriptionName}`, ...values },
    // { id, attachment, ...values },
    formdata,
    {
      headers: {
        authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
        'Content-Type': 'multipart/form-data',
      },
    }
  );
};
export const ChangeStatusResponse = (id, status) =>
  axios.post(
    `${process.env.REACT_APP_API_URL}/changestatus`,
    { id, values: { status } },
    {
      headers: {
        authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
      },
    }
  );

export const ChangeRatingResponse = (id, rating) =>
  axios.post(
    `${process.env.REACT_APP_API_URL}/changerating`,
    { id, values: { rating }, event: 'You gave Rating to ' },
    {
      headers: {
        authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
      },
    }
  );

export const ChangeLikeResponse = (id, isLiked) =>
  axios.post(
    `${process.env.REACT_APP_API_URL}/changelike`,
    { id, values: { isLiked }, event: 'You gave safal to ' },
    {
      headers: {
        authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
      },
    }
  );

export const EditComapnysubsResponse = (values) =>
  axios.post(`${process.env.REACT_APP_API_URL}/editcompany`, values, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
      'Content-Type': 'multipart/form-data',
    },
  });

export const DeleteBanner = (id) =>
  axios.delete(`${process.env.REACT_APP_API_URL}/notification-config/banner/${id}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });

export const DeletesubResponse = (values) =>
  axios.post(`${process.env.REACT_APP_API_URL}/deletsub`, values, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });

export const DeleteCompanyResponse = (id) =>
  axios.post(
    `${process.env.REACT_APP_API_URL}/deletecompany`,
    { id },
    {
      headers: {
        authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
      },
    }
  );

export const DeletAllResponse = (values) =>
  axios.post(`${process.env.REACT_APP_API_URL}/deletsub`, values, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
export const DeletAllCompanyResponse = (selectedIDs) =>
  axios.post(
    `${process.env.REACT_APP_API_URL}/deletecompany`,
    { id: selectedIDs },
    {
      headers: {
        authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
      },
    }
  );

export const ProfilepicResponse = (e) =>
  axios
    .post(
      `${process.env.REACT_APP_API_URL}/profilepic`,
      { profilepic: e.target.files[0], event: { call: 'EditPic' } },
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    .catch((err) => {
      ErrorToast(err.response.data.message || 'Something went wrong !!');
    });

export const ChangePasswordResponse = (oldPass, newPass) =>
  axios.post(
    `${process.env.REACT_APP_API_URL}/changepassonl`,
    {
      oldPassword: oldPass,
      newPassword: newPass,
      event: 'You Changed Your Password',
    },
    {
      headers: {
        authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
      },
    }
  );
// export const FilterResponse = (query, searchArr) =>
//   axios.post(
//     `${process.env.REACT_APP_API_URL}/search`,
//     {
//       search: query,
//       columns: searchArr,
//     },
//     {
//       headers: {
//         authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
//       },
//     }
//   );

export const FilterResponse = (
  startDate,
  nextBilling,
  subscriptionName,
  amount,
  frequency,
  status,
  isLiked,
  rating,
  subType,
  page = 1,
  limit = 5
) =>
  axios.post(
    `${process.env.REACT_APP_API_URL}/newsearchfilters?page=${page}&limit=${limit}`,
    {
      startDate,
      nextBilling,
      subscriptionName,
      amount,
      frequency,
      status,
      isLiked,
      rating,
      subType,
    },
    {
      headers: {
        authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
      },
    }
  );

export const PersonalInfoResponse = (values) =>
  axios.post(`${process.env.REACT_APP_API_URL}/personaldetails`, values, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });

export const singleEventResponse = (id) =>
  axios.post(
    `${process.env.REACT_APP_API_URL}/singlesub`,
    { id },
    {
      headers: {
        authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
      },
    }
  );

export const TotalBudgetResponse = (values) =>
  axios.post(`${process.env.REACT_APP_API_URL}/changebudget`, values, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });

export const LogReportResponse = (page = 1, limit = 5) =>
  axios.get(`${process.env.REACT_APP_API_URL}/getreports?page=${page}&limit=${limit}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });

export const downloadReportData = (values, page = 1, limit = 5) =>
  axios.post(`${process.env.REACT_APP_API_URL}/downloadreport?page=${page}&limit=${limit}`, values, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });

export const AddAddonEmails = (values) =>
  axios.post(`${process.env.REACT_APP_API_URL}/addEmail`, values, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });

export const DeleteAddonEmails = (values) =>
  axios.post(`${process.env.REACT_APP_API_URL}/deleteAddonmail`, values, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });

export const NotificationSettings = (values) =>
  axios.put(`${process.env.REACT_APP_API_URL}/notification-settings`, values, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });

export const GetNotifications = () => {
  const token = localStorage.getItem('Jtoken');
  if (token) {
    return axios.get(`${process.env.REACT_APP_API_URL}/getnotifications`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
  }
};

export const MarkAsReadNotification = (id) =>
  axios.put(`${process.env.REACT_APP_API_URL}/markread`, id, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });

export const filterReports = (values, page = 1, limit = 5, order = 'desc', orderBy = 'createdAt') =>
  axios.post(
    `${process.env.REACT_APP_API_URL}/filter-reports?page=${page}&limit=${limit}&orderBy=${orderBy}&order=${order}`,
    values,
    {
      headers: {
        authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
      },
    }
  );

export const downloadSubData = (values) =>
  axios.post(`${process.env.REACT_APP_API_URL}/downloadfile`, values, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });

export const sendVerificationCodeResponse = (value) =>
  axios.post(`${process.env.REACT_APP_API_URL}/sendverificationcode`, value);

export const VerifyPhoneResponse = (phoneNo, verificationcode, user = null) =>
  axios.post(`${process.env.REACT_APP_API_URL}/verifyphoneno`, {
    phoneNo: phoneNo,
    verificationcode: verificationcode,
    user: user,
  });

export const VerifyEmailResponse = (data) =>
  axios.post(`${process.env.REACT_APP_API_URL}/verifyemail`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });

export const FbloginResponse = (res) => axios.post(`${process.env.REACT_APP_API_URL}/fblogin`, res);

export const ForgotpassResponse = (values) => axios.post(`${process.env.REACT_APP_API_URL}/forgotpass`, values);

export const ForgotUserIdResponse = (values) => axios.post(`${process.env.REACT_APP_API_URL}/forgotuserid`, values);

export const VerifyLoginToken = (values, props) =>
  axios.post(`${process.env.REACT_APP_API_URL}/verify/${props.token}`, values);

export const addFeedback = (data) =>
  axios.post(`${process.env.REACT_APP_API_URL}/feedback`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
      'Content-Type': 'multipart/form-data',
    },
  });

export const updateFeedback = (data, id) =>
  axios.put(`${process.env.REACT_APP_API_URL}/feedback/${id}`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });

export const sendUserNotification = (data) =>
  axios.post(`${process.env.REACT_APP_API_URL}/notification-config/add-user-notification`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
export const sendUserNotificationForCall = (data) =>
  axios.post(`${process.env.REACT_APP_API_URL}/notification-config/add-user-notification-call`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });

export const handleFeedback = (data) =>
  axios.post(`${process.env.REACT_APP_API_URL}/feedback/update-feedback`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
      'Content-Type': 'multipart/form-data',
    },
  });

export const getFeedbacks = ({ page = 1, limit = 10, orderBy = null, order = null, search = '' }) =>
  axios.get(
    `${process.env.REACT_APP_API_URL}/feedback?page=${page}&limit=${limit}&orderby=${orderBy}&order=${order}&query=${search}`,
    {
      headers: {
        authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
      },
    }
  );

export const getFeedback = (data) =>
  axios.get(`${process.env.REACT_APP_API_URL}/feedback/${data.id}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });

export const deleteFeedback = (id) =>
  axios.delete(`${process.env.REACT_APP_API_URL}/feedback/${id}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });

export const VerifyUserIdLoginToken = (values, props) =>
  axios.post(`${process.env.REACT_APP_API_URL}/verify/userid/${props.token}`, values);

export const Logout = () =>
  axios.post(
    `${process.env.REACT_APP_API_URL}/logout`,
    {},
    {
      headers: {
        authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
      },
    }
  );
export const getUserFeedbacks = ({ page = 1, limit = 5, id, filter, from, to }) => {
  console.log('filter', filter);

  return axios.post(
    `${process.env.REACT_APP_API_URL}/feedback/user-feedback/?page=${page}&limit=${limit}&id=${id}&from=${from}&to=${to}`,
    filter,
    {
      headers: {
        authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
      },
    }
  );
};

export const getShiftTiming = () =>
  axios.get(`${process.env.REACT_APP_API_URL}/shift-timing`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
export const addShiftTiming = (data) =>
  axios.post(`${process.env.REACT_APP_API_URL}/shift-timing`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
export const updateShiftTiming = (data) =>
  axios.put(`${process.env.REACT_APP_API_URL}/shift-timing`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
export const deleteShiftTiming = (id) =>
  axios.delete(`${process.env.REACT_APP_API_URL}/shift-timing?id=${id}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
export const getAllSupportUser = () =>
  axios.get(`${process.env.REACT_APP_API_URL}/get-all-supportUser`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });

export const ExcelDownload = async ({ id, fromDate, toDate, search }) => {
  let idQuery = ``;
  if (id) idQuery = `&id=${id}`;

  const res = await axios
    .get(
      `${process.env.REACT_APP_API_URL}/feedback/feedback-excel/?from=${fromDate}&to=${toDate}&search=${JSON.stringify(
        search
      )}${idQuery}`,
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
        },
      },
      {
        sort: {
          supportId: 1,
        },
        search: {},
      }
    )
    .then((res) => {
      FileDownload(res.data, `feedback_log_${new Date()}.csv`);
    })
    .catch((err) => {
      ErrorToast(err.response.data.message || 'Something went wrong !!');
    });

  // console.log(res.data);
};

export const PDFDownload = async ({ id = null, fromDate, toDate, search }) => {
  console.log('filter', id);
  let idQuery = ``;
  if (id) idQuery = `&id=${id}`;

  await axios
    .get(
      `${process.env.REACT_APP_API_URL}/feedback/feedback-pdf/?from=${fromDate}&to=${toDate}&search=${JSON.stringify(
        search
      )}${idQuery}`,
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
        },
      }
    )
    .then((res) => {
      const link = document.createElement('a');
      link.href = res.data.data;
      link.download = `feedback_log.pdf`;
      link.click();
    })
    .catch((err) => {
      ErrorToast(err.response.data.message || 'Something went wrong !!');
    });

  // window.open(res.data);
  // FileDownload(res.data, `feedback_log_${new Date()}.pdf`);
  //
};

export const TxtDownload = async ({ id, fromDate, toDate, search }) => {
  console.log('filter', id);
  let idQuery = ``;
  if (id) idQuery = `&id=${id}`;
  const res = await axios
    .get(
      `${process.env.REACT_APP_API_URL}/feedback/feedback-text/?from=${fromDate}&to=${toDate}&search=${JSON.stringify(
        search
      )}${idQuery}`,
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
        },
      },
      {
        sort: {
          supportId: 1,
        },
        search: {},
      }
    )
    .then((res) => {
      FileDownload(res.data, `feedback_log_${new Date()}.txt`);
    })
    .catch((err) => {
      ErrorToast(err.response.data.message || 'Something went wrong !!');
    });
  // console.log(res.data, 'res_data');
};

export const TabDownload = async ({ id, fromDate, toDate, search }) => {
  console.log('filter', id);
  let idQuery = ``;
  if (id) idQuery = `&id=${id}`;
  const res = await axios
    .get(
      `${
        process.env.REACT_APP_API_URL
      }/feedback/feedback-Tabtext/?from=${fromDate}&to=${toDate}&search=${JSON.stringify(search)}${idQuery}`,
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
        },
      },
      {
        sort: {
          supportId: 1,
        },
        search: {},
      }
    )
    .then((res) => {
      FileDownload(res.data, `feedback_log_${new Date()}.txt`);
    })
    .catch((err) => {
      ErrorToast(err.response.data.message || 'Something went wrong !!');
    });
};
export const updateFireToken = (values) => axios.post(`${process.env.REACT_APP_API_URL}/firebase/updateToken`, values);

export const getNotificationBanner = (values) =>
  axios.post(`${process.env.REACT_APP_API_URL}/notification-config/banner`, values, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });

export const addCustomCompany = (values) =>
  axios.post(`${process.env.REACT_APP_API_URL}/addcustomcompany`, values, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
      'Content-Type': 'multipart/form-data',
    },
  });
export const deleteCustomCompany = (values) =>
  axios.post(`${process.env.REACT_APP_API_URL}/deleteCustomCompany`, values, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });

export const getUserIdbyEmail = (values) =>
  axios.post(`${process.env.REACT_APP_API_URL}/user/getUserID`, values, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });

export const forgotUserID = (values) =>
  axios.post(`${process.env.REACT_APP_API_URL}/user/forgotUserId`, values, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });

export const trackSubscription = (values) =>
  axios.post(`${process.env.REACT_APP_API_URL}/filterSubscription`, values, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });

export const filterAssetChangelog = (values) =>
  axios.post(`${process.env.REACT_APP_API_URL}/filterAssetChangelog`, values, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });

export const CompaniesBulkUpload = (values) =>
  axios.post(`${process.env.REACT_APP_API_URL}/companiesbulkupload`, values, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
      'Content-Type': 'multipart/form-data',
    },
  });

export const DownloadLastBulkUpload = () =>
  axios.get(`${process.env.REACT_APP_API_URL}/getbulkupload`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });

export const GetMemoryDetails = () =>
  axios.get(`${process.env.REACT_APP_API_URL}/getdetails`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });

export const SaveMemoryDetails = (values) =>
  axios.post(`${process.env.REACT_APP_API_URL}/savememorydetails`, values, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });

export const SaveNoOfSubs = (values) =>
  axios.post(`${process.env.REACT_APP_API_URL}/setnubofsubs`, values, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });

export const SaveNoOfDownloads = (values) =>
  axios.post(`${process.env.REACT_APP_API_URL}/setdownloads`, values, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });

export const SaveNoOfTextEmails = (values) =>
  axios.post(`${process.env.REACT_APP_API_URL}/settextemails`, values, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });

export const GetMemoryUser = (values) =>
  axios.get(`${process.env.REACT_APP_API_URL}/mymemory`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });

export const GetDownloadsChart = (values) =>
  axios.get(`${process.env.REACT_APP_API_URL}/getdownloads`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
export const GetUploadsChart = (values) =>
  axios.get(`${process.env.REACT_APP_API_URL}/getuploads`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });

export const GetLastMonthSubsChart = (values) =>
  axios.get(`${process.env.REACT_APP_API_URL}/lastmonthsubs`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
export const GetSMSChart = (values) =>
  axios.get(`${process.env.REACT_APP_API_URL}/getsmscounter`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
export const GetEmailsChart = (values) =>
  axios.get(`${process.env.REACT_APP_API_URL}/getemailcounter`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });

export const downloadAttachmentFile = (values) =>
  axios.post(`${process.env.REACT_APP_API_URL}/downloadAttachmentFile`, values, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });

export const checkExistingSub = (values) =>
  axios.post(`${process.env.REACT_APP_API_URL}/checkexistingsub`, values, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });

export const SearchCompany = (values) =>
  axios.post(`${process.env.REACT_APP_API_URL}/searchcompany`, values, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });

export const SearchSubscription = (values) =>
  axios.post(`${process.env.REACT_APP_API_URL}/searchsub`, values, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });

export const SearchCustomCompany = (values) =>
  axios.post(`${process.env.REACT_APP_API_URL}/searchcustomcompany`, values, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });

export const updateCallDuration = (values) =>
  axios.post(`${process.env.REACT_APP_API_URL}/updatecallduration`, values, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });

export const getCallChartData = () =>
  axios.get(`${process.env.REACT_APP_API_URL}/getcallchartdata`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });

export const getChartdetails = () =>
  axios.get(`${process.env.REACT_APP_API_URL}/getchartdetails`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });

export const RejectCustomCompany = (values) =>
  axios.post(`${process.env.REACT_APP_API_URL}/rejectcustomcompany`, values, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });

export const firebasePushNotification = (values) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/firebase/pushNotification`, values, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const generateAgoraToken = (values) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/firebase/generateAgoraToken`, values, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const getAllFirebaseCallLogs = ({ uid, otherUid, type }) => {
  const otherUidXX = otherUid !== undefined ? '&otherUid=' + otherUid : '';

  return axios.get(`${process.env.REACT_APP_API_URL}/firebase/call-log?uid=${uid}&type=${type}${otherUidXX}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const cloudRecordingStart = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/firebase/cloud-recording-start`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const cloudRecordingStop = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/firebase/cloud-recording-stop`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const getModuleConfigurationAppVersions = () => {
  return axios.get(`${process.env.REACT_APP_API_URL}/app-versions`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const createModuleConfigurationMenu = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/app/menu/create`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const getModuleConfiguration = (version, device) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/app/menus/${device}/${version}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const getModuleConfigurationBusiness = () => {
  return axios.get(`${process.env.REACT_APP_API_URL}/app/menus`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const updateModuleConfigurationMenu = (id, data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/app/menu/edit/${id}`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const deleteModuleConfigurationMenu = (id) => {
  return axios.delete(`${process.env.REACT_APP_API_URL}/app/menu/delete/${id}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const getBusinessReportLog = (page, limit, data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/filter-business-reports?page=${page}&limit=${limit}`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const getAssetModuleCompany = (businessOnly, isVerified, page, limit, skip) => {
  const _businessOnly = businessOnly === undefined ? '' : businessOnly;
  const _isVerified = isVerified === undefined ? '' : isVerified;

  return axios.get(
    `${process.env.REACT_APP_API_URL}/assetModule/company?businessOnly=${_businessOnly}&isVerified=${_isVerified}&page=${page}&limit=${limit}`,
    {
      headers: {
        authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
      },
    }
  );
};

export const getAssetModuleCards = (page, limit, type, companyId, isVerified) => {
  const v_page = page ? page : '';
  const v_limit = limit ? limit : '';
  const v_type = type ? type : '';
  const v_companyId = companyId ? companyId : '';
  const _isVerified = isVerified === undefined ? '' : isVerified;

  return axios.get(
    `${process.env.REACT_APP_API_URL}/assetModule/asset?page=${v_page}&limit=${v_limit}&type=${v_type}&companyId=${v_companyId}&isVerified=${_isVerified}`,
    {
      headers: {
        authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
      },
    }
  );
};

export const deleteAssetModuleCompany = (id) => {
  return axios.delete(`${process.env.REACT_APP_API_URL}/assetModule/company?id=${id}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const deleteAssetModuleCard = (id) => {
  return axios.delete(`${process.env.REACT_APP_API_URL}/assetModule/asset?id=${id}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const addAssetModuleCard = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/assetModule/asset`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const updateAssetModuleCard = (data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/assetModule/asset`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const addAssetModulecompany = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/assetModule/company`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const updateAssetModulecompany = (data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/assetModule/company`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const downloadBusinessReportData = (page = 1, limit = 5, values) =>
  axios.post(`${process.env.REACT_APP_API_URL}/download-business-log-reports?page=${page}&limit=${limit}`, values, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });

export const uploadAssetModuleImages = (file) => {
  const bodyFormData = new FormData();
  bodyFormData.append('file', file);
  return axios.post(`${process.env.REACT_APP_API_URL}/assetModule/image`, bodyFormData, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const getAssetModuleAccounts = (page, limit, cardType, filter) => {
  const v_page = page ? page : '';
  const v_limit = limit ? limit : '';
  const v_cardType = cardType ? cardType : '';
  // const v_companyId = filter.companyId ? filter.companyId : '';
  const v_companyId = filter?.companyId ? filter?.companyId : filter?.masterCompany ? filter?.masterCompany._id : '';
  const v_pointsMin = filter?.pointsMin ? filter?.pointsMin : '';
  const v_pointsMax = filter?.pointsMax ? filter?.pointsMax : '';
  const v_expiryTo = filter?.expiryTo ? filter?.expiryTo : '';
  const v_expiryFrom = filter?.expiryFrom ? filter?.expiryFrom : '';
  const v_companyType = filter?.companyType ? filter?.companyType : '';
  const v_status = filter?.status !== undefined ? filter?.status : '';
  return axios.get(
    `${process.env.REACT_APP_API_URL}/assetModule/account?page=${v_page}&limit=${v_limit}&cardType=${v_cardType}&companyId=${v_companyId}&pointsMin=${v_pointsMin}&pointsMax=${v_pointsMax}&expiryTo=${v_expiryTo}&expiryFrom=${v_expiryFrom}&companyType=${v_companyType}&status=${v_status}`,
    {
      headers: {
        authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
      },
    }
  );
};

export const addAssetModuleAccount = (data) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/assetModule/account`,
    { ...data, device: 'Web' },
    {
      headers: {
        authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
      },
    }
  );
};

export const updateAssetModuleAccount = (data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/assetModule/account`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const deleteAssetModuleAccount = (id) => {
  return axios.delete(`${process.env.REACT_APP_API_URL}/assetModule/account?id=${id}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const downloadAssetModuleAccount = (cardType, fileType) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/assetModule/account-download?cardType=${cardType}&fileType=${fileType}`,
    {
      headers: {
        authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
      },
    }
  );
};

export const createBusinessLog = (values) =>
  axios.post(`${process.env.REACT_APP_API_URL}/create-business-log-report`, values, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });

export const getBusinessReportDownload = (page = 1, limit = 5, values) =>
  axios.post(`${process.env.REACT_APP_API_URL}/download-business-log-reports?page=${page}&limit=${limit}`, values, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });

export const getAssetModuleAccountSingle = (id) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/assetModule/account-single?id=${id}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

// Colors and Bg for user and business

export const getColorsAndBgList = () => {
  return axios.get(`${process.env.REACT_APP_API_URL}/colors`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const getColorsAndBgSelected = (filter) => {
  const queryParam = queryString.stringify(filter, { skipNull: true });
  return axios.get(`${process.env.REACT_APP_API_URL}/colors/select?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const updateColorsAndBgSelected = (data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/colors/select`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const generateVirtualCardImage = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/assetModule/account/vcard`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const createColorsAndBg = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/colors`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const updateColorsAndBg = (data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/colors`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const deleteColorsAndBg = (id) => {
  return axios.delete(`${process.env.REACT_APP_API_URL}/colors?id=${id}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const uploadBackgroundImage = (file) => {
  const bodyFormData = new FormData();
  bodyFormData.append('file', file);
  return axios.post(`${process.env.REACT_APP_API_URL}/colors/upload`, bodyFormData, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const getAssetDashboard = () => {
  return axios.get(`${process.env.REACT_APP_API_URL}/assetModule/dashboard`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const uploadPdfStatement = (file, type) => {
  const bodyFormData = new FormData();
  bodyFormData.append('file', file);
  bodyFormData.append('type', type);
  return axios.post(`${process.env.REACT_APP_API_URL}/pdf-statement/upload`, bodyFormData, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

// RSS Link List

export const getRSSLinkList = () => {
  return axios.get(`${process.env.REACT_APP_API_URL}/rss`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const createRSSLink = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/rss`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const updateRSSLink = (data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/rss`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const deleteRSSLink = (id) => {
  return axios.delete(`${process.env.REACT_APP_API_URL}/rss?id=${id}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

// offers

export const getOffersListUser = (filter) => {
  const queryParam = queryString.stringify(filter, { skipNull: true });
  return axios.get(`${process.env.REACT_APP_API_URL}/offers/list?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const getOffersListDownload = (filter) => {
  const queryParam = queryString.stringify({ ...filter, timeZone: getTimeZoneAbbreviation() }, { skipNull: true });
  return axios.get(`${process.env.REACT_APP_API_URL}/offers/list/download?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const getOffersCommentsListUser = (filter) => {
  const queryParam = queryString.stringify(filter, { skipNull: true });
  return axios.get(`${process.env.REACT_APP_API_URL}/offers/comments?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const getOffersCommentsReplyListUser = (filter) => {
  const queryParam = queryString.stringify(filter, { skipNull: true });
  return axios.get(`${process.env.REACT_APP_API_URL}/offers/comments-reply?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const getOffersList = () => {
  return axios.get(`${process.env.REACT_APP_API_URL}/offers`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const createOffer = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/offers`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const createOfferComments = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/offers/comments`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const createOfferCommentsReply = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/offers/comments-reply`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const updateOffer = (data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/offers`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const updateOfferComments = (data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/offers/comments`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const deleteOffer = (id) => {
  return axios.delete(`${process.env.REACT_APP_API_URL}/offers?id=${id}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

// Banner List

export const getBannerList = (pageType) => {
  const queryParam = queryString.stringify({ pageType, device: 'web' }, { skipNull: true });
  return axios.get(`${process.env.REACT_APP_API_URL}/banner?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const createBanner = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/banner`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const updateBanner = (data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/banner`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const deleteBanner = (id) => {
  return axios.delete(`${process.env.REACT_APP_API_URL}/banner?id=${id}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const uploadBanner = (file) => {
  const bodyFormData = new FormData();
  bodyFormData.append('file', file);
  return axios.post(`${process.env.REACT_APP_API_URL}/banner/upload`, bodyFormData, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

// Safal AI
export const safalAiSend = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/safal-ai/send`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const safalAiList = (filter) => {
  const queryParam = queryString.stringify(filter, { skipNull: true });
  return axios.get(`${process.env.REACT_APP_API_URL}/safal-ai?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const safalAiSendGpt = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/safal-ai/gpt`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const safalAiListGpt = (data) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/safal-ai/gpt`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const getCompanyType = () => {
  return axios.get(`${process.env.REACT_APP_API_URL}/company-type`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const addCompanyType = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/company-type`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const deleteCompanyType = (id) => {
  return axios.delete(`${process.env.REACT_APP_API_URL}/company-type?id=${id}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const updateCompanyType = (data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/company-type`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const addChangeLog = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/change-log`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const addInitialFeedback = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/initial-feedback`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const getInitialFeedback = () => {
  return axios.get(`${process.env.REACT_APP_API_URL}/initial-feedback`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const getSubscriptionsTrend = (filter) => {
  const queryParam = queryString.stringify(filter, { skipNull: true });

  return axios.get(`${process.env.REACT_APP_API_URL}/subscriptions/trend?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const getSubscriptionsSpendData = (filter) => {
  const queryParam = queryString.stringify(filter, { skipNull: true });

  return axios.get(`${process.env.REACT_APP_API_URL}/subscriptions/spend?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const getSubscriptionsSpendBudget = (filter) => {
  const queryParam = queryString.stringify(filter, { skipNull: true });

  return axios.get(`${process.env.REACT_APP_API_URL}/subscriptions/spend-budget?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const downloadSubscriptionsTrend = (filter) => {
  const queryParam = queryString.stringify({ ...filter, timeZone: getTimeZoneAbbreviation() }, { skipNull: true });

  return axios.get(`${process.env.REACT_APP_API_URL}/subscriptions/trend/download?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

// plan
export const getPlanList = () => {
  return axios.get(`${process.env.REACT_APP_API_URL}/payment/plan`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const createPlan = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/payment/plan`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const updatePlan = (data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/payment/plan`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

// export const deletePlan = (id) => {
//   return axios.delete(`${process.env.REACT_APP_API_URL}/offers?id=${id}`, {
//     headers: {
//       authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
//     },
//   });
// };

// plan
export const getPriceListUser = () => {
  return axios.get(`${process.env.REACT_APP_API_URL}/payment/list`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const getPriceList = () => {
  return axios.get(`${process.env.REACT_APP_API_URL}/payment/price`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const createPrice = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/payment/price`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const updatePrice = (data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/payment/price`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const checkoutPlan = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/payment/checkout`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const getCheckoutPlanInfo = (sessionId) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/payment/checkout?sessionId=${sessionId}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const getUserPaymentHistory = (newFilterState) => {
  const queryParam = queryString.stringify(newFilterState, { skipNull: true });
  return axios.get(`${process.env.REACT_APP_API_URL}/payment/user?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const downloadUserPaymentHistory = (newFilterState) => {
  const queryParam = queryString.stringify(
    { ...newFilterState, timeZone: getTimeZoneAbbreviation() },
    { skipNull: true }
  );
  return axios.get(`${process.env.REACT_APP_API_URL}/payment/user/download?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const getPlanCancel = () => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/payment/cancel`,
    {},
    {
      headers: {
        authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
      },
    }
  );
};

// Asset Limils
export const getAssetLimitsBusiness = () => {
  return axios.get(`${process.env.REACT_APP_API_URL}/limits/assets`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const updateAssetLimitsBusiness = (data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/limits/assets`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

// SafalAi Limils
export const getSafalAiLimitsBusiness = () => {
  return axios.get(`${process.env.REACT_APP_API_URL}/limits/safalai`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const updateSafalAiLimitsBusiness = (data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/limits/safalai`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

// Sub label Limils
export const getSubLabelLimitsBusiness = () => {
  return axios.get(`${process.env.REACT_APP_API_URL}/limits/label`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const updateSubLabelLimitsBusiness = (data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/limits/label`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const increaseDownload = () => {
  return axios.put(
    `${process.env.REACT_APP_API_URL}/limits/download-inc`,
    {},
    {
      headers: {
        authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
      },
    }
  );
};

// export const deletePrice = (id) => {
//   return axios.delete(`${process.env.REACT_APP_API_URL}/offers?id=${id}`, {
//     headers: {
//       authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
//     },
//   });
// };

export const getSubLabel = () => {
  return axios.get(`${process.env.REACT_APP_API_URL}/sub-label`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const getSubLabelReport = (filter) => {
  const queryParam = queryString.stringify(filter, { skipNull: true });
  return axios.get(`${process.env.REACT_APP_API_URL}/sub-label/report?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const getPaymentModeReport = (filter) => {
  const queryParam = queryString.stringify(filter, { skipNull: true });
  return axios.get(`${process.env.REACT_APP_API_URL}/payment-mode/report?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const addSubLabel = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/sub-label`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const updateSubLabel = (data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/sub-label`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const deleteSubLabel = (id, name) => {
  return axios.delete(`${process.env.REACT_APP_API_URL}/sub-label?id=${id}&name=${name}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const downloadSubLabelReport = (filter) => {
  const queryParam = queryString.stringify({ ...filter, timeZone: getTimeZoneAbbreviation() }, { skipNull: true });

  return axios.get(`${process.env.REACT_APP_API_URL}/sub-label/report-download?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const downloadPaymentModeReport = (filter) => {
  const queryParam = queryString.stringify({ ...filter, timeZone: getTimeZoneAbbreviation() }, { skipNull: true });

  return axios.get(`${process.env.REACT_APP_API_URL}/payment-mode/report-download?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const sendOTP = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/otp`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const verifyOTP = (data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/otp`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

// FAQ api

export const getFaqList = (filter) => {
  const queryParam = queryString.stringify(filter, { skipNull: true });

  return axios.get(`${process.env.REACT_APP_API_URL}/faq?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const createFaq = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/faq`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const updateFaq = (data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/faq`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const deleteFaq = (id) => {
  return axios.delete(`${process.env.REACT_APP_API_URL}/faq?id=${id}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

// Privacy Policy And Term Of Condition api

export const getPrivacyPolicyAndTermOfCondition = () => {
  return axios.get(`${process.env.REACT_APP_API_URL}/pp-toc`);
};

export const updatePrivacyPolicyAndTermOfCondition = (data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/pp-toc`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

// Payment Mode

export const getPaymentMode = (filter) => {
  const queryParam = queryString.stringify(filter, { skipNull: true });

  return axios.get(`${process.env.REACT_APP_API_URL}/payment-mode?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const createPaymentModeBusiness = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/payment-mode`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const updatePaymentModeBusiness = (data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/payment-mode`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const deletePaymentModeBusiness = (payload) => {
  const queryParam = queryString.stringify(payload, { skipNull: true });
  return axios.delete(`${process.env.REACT_APP_API_URL}/payment-mode?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

// Payment Mode

export const getPaymentInstrument = (filter) => {
  const queryParam = queryString.stringify(filter, { skipNull: true });

  return axios.get(`${process.env.REACT_APP_API_URL}/payment-mode/payment-instrument?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const createPaymentInstrument = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/payment-mode/payment-instrument`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const updatePaymentInstrument = (data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/payment-mode/payment-instrument`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const deletePaymentInstrument = (payload) => {
  const queryParam = queryString.stringify(payload, { skipNull: true });
  return axios.delete(`${process.env.REACT_APP_API_URL}/payment-mode/payment-instrument?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

// Payment Mode

export const getCardScheme = (filter) => {
  const queryParam = queryString.stringify(filter, { skipNull: true });

  return axios.get(`${process.env.REACT_APP_API_URL}/payment-mode/card-scheme?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const createCardSchemeBusiness = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/payment-mode/card-scheme`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const updateCardSchemeBusiness = (data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/payment-mode/card-scheme`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const deleteCardSchemeBusiness = (payload) => {
  const queryParam = queryString.stringify(payload, { skipNull: true });
  return axios.delete(`${process.env.REACT_APP_API_URL}/payment-mode/card-scheme?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

// Plan Feature

export const getPlanFeature = (filter) => {
  const queryParam = queryString.stringify(filter, { skipNull: true });

  return axios.get(`${process.env.REACT_APP_API_URL}/payment/feature?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const createPlanFeatureBusiness = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/payment/feature`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const updatePlanFeatureBusiness = (data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/payment/feature`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

// PDF Scan type

export const getPdfScanType = (filter) => {
  const queryParam = queryString.stringify(filter, { skipNull: true });

  return axios.get(`${process.env.REACT_APP_API_URL}/pdf-statement/type?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const createPdfScanTypeBusiness = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/pdf-statement/type`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const updatePdfScanTypeBusiness = (data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/pdf-statement/type`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const deletePdfScanTypeBusiness = (payload) => {
  const queryParam = queryString.stringify(payload, { skipNull: true });
  return axios.delete(`${process.env.REACT_APP_API_URL}/pdf-statement/type?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

// Help Video Inventory

export const getHelpVideoInventory = (filter) => {
  const queryParam = queryString.stringify(filter, { skipNull: true });

  return axios.get(`${process.env.REACT_APP_API_URL}/help-video?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const getHelpVideoInventoryDownload = (filter) => {
  const queryParam = queryString.stringify({ ...filter, timeZone: getTimeZoneAbbreviation() }, { skipNull: true });

  return axios.get(`${process.env.REACT_APP_API_URL}/help-video/download?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const getAllFaqDownload = (filter) => {
  const queryParam = queryString.stringify({ ...filter, timeZone: getTimeZoneAbbreviation() }, { skipNull: true });

  return axios.get(`${process.env.REACT_APP_API_URL}/faq/download?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const createHelpVideoInventoryBusiness = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/help-video`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const updateHelpVideoInventoryBusiness = (data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/help-video`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const deleteHelpVideoInventoryBusiness = (payload) => {
  const queryParam = queryString.stringify(payload, { skipNull: true });
  return axios.delete(`${process.env.REACT_APP_API_URL}/help-video?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

// Help Video Module

export const getHelpVideoModule = (filter) => {
  const queryParam = queryString.stringify(filter, { skipNull: true });

  return axios.get(`${process.env.REACT_APP_API_URL}/help-video/module?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const createHelpVideoModuleBusiness = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/help-video/module`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const updateHelpVideoModuleBusiness = (data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/help-video/module`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const deleteHelpVideoModuleBusiness = (payload) => {
  const queryParam = queryString.stringify(payload, { skipNull: true });
  return axios.delete(`${process.env.REACT_APP_API_URL}/help-video/module?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

// General KV

export const getGeneralKeyValueList = (filter) => {
  const queryParam = queryString.stringify(filter, { skipNull: true });

  return axios.get(`${process.env.REACT_APP_API_URL}/general-kv?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const createGeneralKeyValueListBusiness = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/general-kv`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const updateGeneralKeyValueListBusiness = (data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/general-kv`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const deleteGeneralKeyValueListBusiness = (payload) => {
  const queryParam = queryString.stringify(payload, { skipNull: true });
  return axios.delete(`${process.env.REACT_APP_API_URL}/general-kv?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const uploadHelpVideoIcon = (file) => {
  const bodyFormData = new FormData();
  bodyFormData.append('file', file);
  return axios.post(`${process.env.REACT_APP_API_URL}/help-video/module/upload`, bodyFormData, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const uploadLeaderBoardMasterCountries = (file) => {
  const bodyFormData = new FormData();
  bodyFormData.append('file', file);
  return axios.post(`${process.env.REACT_APP_API_URL}/leader-board/countries/upload`, bodyFormData, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

// Offers Social Media

export const getOfferSocialMedia = (filter) => {
  const queryParam = queryString.stringify(filter, { skipNull: true });

  return axios.get(`${process.env.REACT_APP_API_URL}/offers/social?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const addOfferSocialMedia = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/offers/social`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const editOfferSocialMedia = (data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/offers/social`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const deleteOfferSocialMedia = (payload) => {
  const queryParam = queryString.stringify(payload, { skipNull: true });
  return axios.delete(`${process.env.REACT_APP_API_URL}/offers/social?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const uploadOfferSocialMedia = (file) => {
  const bodyFormData = new FormData();
  bodyFormData.append('file', file);
  return axios.post(`${process.env.REACT_APP_API_URL}/offers/social/upload`, bodyFormData, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

// Safal FAQ AI
export const faqAiSend = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/faq-ai`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const faqAiList = (filter) => {
  const queryParam = queryString.stringify(filter, { skipNull: true });
  return axios.get(`${process.env.REACT_APP_API_URL}/faq-ai?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

// SafalLeaderConfigMaster API

export const getSafalLeaderConfigMaster = (filter) => {
  const queryParam = queryString.stringify(filter, { skipNull: true });

  return axios.get(`${process.env.REACT_APP_API_URL}/leader-board/config?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const createSafalLeaderConfigMaster = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/leader-board/config`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const updateSafalLeaderConfigMaster = (data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/leader-board/config`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const deleteSafalLeaderConfigMaster = (id) => {
  return axios.delete(`${process.env.REACT_APP_API_URL}/leader-board/config?id=${id}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

// ApplicationList API

export const getApplicationList = (filter) => {
  const queryParam = queryString.stringify(filter, { skipNull: true });

  return axios.get(`${process.env.REACT_APP_API_URL}/application-list?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const createApplicationList = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/application-list`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const updateApplicationList = (data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/application-list`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const deleteApplicationList = (payload) => {
  const queryParam = queryString.stringify(payload, { skipNull: true });
  return axios.delete(`${process.env.REACT_APP_API_URL}/application-list?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

// SafalLeaderFrequencyConfig

export const getSafalLeaderFrequencyConfig = (filter) => {
  const queryParam = queryString.stringify(filter, { skipNull: true });

  return axios.get(`${process.env.REACT_APP_API_URL}/leader-board/frequency?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const createSafalLeaderFrequencyConfig = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/leader-board/frequency`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const updateSafalLeaderFrequencyConfig = (data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/leader-board/frequency`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const deleteSafalLeaderFrequencyConfig = (payload) => {
  const queryParam = queryString.stringify(payload, { skipNull: true });
  return axios.delete(`${process.env.REACT_APP_API_URL}/leader-board/frequency?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const getSafalLeaderBusiness = (filter) => {
  const queryParam = queryString.stringify(filter, { skipNull: true });

  return axios.get(`${process.env.REACT_APP_API_URL}/leader-board/business?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

// LeaderBoardMasterCountries

export const getLeaderBoardMasterCountries = (filter) => {
  const queryParam = queryString.stringify(filter, { skipNull: true });

  return axios.get(`${process.env.REACT_APP_API_URL}/leader-board/countries?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const createLeaderBoardMasterCountries = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/leader-board/countries`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const updateLeaderBoardMasterCountries = (data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/leader-board/countries`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const deleteLeaderBoardMasterCountries = (payload) => {
  const queryParam = queryString.stringify(payload, { skipNull: true });
  return axios.delete(`${process.env.REACT_APP_API_URL}/leader-board/countries?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const getSafalLeaderBoard = (filter) => {
  const queryParam = queryString.stringify(filter, { skipNull: true });

  return axios.get(`${process.env.REACT_APP_API_URL}/leader-board?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const getSafalLeaderBoardReport = (filter) => {
  const queryParam = queryString.stringify(filter, { skipNull: true });

  return axios.get(`${process.env.REACT_APP_API_URL}/leader-board/report?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const getSafalLeaderBoardReportDownload = (filter) => {
  const queryParam = queryString.stringify({ ...filter, timeZone: getTimeZoneAbbreviation() }, { skipNull: true });

  return axios.get(`${process.env.REACT_APP_API_URL}/leader-board/report-download?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const getSafalBuddy = (filter) => {
  const queryParam = queryString.stringify(filter, { skipNull: true });

  return axios.get(`${process.env.REACT_APP_API_URL}/safal-buddy?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const addSafalBuddy = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/safal-buddy`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const updateSafalBuddy = (data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/safal-buddy`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

// SafalBuddyActivitiesMasterConfig

export const getSafalBuddyActivitiesMasterConfig = (filter) => {
  const queryParam = queryString.stringify(filter, { skipNull: true });

  return axios.get(`${process.env.REACT_APP_API_URL}/safal-buddy/master-config?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const createSafalBuddyActivitiesMasterConfig = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/safal-buddy/master-config`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const updateSafalBuddyActivitiesMasterConfig = (data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/safal-buddy/master-config`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const deleteSafalBuddyActivitiesMasterConfig = (payload) => {
  const queryParam = queryString.stringify(payload, { skipNull: true });
  return axios.delete(`${process.env.REACT_APP_API_URL}/safal-buddy/master-config?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

// SafalLeaderBoardActivitiesMasterConfig

export const getSafalLeaderBoardActivitiesMasterConfig = (filter) => {
  const queryParam = queryString.stringify(filter, { skipNull: true });

  return axios.get(`${process.env.REACT_APP_API_URL}/leader-board/activities-config?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const createSafalLeaderBoardActivitiesMasterConfig = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/leader-board/activities-config`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const updateSafalLeaderBoardActivitiesMasterConfig = (data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/leader-board/activities-config`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const deleteSafalLeaderBoardActivitiesMasterConfig = (payload) => {
  const queryParam = queryString.stringify(payload, { skipNull: true });
  return axios.delete(`${process.env.REACT_APP_API_URL}/leader-board/activities-config?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

// SafalBuddyActivitiesConfig

export const getSafalBuddyActivitiesConfig = (filter) => {
  const queryParam = queryString.stringify(filter, { skipNull: true });

  return axios.get(`${process.env.REACT_APP_API_URL}/safal-buddy/config?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const updateSafalBuddyActivitiesConfig = (data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/safal-buddy/config`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

// SafalLeaderBoardActivitiesConfig

export const getSafalLeaderBoardActivitiesConfig = (filter) => {
  const queryParam = queryString.stringify(filter, { skipNull: true });

  return axios.get(`${process.env.REACT_APP_API_URL}/leader-board/activities-config-user?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const updateSafalLeaderBoardActivitiesConfig = (data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/leader-board/activities-config-user`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const updateSafalBuddyAndLeaderBoardMasterSwitch = (data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/leader-board/activities-switch`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const updateSafalBuddyAndLeaderBoardSwitch = (data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/leader-board/activities-switch-user`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const addSafalLeaderBoardLog = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/leader-board/add`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const getSafalBuddyReport = (filter) => {
  const queryParam = queryString.stringify(filter, { skipNull: true });

  return axios.get(`${process.env.REACT_APP_API_URL}/safal-buddy/report?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const getSafalBuddyReportDownload = (filter) => {
  const queryParam = queryString.stringify({ ...filter, timeZone: getTimeZoneAbbreviation() }, { skipNull: true });

  return axios.get(`${process.env.REACT_APP_API_URL}/safal-buddy/report-download?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const getSafalBuddyByOtherUserId = (filter) => {
  const queryParam = queryString.stringify(filter, { skipNull: true });

  return axios.get(`${process.env.REACT_APP_API_URL}/safal-buddy/status?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const getSafalLeaderConfigMasterDownload = (filter) => {
  const queryParam = queryString.stringify({ ...filter, timeZone: getTimeZoneAbbreviation() }, { skipNull: true });

  return axios.get(`${process.env.REACT_APP_API_URL}/leader-board/config-download?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

// SafalGift Master Inventory (Business)
// SafalGiftMasterInventory

export const getSafalGiftMasterInventory = (filter) => {
  const queryParam = queryString.stringify(filter, { skipNull: true });

  return axios.get(`${process.env.REACT_APP_API_URL}/safal-gift/inventory?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const createSafalGiftMasterInventory = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/safal-gift/inventory`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const updateSafalGiftMasterInventory = (data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/safal-gift/inventory`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const deleteSafalGiftMasterInventory = (payload) => {
  const queryParam = queryString.stringify(payload, { skipNull: true });
  return axios.delete(`${process.env.REACT_APP_API_URL}/safal-gift/inventory?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

// SafalGift Orders
export const placeSafalGiftUserOrder = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/safal-gift/order`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const updateSafalGiftUserOrderBusiness = (data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/safal-gift/order`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const getSafalGiftOrdersBusiness = (payload) => {
  const queryParam = queryString.stringify(payload, { skipNull: true });
  return axios.get(`${process.env.REACT_APP_API_URL}/safal-gift/order?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const getUserSafalGiftOrdersHistory = (payload) => {
  const queryParam = queryString.stringify(payload, { skipNull: true });
  return axios.get(`${process.env.REACT_APP_API_URL}/safal-gift/order-history?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const getSafalGiftInventoryUser = (payload) => {
  const queryParam = queryString.stringify(payload, { skipNull: true });
  return axios.get(`${process.env.REACT_APP_API_URL}/safal-gift/inventory-user?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const getSafalGiftUserCart = (payload) => {
  const queryParam = queryString.stringify(payload, { skipNull: true });
  return axios.get(`${process.env.REACT_APP_API_URL}/safal-gift/cart?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const updateSafalGiftUserCart = (data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/safal-gift/cart`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const getSafalLeaderUserPoints = (payload) => {
  const queryParam = queryString.stringify(payload, { skipNull: true });
  return axios.get(`${process.env.REACT_APP_API_URL}/leader-board/user-points?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

// Address
export const addSafalGiftAddress = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/safal-gift/address`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const updateSafalGiftAddress = (data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/safal-gift/address`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const getSafalGiftAddress = (payload) => {
  const queryParam = queryString.stringify(payload, { skipNull: true });
  return axios.get(`${process.env.REACT_APP_API_URL}/safal-gift/address?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const deleteSafalGiftAddress = (payload) => {
  const queryParam = queryString.stringify(payload, { skipNull: true });
  return axios.delete(`${process.env.REACT_APP_API_URL}/safal-gift/address?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const getSafalRoomPublic = (payload) => {
  const queryParam = queryString.stringify(payload, { skipNull: true });
  return axios.get(`${process.env.REACT_APP_API_URL}/safal-room?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const getSafalRoomPublicNormalChat = (payload) => {
  const queryParam = queryString.stringify(payload, { skipNull: true });
  return axios.get(`${process.env.REACT_APP_API_URL}/safal-room/chat?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const updateSafalRoomPublicNormalChat = (data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/safal-room/chat`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const createSafalRoomPublicNormalChat = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/safal-room/chat`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const updateSafalRoomPublicNormalChatReply = (data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/safal-room/chat-reply`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const createSafalRoomPublicNormalChatReply = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/safal-room/chat-reply`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const getSafalRoomPullUser = (data) => {
  const queryParam = queryString.stringify(data, { skipNull: true });
  return axios.get(`${process.env.REACT_APP_API_URL}/safal-room/pull-user?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const updateSafalRoomPullUser = (data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/safal-room/pull-option`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

// Community Forum
export const getSafalRoomForumUser = (data) => {
  const queryParam = queryString.stringify(data, { skipNull: true });
  return axios.get(`${process.env.REACT_APP_API_URL}/safal-room/forum?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const createSafalRoomForumUser = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/safal-room/forum`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const updateSafalRoomForumUser = (data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/safal-room/forum`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const deleteSafalRoomForumUser = (data) => {
  const queryParam = queryString.stringify(data, { skipNull: true });
  return axios.delete(`${process.env.REACT_APP_API_URL}/safal-room/forum?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

// Community Forum - Feed or chat
export const createSafalRoomForumUserFeed = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/safal-room/forum/chat`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const getSafalRoomForumUserFeed = (data) => {
  const queryParam = queryString.stringify(data, { skipNull: true });
  return axios.get(`${process.env.REACT_APP_API_URL}/safal-room/forum/chat?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const updateSafalRoomForumUserFeed = (data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/safal-room/forum/chat`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const createSafalRoomForumUserFeedReply = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/safal-room/forum/chat-reply`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const updateSafalRoomForumUserFeedReply = (data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/safal-room/forum/chat-reply`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const updateSafalRoomDefaultCompany = (data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/safal-room/default-company`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

// Private SafalRoom

export const getPrivateSafalRoomList = (data) => {
  const queryParam = queryString.stringify(data, { skipNull: true });
  return axios.get(`${process.env.REACT_APP_API_URL}/safal-room-private/list?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const getPrivateSafalRoomReqList = (data) => {
  const queryParam = queryString.stringify(data, { skipNull: true });
  return axios.get(`${process.env.REACT_APP_API_URL}/safal-room-private/list-all?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const getSafalRoomPrivate = (payload) => {
  const queryParam = queryString.stringify(payload, { skipNull: true });
  return axios.get(`${process.env.REACT_APP_API_URL}/safal-room-private?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

// Private Room Feed
export const createSafalRoomPrivateFeed = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/safal-room-private/chat`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const getSafalRoomPrivateFeed = (payload) => {
  const queryParam = queryString.stringify(payload, { skipNull: true });
  return axios.get(`${process.env.REACT_APP_API_URL}/safal-room-private/chat?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const updateSafalRoomPrivateFeed = (data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/safal-room-private/chat`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const createSafalRoomPrivateFeedReply = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/safal-room-private/chat-reply`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const updateSafalRoomPrivateFeedReply = (data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/safal-room-private/chat-reply`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
// Private Room Polls
export const getSafalRoomPrivatePullUser = (data) => {
  const queryParam = queryString.stringify(data, { skipNull: true });
  return axios.get(`${process.env.REACT_APP_API_URL}/safal-room-private/pull-user?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const updateSafalRoomPrivatePullUser = (data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/safal-room-private/pull-option`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const updateSafalRoomPrivatePull = (data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/safal-room-private/pull`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const createSafalRoomPrivate = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/safal-room-private`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const updateSafalRoomPrivateOwner = (data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/safal-room-private`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const createSafalRoomPrivatePolls = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/safal-room-private/pull`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

// Community Forum
export const getSafalRoomPrivateForumUser = (data) => {
  const queryParam = queryString.stringify(data, { skipNull: true });
  return axios.get(`${process.env.REACT_APP_API_URL}/safal-room-private/forum?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const createSafalRoomPrivateForumUser = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/safal-room-private/forum`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const updateSafalRoomPrivateForumUser = (data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/safal-room-private/forum`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const deleteSafalRoomPrivateForumUser = (data) => {
  const queryParam = queryString.stringify(data, { skipNull: true });
  return axios.delete(`${process.env.REACT_APP_API_URL}/safal-room-private/forum?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

// Community Forum - Feed or chat
export const createSafalRoomPrivateForumUserFeed = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/safal-room-private/forum/chat`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const getSafalRoomPrivateForumUserFeed = (data) => {
  const queryParam = queryString.stringify(data, { skipNull: true });
  return axios.get(`${process.env.REACT_APP_API_URL}/safal-room-private/forum/chat?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const updateSafalRoomPrivateForumUserFeed = (data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/safal-room-private/forum/chat`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const createSafalRoomPrivateForumUserFeedReply = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/safal-room-private/forum/chat-reply`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const updateSafalRoomPrivateForumUserFeedReply = (data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/safal-room-private/forum/chat-reply`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const updateSafalRoomPrivateReq = (data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/safal-room-private/req`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const deleteSafalRoomPrivate = (data) => {
  const queryParam = queryString.stringify(data, { skipNull: true });
  return axios.delete(`${process.env.REACT_APP_API_URL}/safal-room-private?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const getPrivateSafalRoomAllReqList = (data) => {
  const queryParam = queryString.stringify(data, { skipNull: true });
  return axios.get(`${process.env.REACT_APP_API_URL}/safal-room-private/all-req?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const getCompanyByNameType = (data) => {
  const queryParam = queryString.stringify(data, { skipNull: true });
  return axios.get(`${process.env.REACT_APP_API_URL}/general-kv/find-company?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const getSafalRoomPrivateForBusiness = (data) => {
  const queryParam = queryString.stringify(data, { skipNull: true });
  return axios.get(`${process.env.REACT_APP_API_URL}/safal-room-private/business?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const deletePrivateRoomForBusiness = (data) => {
  const queryParam = queryString.stringify(data, { skipNull: true });
  return axios.delete(`${process.env.REACT_APP_API_URL}/safal-room-private/business?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const updateSafalRoomPrivateForBusiness = (data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/safal-room-private/business`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const getPrivateRoomInviteCodeInfo = (data) => {
  const queryParam = queryString.stringify(data, { skipNull: true });
  return axios.get(`${process.env.REACT_APP_API_URL}/safal-room-private/invite?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const createPrivateRoomInviteCode = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/safal-room-private/invite`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const rejectPrivateRoomInviteCode = (data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/safal-room-private/invite`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const uploadSafalRoomImages = (files) => {
  const bodyFormData = new FormData();
  files.forEach((file) => {
    bodyFormData.append('files', file);
  });

  return axios.post(`${process.env.REACT_APP_API_URL}/safal-room/upload-images`, bodyFormData, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const getGlobalShareLinkList = () => {
  return axios.get(`${process.env.REACT_APP_API_URL}/global-share`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const updateGlobalShareLink = (data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/global-share`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const getSafalRoomUserInfo = (data) => {
  const queryParam = queryString.stringify(data, { skipNull: true });
  return axios.get(`${process.env.REACT_APP_API_URL}/safal-room/user-info?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const getDownloadSafalGiftOrders = (filter) => {
  const queryParam = queryString.stringify({ ...filter, timeZone: getTimeZoneAbbreviation() }, { skipNull: true });

  return axios.get(`${process.env.REACT_APP_API_URL}/safal-gift/order-download?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const getNewBuddyList = (filter) => {
  const queryParam = queryString.stringify(filter, { skipNull: true });
  return axios.get(`${process.env.REACT_APP_API_URL}/safal-buddy/new?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const getBuddyInviteSearchList = (filter) => {
  const queryParam = queryString.stringify(filter, { skipNull: true });
  return axios.get(`${process.env.REACT_APP_API_URL}/safal-buddy/search-users?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

// User Assessment
export const getUserAssessmentBusiness = (filter) => {
  const queryParam = queryString.stringify(filter, { skipNull: true });
  return axios.get(`${process.env.REACT_APP_API_URL}/user-assessment?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const deleteUserAssessmentBusiness = (filter) => {
  const queryParam = queryString.stringify(filter, { skipNull: true });
  return axios.delete(`${process.env.REACT_APP_API_URL}/user-assessment?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const createUserAssessment = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/user-assessment`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const updateUserAssessment = (data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/user-assessment`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const getUserAssessmentUser = (filter) => {
  const queryParam = queryString.stringify(filter, { skipNull: true });
  return axios.get(`${process.env.REACT_APP_API_URL}/user-assessment/user?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const addUserAssessment = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/user-assessment/user`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const getUserAssessmentUserLog = (filter) => {
  const queryParam = queryString.stringify(filter, { skipNull: true });
  return axios.get(`${process.env.REACT_APP_API_URL}/user-assessment/user-log?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const getUserAssessmentUserLogDownload = (filter) => {
  const queryParam = queryString.stringify(filter, { skipNull: true });
  return axios.get(`${process.env.REACT_APP_API_URL}/user-assessment/user-log-download?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const getUserToggleSettingBusiness = (filter) => {
  const queryParam = queryString.stringify(filter, { skipNull: true });
  return axios.get(`${process.env.REACT_APP_API_URL}/user-toggle-setting/business?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const getUserToggleSetting = (filter) => {
  const queryParam = queryString.stringify(filter, { skipNull: true });
  return axios.get(`${process.env.REACT_APP_API_URL}/user-toggle-setting?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const updateUserToggleSettingBusiness = (data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/user-toggle-setting/business`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const updateUserToggleSetting = (data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/user-toggle-setting`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const addSingleUserAssessment = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/user-assessment/user-single`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const updateSingleUserAssessment = (data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/user-assessment/user-single`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const addUserAssessmentMonthly = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/user-assessment/user-monthly`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const getUserAssessmentReport = (filter) => {
  const queryParam = queryString.stringify(filter, { skipNull: true });
  return axios.get(`${process.env.REACT_APP_API_URL}/user-assessment/user-report?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const getUserAssessmentGroup = (filter) => {
  const queryParam = queryString.stringify(filter, { skipNull: true });
  return axios.get(`${process.env.REACT_APP_API_URL}/user-assessment/user-group?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const getBusinessDashboard = (filter) => {
  const queryParam = queryString.stringify(filter, { skipNull: true });
  return axios.get(`${process.env.REACT_APP_API_URL}/business-dashboard?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const getBusinessDashboardTotalUsers = (filter) => {
  const queryParam = queryString.stringify(filter, { skipNull: true });
  return axios.get(`${process.env.REACT_APP_API_URL}/business-dashboard/users?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const getBusinessDashboardTotalUsersCountry = (filter) => {
  const queryParam = queryString.stringify(filter, { skipNull: true });
  return axios.get(`${process.env.REACT_APP_API_URL}/business-dashboard/users-country?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const getSalesRevenuePerPlan = (filter) => {
  const queryParam = queryString.stringify(filter, { skipNull: true });
  return axios.get(`${process.env.REACT_APP_API_URL}/business-dashboard/sales-revenue?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const getSubscriptionRevenue = (filter) => {
  const queryParam = queryString.stringify(filter, { skipNull: true });
  return axios.get(`${process.env.REACT_APP_API_URL}/business-dashboard/subscription-revenue?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const getSalesNetRevenuePerPlan = (filter) => {
  const queryParam = queryString.stringify(filter, { skipNull: true });
  return axios.get(`${process.env.REACT_APP_API_URL}/business-dashboard/sales-net-revenue?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const getSubscriptionDifferentPaymentTier = (filter) => {
  const queryParam = queryString.stringify(filter, { skipNull: true });
  return axios.get(
    `${process.env.REACT_APP_API_URL}/business-dashboard/subscription-different-payment-tier?${queryParam}`,
    {
      headers: {
        authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
      },
    }
  );
};

export const getDeviceTypeAnalytics = (filter) => {
  const queryParam = queryString.stringify(filter, { skipNull: true });
  return axios.get(`${process.env.REACT_APP_API_URL}/business-dashboard/device-type-analytics?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const getUserActiveInactivePie = (filter) => {
  const queryParam = queryString.stringify(filter, { skipNull: true });
  return axios.get(`${process.env.REACT_APP_API_URL}/business-dashboard/user-active-inactive-pie?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const getChurnRate = (filter) => {
  const queryParam = queryString.stringify(filter, { skipNull: true });
  return axios.get(`${process.env.REACT_APP_API_URL}/business-dashboard/churn-rate?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const getCustomerRenewal = (filter) => {
  const queryParam = queryString.stringify(filter, { skipNull: true });
  return axios.get(`${process.env.REACT_APP_API_URL}/business-dashboard/customer-renewal?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const addAdClicksAndImpressionsAnalytics = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/analytics/ad`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const getAdClicksAndImpressionsAnalytics = (data) => {
  const queryParam = queryString.stringify(data, { skipNull: true });
  return axios.get(`${process.env.REACT_APP_API_URL}/business-dashboard/clicks-impressions?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const getARPUData = (data) => {
  const queryParam = queryString.stringify(data, { skipNull: true });
  return axios.get(`${process.env.REACT_APP_API_URL}/business-dashboard/arpu?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const getPlanedMRRActualMRR = (data) => {
  const queryParam = queryString.stringify(data, { skipNull: true });
  return axios.get(`${process.env.REACT_APP_API_URL}/business-dashboard/mrr-mrr?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

// Advertisementpage

export const addAdvertisementpage = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/advertisement`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const getAdvertisementPage = (data) => {
  const queryParam = queryString.stringify(data, { skipNull: true });
  return axios.get(`${process.env.REACT_APP_API_URL}/advertisement?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const updateAdvertisementPage = (data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/advertisement`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const uploadAdvertisementImages = (file) => {
  const bodyFormData = new FormData();
  bodyFormData.append('file', file);
  return axios.post(`${process.env.REACT_APP_API_URL}/advertisement/upload`, bodyFormData, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

// Advertisement price

export const addAdvertisementPrice = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/advertisement/price`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const getAdvertisementPrice = (data) => {
  const queryParam = queryString.stringify(data, { skipNull: true });
  return axios.get(`${process.env.REACT_APP_API_URL}/advertisement/price?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const updateAdvertisementPrice = (data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/advertisement/price`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const addEmailCrawlerProcess = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/email-crawler`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const getEmailCrawlerProcess = (data) => {
  const queryParam = queryString.stringify(data, { skipNull: true });
  return axios.get(`${process.env.REACT_APP_API_URL}/email-crawler?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const getAdvertisementListFromVendor = (data) => {
  const queryParam = queryString.stringify(data, { skipNull: true, skipEmptyString: true });
  return axios.get(`${process.env.REACT_APP_API_URL}/advertisement/list-from-vendor?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const updateAdvertisementListFromVendor = (data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/advertisement/list-from-vendor`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

// safal-warranty-product-category

export const getWarrantyProductCategory = (data) => {
  const queryParam = queryString.stringify(data, { skipNull: true, skipEmptyString: true });
  return axios.get(`${process.env.REACT_APP_API_URL}/safal-warranty/product-category?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const deleteWarrantyProductCategory = (data) => {
  const queryParam = queryString.stringify(data, { skipNull: true, skipEmptyString: true });
  return axios.delete(`${process.env.REACT_APP_API_URL}/safal-warranty/product-category?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const createWarrantyProductCategory = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/safal-warranty/product-category`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const updateWarrantyProductCategory = (data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/safal-warranty/product-category`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const globalImagesUpload = (files, folderName, uploadType) => {
  const bodyFormData = new FormData();
  bodyFormData.append('folderName', folderName);
  bodyFormData.append('uploadType', uploadType);
  files.forEach((file) => {
    bodyFormData.append('files', file);
  });

  return axios.post(`${process.env.REACT_APP_API_URL}/global-images-upload`, bodyFormData, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

// safal-warranty-product-sub-category

export const getWarrantyProductSubCategory = (data) => {
  const queryParam = queryString.stringify(data, { skipNull: true, skipEmptyString: true });
  return axios.get(`${process.env.REACT_APP_API_URL}/safal-warranty/product-sub-category?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const deleteWarrantyProductSubCategory = (data) => {
  const queryParam = queryString.stringify(data, { skipNull: true, skipEmptyString: true });
  return axios.delete(`${process.env.REACT_APP_API_URL}/safal-warranty/product-sub-category?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const createWarrantyProductSubCategory = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/safal-warranty/product-sub-category`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const updateWarrantyProductSubCategory = (data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/safal-warranty/product-sub-category`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

// safal-warranty-store-retailer

export const getWarrantyStoreRetailer = (data) => {
  const queryParam = queryString.stringify(data, { skipNull: true, skipEmptyString: true });
  return axios.get(`${process.env.REACT_APP_API_URL}/safal-warranty/retailer?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const deleteWarrantyStoreRetailer = (data) => {
  const queryParam = queryString.stringify(data, { skipNull: true, skipEmptyString: true });
  return axios.delete(`${process.env.REACT_APP_API_URL}/safal-warranty/retailer?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const createWarrantyStoreRetailer = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/safal-warranty/retailer`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const updateWarrantyStoreRetailer = (data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/safal-warranty/retailer`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

// safal-warranty-type

export const getWarrantyType = (data) => {
  const queryParam = queryString.stringify(data, { skipNull: true, skipEmptyString: true });
  return axios.get(`${process.env.REACT_APP_API_URL}/safal-warranty/warranty-type?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const deleteWarrantyType = (data) => {
  const queryParam = queryString.stringify(data, { skipNull: true, skipEmptyString: true });
  return axios.delete(`${process.env.REACT_APP_API_URL}/safal-warranty/warranty-type?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const createWarrantyType = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/safal-warranty/warranty-type`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const updateWarrantyType = (data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/safal-warranty/warranty-type`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

// safal-warranty-user

export const getWarrantyUser = (data) => {
  const queryParam = queryString.stringify(data, { skipNull: true, skipEmptyString: true });
  return axios.get(`${process.env.REACT_APP_API_URL}/safal-warranty/user?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const deleteWarrantyUser = (data) => {
  const queryParam = queryString.stringify(data, { skipNull: true, skipEmptyString: true });
  return axios.delete(`${process.env.REACT_APP_API_URL}/safal-warranty/user?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const createWarrantyUser = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/safal-warranty/user`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const updateWarrantyUser = (data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/safal-warranty/user`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const getWarrantyUserDownload = (filter) => {
  const queryParam = queryString.stringify({ ...filter, timeZone: getTimeZoneAbbreviation() }, { skipNull: true });
  return axios.get(`${process.env.REACT_APP_API_URL}/safal-warranty/user-download?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const createWarrantyUserBulk = (file) => {
  const bodyFormData = new FormData();
  bodyFormData.append('file', file);
  return axios.post(`${process.env.REACT_APP_API_URL}/safal-warranty/user-bulk`, bodyFormData, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const getWarrantyNotificationMaster = (filter) => {
  const queryParam = queryString.stringify(filter, { skipNull: true });
  return axios.get(`${process.env.REACT_APP_API_URL}/safal-warranty/notification-business?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const getWarrantyNotificationUser = (filter) => {
  const queryParam = queryString.stringify(filter, { skipNull: true });
  return axios.get(`${process.env.REACT_APP_API_URL}/safal-warranty/notification?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const updateWarrantyNotificationMaster = (data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/safal-warranty/notification-business`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const updateWarrantyNotificationUser = (data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/safal-warranty/notification`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const getWarrantyReports = (filter) => {
  const queryParam = queryString.stringify(filter, { skipNull: true });
  return axios.get(`${process.env.REACT_APP_API_URL}/safal-warranty/user-report?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};

export const getStripeTransactionsBusiness = (filter) => {
  const queryParam = queryString.stringify(filter, { skipNull: true, skipEmptyString: true });
  return axios.get(`${process.env.REACT_APP_API_URL}/payment/stripe-transactions?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
export const getStripeTransactionsBusinessDownload = (filter) => {
  const queryParam = queryString.stringify(filter, { skipNull: true, skipEmptyString: true });
  return axios.get(`${process.env.REACT_APP_API_URL}/payment/stripe-transactions-download?${queryParam}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('Jtoken')}`,
    },
  });
};
