/* eslint-disable no-else-return */
import moment from 'moment';
import { useEffect, useState } from 'react';

// Helper function to get nested value
const getNestedValue = (obj, path) => {
  return (
    path.split('.').reduce((value, key) => {
      return value[key] ?? '';
    }, obj) ?? ''
  );
};

const theSortFunction = (data, orderBy, order, dateKeys) => {
  // Define a function to compare values based on the specified orderBy parameter
  const compareValues = (a, b) => {
    const valueA = getNestedValue(a, orderBy);
    const valueB = getNestedValue(b, orderBy);

    if (dateKeys.includes(orderBy)) {
      // If orderBy is a date key, parse dates using Moment.js
      const dateA = valueA ? moment(valueA) : '';
      const dateB = valueB ? moment(valueB) : '';

      if (order === 'desc') {
        return dateB - dateA; // Descending order
      } else {
        return dateA - dateB; // Ascending order (default)
      }
    } else {
      // For string and number comparison
      const valA = typeof valueA === 'string' ? valueA.toLowerCase() : valueA;
      const valB = typeof valueB === 'string' ? valueB.toLowerCase() : valueB;

      if (order === 'desc') {
        if (valB < valA) {
          return -1;
        }
        if (valB > valA) {
          return 1;
        }
        return 0;
      } else {
        if (valA < valB) {
          return -1;
        }
        if (valA > valB) {
          return 1;
        }
        return 0;
      }
    }
  };

  // Sort the data array based on the compareValues function
  data.sort((a, b) => {
    return compareValues(a, b);
  });

  return data;
};

const useSortHook = (data, dateKeyNames) => {
  const [newSortData, setNewSortData] = useState([]);
  const [sortState, setSortState] = useState({
    orderBy: '', // asc - desc - ""
    order: '',
  });

  const dateKeys = dateKeyNames || [];

  // 1st click asc
  // 2nd click desc
  // 3rd click reset
  const handleSort = (newOrderBy, onSort) => {
    if (newOrderBy === sortState.orderBy) {
      switch (sortState.order) {
        case 'desc':
          setSortState({
            orderBy: '',
            order: '',
          });
          onSort &&
            onSort({
              orderBy: '',
              order: '',
            });
          setNewSortData([...data]);
          break;
        case 'asc':
          setSortState({
            orderBy: newOrderBy,
            order: 'desc',
          });
          onSort &&
            onSort({
              orderBy: newOrderBy,
              order: 'desc',
            });
          setNewSortData(theSortFunction([...data], newOrderBy, 'desc', dateKeys));
          break;
        default:
          setSortState({
            orderBy: newOrderBy,
            order: 'asc',
          });
          onSort &&
            onSort({
              orderBy: newOrderBy,
              order: 'asc',
            });
          setNewSortData(theSortFunction([...data], newOrderBy, 'asc', dateKeys));
          break;
      }
    } else {
      setSortState({
        orderBy: newOrderBy,
        order: 'asc',
      });
      onSort &&
        onSort({
          orderBy: newOrderBy,
          order: 'asc',
        });
      setNewSortData(theSortFunction(data, newOrderBy, 'asc', dateKeys));
    }
  };

  useEffect(() => {
    setNewSortData(data);
  }, [data]);

  return { sortState, handleSort, newSortData };
};

export default useSortHook;
