import { FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import toast from 'react-hot-toast';
import { useEffect, useState } from 'react';

import './WarrantyNotificationMaster.css';

import {
  getPlanList,
  getWarrantyNotificationMaster,
  updateWarrantyNotificationMaster,
} from '../../../services/Service';
import TableView from './TableView';

const WarrantyNotificationMaster = () => {
  const [planList, setPlanList] = useState([]);
  const [rawData, setRawData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [disableTableSwitch, setDisableTableSwitch] = useState(false);
  const [selectedTier, setSelectedTier] = useState('0');

  const fetchData = async () => {
    try {
      const res = await getWarrantyNotificationMaster();
      if (res.status === 200) {
        setRawData(res.data.data);
        const filterResult = res.data.data.filter((e) => e?.tier === parseInt(selectedTier, 10));
        setFilterData(filterResult);
      }
    } catch (err) {
      console.log('getSafalBuddyActivitiesMasterConfig fetchData error', err.message);
    }
  };

  const fetchPlanData = async () => {
    try {
      const res = await getPlanList();
      if (res.status === 200) {
        setPlanList(res.data.data);
      }
    } catch (err) {
      console.log('getPlanList fetchData error', err.message);
    }
  };

  useEffect(() => {
    fetchData();
    fetchPlanData();
  }, []);

  useEffect(() => {
    const filterResult = rawData.filter((e) => e?.tier === parseInt(selectedTier, 10));
    setFilterData(filterResult);
  }, [selectedTier]);

  const updateNotificationValue = async (data, key, newValue) => {
    setDisableTableSwitch(true);
    try {
      const payload = { ...data, notification: { ...data.notification, [key]: newValue } };
      const res = await updateWarrantyNotificationMaster(payload);
      if (res.status === 200) {
        toast.success(res.data.message);
        fetchData();
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
    } finally {
      setDisableTableSwitch(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Warranty Notification - SafalSubscriptions</title>
      </Helmet>

      <div id="WarrantyNotificationMaster">
        <div className="title-bar">
          <div className="title-search-div">
            <Typography variant="h4" sx={{ fontSize: '40px', fontWeight: 700, color: '#027c34' }}>
              Warranty Notification Master
            </Typography>
          </div>
        </div>
        <div className="toggle-bar">
          <div className="toggle-bar-dix">
            <FormControl size="small" sx={{ width: '200px', ml: '12px' }}>
              <InputLabel id="WarrantyNotificationMaster-selectedTier">Plan Tier</InputLabel>
              <Select
                labelId="WarrantyNotificationMaster-selectedTier"
                value={selectedTier}
                label="Plan Tier"
                onChange={(e) => setSelectedTier(e.target.value)}
              >
                {planList?.map((e, index) => (
                  <MenuItem key={index} value={e?.tier}>
                    Tier {e.tier} : {e.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="title-action-div" />
        </div>
        <div>
          <TableView
            disabled={disableTableSwitch}
            updateNotificationValue={updateNotificationValue}
            data={filterData}
          />
        </div>
      </div>
    </>
  );
};

export default WarrantyNotificationMaster;
