import React, { useState, useEffect, useRef } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import Swal from 'sweetalert2';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import moment from 'moment';

import { useNavigate } from 'react-router-dom';
import { Grid, Button } from '@mui/material';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { useDispatch, useSelector } from 'react-redux';
import { db } from '../../firebase';
import Page from '../../components/Page';
import { getNotificationBanner, DeleteBanner, getAssetDashboard, getUserAssessmentUser } from '../../services/Service';
import Attachment from '../../components/Attachments';
import UserAds from './Ads/UserAds';

import dimg1 from '../../assets/dashboard1.png';
import dimg2 from '../../assets/dashboard2.png';
import dimg3 from '../../assets/dashboard3.png';
import dimg4 from '../../assets/dashboard4.png';
import dimg5 from '../../assets/dashboard5.png';
import dimg6 from '../../assets/dashboard6.png';
import dimg7 from '../../assets/dashboard7.png';
import dimg8 from '../../assets/dashboard8.png';
import CustomNewDashboardBox from '../../components/UI/CustomNewDashboardBox';
import NewSubscriptionChart from '../../sections/Charts/NewSubscriptionChart';
import NewAssetsLimitChart from '../../sections/Charts/NewAssetsLimitChart';
import NewCommunicationChart from '../../sections/Charts/NewCommunicationChart';
import NewStorageChart from '../../sections/Charts/NewStorageChart';
import NewDownloadsUploadsChart from '../../sections/Charts/NewDownloadsUploadsChart';
import NewSafalAiLimitsChart from '../../sections/Charts/NewSafalAiLimitsChart';
import NewLabelLimitsChart from '../../sections/Charts/NewLabelLimitsChart';
import NewCallChart from '../../sections/Charts/NewCallChart';
import FaqModal from '../../components/Modals/FaqModal';
import DashboardNotificationsCarousel from '../../sections/DashboardCarousel/DashboardNotificationsCarousel';
import { getNotifications } from '../../store/loginSlice';
import UserAssessmentModal from '../../components/Modals/UserAssessmentModal';

const initDashboardInfo = {
  gift: {
    count: 0,
    value: 0,
    expire: 0,
  },
  reward: {
    count: 0,
    value: 0,
    expire: 0,
  },
  loyalty: {
    count: 0,
    value: 0,
    expire: 0,
  },
  assetLimits: {
    gift: {
      used: 0,
      limit: 0,
      isUnlimited: false,
    },
    reward: {
      used: 0,
      limit: 0,
      isUnlimited: false,
    },
    loyalty: {
      used: 0,
      limit: 0,
      isUnlimited: false,
    },
  },
  safalAi: {
    safalAi: {
      used: 0,
      limit: 0,
      isUnlimited: false,
    },
    safalAiGpt: {
      used: 0,
      limit: 0,
      isUnlimited: false,
    },
  },
};

export default function DashBoard() {
  const dispatch = useDispatch();
  const [carouselItem, setCarouselItem] = useState([]);
  const { SubscriptionData } = useSelector((state) => state.subscription);
  const dynamicMenuState = useSelector((state) => state.dynamicMenuState);
  const { user } = useSelector((state) => state.login);
  const [bannerData, setBannerData] = useState(null);
  const [dashboardInfo, setDashboardInfo] = useState(initDashboardInfo);
  const [isSubUnlimited, setIsSubUnlimited] = useState(false);

  const assetsDynamicMenuState = dynamicMenuState?.find((e) => e?.menu === 'assets');

  const subscriptionsDynamicMenuState = dynamicMenuState?.find((e) => e?.menu === 'subscriptions');

  const navigate = useNavigate();

  const fatchAssetDashboardInfo = async () => {
    try {
      const res = await getAssetDashboard();
      setDashboardInfo(res.data.data);
    } catch (err) {
      console.log('fatchAssetDashboardInfo error', err.message);
    }
  };

  useEffect(() => {
    const redirectLocal = localStorage.getItem('redirect');
    if (redirectLocal) {
      let qValue = {};
      try {
        qValue = JSON.parse(redirectLocal);
      } catch (err) {
        localStorage.removeItem('redirect');
        console.log(err);
      }
      if (qValue?.page === 'offers' && qValue?.type !== undefined && qValue?.id !== undefined) {
        const userRedirectLink = `/offers/${qValue?.type}/${qValue?.id}`;
        localStorage.removeItem('redirect');
        navigate(userRedirectLink);
      }
    }

    getNotificationBanner({ userId: user._id, limit: 20, orderBy: 'createdAt', order: 'DESC' }).then((res) =>
      setBannerData(res.data.data)
    );
    fatchAssetDashboardInfo();
    dispatch(getNotifications());
    fetchUserAssessmentUser();
  }, []);

  // User Assessment
  const [openUserAssessmentModal, setOpenUserAssessmentModal] = useState(false);
  const onCloseUserAssessmentModal = () => {
    setOpenUserAssessmentModal(false);
  };
  const fetchUserAssessmentUser = async () => {
    try {
      const UserAssessmentMonthlyFeedback = localStorage.getItem('UserAssessmentMonthlyFeedback');
      if (UserAssessmentMonthlyFeedback !== 'TRUE') return;
      localStorage.removeItem('UserAssessmentMonthlyFeedback');
      const res = await getUserAssessmentUser({ scenario: 'MonthlyFeedback' });
      if (res.data.data?.length > 0) {
        setOpenUserAssessmentModal(true);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const { currentUser } = useSelector((state) => state.auth);

  const closeAttachmentPopUpRef = useRef(() => {});

  const fireUser = currentUser?.uid;

  const getUserdetails = async () => {
    const docRef = doc(db, 'users', fireUser);
    const docSnap = await getDoc(docRef);
    console.log('DOC state', docSnap.data().isOnline);
    // setOnline(localStorage.setItem('mySTate', docSnap.data().isOnline));
    console.log('STATE', docSnap.data().isOnline);
    localStorage.setItem('status', docSnap.data().isOnline ? 'online' : 'offline');
  };

  const navigateHandler = (event) => {
    if (event.detail === 2) {
      navigate('/profile');
    }
  };

  const navigateSubHandler = (event) => {
    if (event.detail === 2) {
      navigate('/subscription');
    }
  };

  const totalVarianceBudgetHandler = (event) => {
    if (event.detail === 2) {
      navigate('/profile');
    } else {
      Swal.fire({
        title: 'Variance from Budget',
        text: `Total Annual Budget( ${user?.budget?.totalBudget}) -
        Total Cost Per Year (${user?.budget?.constPerYear}) = 
        Variance From Budget (${user?.budget?.varianceFromBudget})`,

        focusConfirm: false,
        confirmButtonText: 'Close',
        confirmButtonAriaLabel: 'Close',
      });
    }
  };

  useEffect(() => {
    getUserdetails();
  });

  const imgStyle = {
    height: '70px',
    width: '70px',
    borderRadius: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: '-18px',
    left: '20px',
  };

  const lineSyle = {
    fontSize: '16px',
    fontWeight: 700,
    pb: '7px',
    position: 'relative',
    pt: '22px',
    '::after': {
      position: 'absolute',
      content: '""',
      left: '0',
      bottom: '0',
      height: '3px',
      width: '60px',
      backgroundColor: '#C1C8F6',
    },
  };

  const handleDelete = (pos) => {
    const newItems = [...carouselItem];
    const caro = carouselItem[pos].id;
    console.log('caro', carouselItem[pos]);
    console.log('New Items', newItems);
    newItems.splice(pos, 1);
    setCarouselItem(newItems);
    caro && DeleteBanner(caro);
  };

  const varianceBudget = Math.sign(user?.budget?.varianceFromBudget);
  const totalBudgets = Math.sign(user?.budget?.totalBudget);
  const monthlyCost = Math.sign(user?.budget?.costPerMonth);
  const yearlyCost = Math.sign(user?.budget?.constPerYear);

  useEffect(() => {
    const banners = [];
    // if (Array.isArray(SubscriptionData)) {
    //   SubscriptionData?.forEach((data) => {
    //     const d1 = moment(new Date()).format('YYYY-MM-DD');
    //     const d2 = moment(data.nextBilling).format('YYYY-MM-DD');
    //     const diff = moment(d2).diff(d1, 'days');
    //     const expire = diff < 7 && diff > 0;

    //     expire &&
    //       banners.push({
    //         title: data.subscriptionName,
    //         msg: 'This SubScription is About to End!',
    //       });
    //   });
    // }
    if (Array.isArray(bannerData) && 'registered' in user) {
      bannerData.forEach((d) => {
        if (d.type === 'primaryEmailVerify') {
          banners.push({
            title: `${d.title}`,
            msg: d.message,
            id: d._id,
          });
        }
        if (d.type === 'addonEmailVerify') {
          banners.push({
            title: `${d.title}`,
            msg: d.message,
            id: d._id,
          });
        }
        if (d.type === 'promotions') {
          banners.push({
            title: `Promotion ${d.title}`,
            msg: d.message,
            id: d._id,
            attachments: d?.attachments,
          });
        }
        if (d.type === 'userChat') {
          banners.push({
            title: `Safalbot ${d.title}`,
            msg: d.message,
            id: d._id,
          });
        }
        if (d.type === 'userCall') {
          banners.push({
            title: `Safalbot ${d.title}`,
            msg: d.message,
            id: d._id,
          });
        }
        if (d.type === 'expiredOrRenew') {
          banners.push({
            title: `${d.title}`,
            msg: d.message,
            id: d._id,
            logo: d?.logo,
          });
        }
        if (d.type === 'inActiveUser') {
          banners.push({
            title: `In Active User ${d.title}`,
            msg: d.message,
            id: d._id,
          });
        }
        if (d.type === 'budgetExceed') {
          banners.push({
            title: `${d.title}`,
            msg: d.message,
            id: d._id,
          });
        }
        if (d.type === 'expireCard') {
          banners.push({
            title: `${d.title}`,
            msg: d.message,
            id: d._id,
            logo: d?.logo,
          });
        }
        if (d.type === 'general' && d.title) {
          banners.push({
            title: ` ${d.title}`,
            msg: d.message,
            id: d._id,
          });
        }
        if (d.type === 'offersExpire' && d.title) {
          banners.push({
            title: ` ${d.title}`,
            msg: d.message,
            id: d._id,
            logo: d?.logo,
          });
        }
        if (d.type === 'safalBuddy' && d.title) {
          banners.push({
            title: ` ${d.title}`,
            msg: d.message,
            id: d._id,
            // logo: d?.logo,
          });
        }
        if (d.type === 'safalLeaderBoard' && d.title) {
          banners.push({
            title: ` ${d.title}`,
            msg: d.message,
            id: d._id,
            // logo: d?.logo,
          });
        }
        if (d.type === 'warranty' && d.title) {
          banners.push({
            title: ` ${d.title}`,
            msg: d.message,
            id: d._id,
            // logo: d?.logo,
          });
        }
      });

      // !user.registered &&
      //   banners.push({
      //     title: `Please verify your email`,
      //     msg: `${user.email} is not verified.`,
      //   });

      // user.addOnEmails.forEach((d) => {
      //   if (!d.verified) {
      //     banners.push({
      //       title: `Please verify your addon email`,
      //       msg: `${d.email} is not verified.`,
      //     });
      //   }
      // });
    }
    setCarouselItem(banners);
  }, [SubscriptionData, user, bannerData]);

  const onAssetClick = (type) => {
    navigate('/asset');
  };

  const isReward = assetsDynamicMenuState && assetsDynamicMenuState.submenu.includes('reward certificate');
  const isGift = assetsDynamicMenuState && assetsDynamicMenuState.submenu.includes('gift card');
  const isLoyalty = assetsDynamicMenuState && assetsDynamicMenuState.submenu.includes('loylty program');

  const isAssetUnlimited =
    dashboardInfo?.assetLimits?.loyalty?.isUnlimited === true &&
    dashboardInfo?.assetLimits?.reward?.isUnlimited === true &&
    dashboardInfo?.assetLimits?.gift?.isUnlimited === true;

  const isAsset = isLoyalty === true && isGift === true && isReward === true;
  const CurrSymbol = user?.country === 'United States' ? '$' : '₹';

  const currentYearString = moment().format('YYYY');
  const currentMonthYearString = moment().format('MMMM YYYY');

  return (
    <>
      <Page title="DashBoard - SafalSubscriptions">
        <Grid container sx={{ display: 'flex', justifyContent: 'center' }}>
          <UserAds />
        </Grid>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: '12px',
            marginTop: '12px',
            marginBottom: '20px',
          }}
        >
          {/* Your Notifications */}
          {carouselItem.length !== 0 && (
            <Accordion defaultExpanded sx={{ backgroundColor: '#f2f2f2' }}>
              <AccordionSummary
                sx={{
                  fontSize: '14px',
                  alignItems: 'center',
                  fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                  fontWeight: 'bold',
                  backgroundColor: '#3d71ff',
                  borderRadius: '12px',
                  minHeight: '40px !important',
                  height: '40px !important',
                  color: 'white',
                }}
                expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
              >
                Your Notifications <FaqModal marginLeft="4px" module="Dashboard - Notification" />
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  backgroundColor: '#f2f2f2',
                  minHeight: 0,
                  height: '100px',
                  marginTop: '12px',
                }}
              >
                <DashboardNotificationsCarousel
                  data={carouselItem}
                  onDelete={handleDelete}
                  childref={closeAttachmentPopUpRef}
                />
              </AccordionDetails>
            </Accordion>
          )}
          {/* Your Subscription */}
          <Accordion
            defaultExpanded
            sx={{
              // backgroundColor: '#f2f2f2',
              backgroundColor: 'white',
              borderRadius: '12px',
              flex: 1,
              '&.Mui-expanded': {
                margin: 0,
                borderRadius: '12px',
              },
            }}
          >
            <AccordionSummary
              sx={{
                fontSize: '14px',
                alignItems: 'center',
                fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                fontWeight: 'bold',
                backgroundColor: '#3d71ff',
                borderRadius: '12px',
                minHeight: '40px !important',
                height: '40px !important',
                color: 'white',
              }}
              expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
            >
              Your Subscription <FaqModal marginLeft="4px" module="Dashboard - Subscriptions" />
            </AccordionSummary>
            <AccordionDetails
              sx={{
                marginTop: '16px',
                backgroundColor: 'white',
                minHeight: 0,
                padding: '0px',
              }}
            >
              <div style={{ display: 'flex', gap: '16px', flexWrap: 'wrap', backgroundColor: 'white' }}>
                {/* Subscriptions */}
                <Accordion
                  disabled
                  defaultExpanded
                  sx={{
                    backgroundColor: 'white !important',
                    flex: 1,
                    '&.Mui-expanded': {
                      margin: 0,
                    },
                  }}
                >
                  <AccordionSummary
                    sx={{
                      fontSize: '14px',
                      alignItems: 'center',
                      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                      fontWeight: 'bold',
                      backgroundColor: '#93B0FF',
                      borderRadius: '12px',
                      minHeight: '40px !important',
                      height: '40px !important',
                      color: '#3D71FF',
                      '&.Mui-disabled': {
                        opacity: 1,
                      },
                    }}
                  >
                    Subscriptions
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      backgroundColor: 'white !important',
                      minHeight: 0,
                      display: 'flex',
                      flexDirection: 'column',
                      padding: '8px',
                      // flexWrap: 'wrap',
                      alignItems: 'center',
                      gap: '20px',
                      // justifyContent: 'center',
                    }}
                  >
                    <NewSubscriptionChart onClick={navigateSubHandler} setUnlimited={() => setIsSubUnlimited(true)} />
                  </AccordionDetails>
                </Accordion>
                {/* Subscriptions Budget */}
                <Accordion
                  disabled
                  defaultExpanded
                  sx={{
                    height: '100%',
                    backgroundColor: 'white',
                    flex: 1,
                    '&.Mui-expanded': {
                      margin: 0,
                    },
                  }}
                >
                  <AccordionSummary
                    sx={{
                      fontSize: '14px',
                      alignItems: 'center',
                      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                      fontWeight: 'bold',
                      backgroundColor: '#93B0FF',
                      borderRadius: '12px',
                      minHeight: '40px !important',
                      height: '40px !important',
                      color: '#3D71FF',
                      '&.Mui-disabled': {
                        opacity: 1,
                      },
                    }}
                  >
                    Subscriptions Budget
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      backgroundColor: 'white',
                      minHeight: 0,
                      // height: '100px',
                      display: 'flex',
                      padding: '8px',
                      flexWrap: 'wrap',
                      gap: '12px',
                      justifyContent: 'center',
                    }}
                  >
                    {/* <NewBudgetChart /> */}
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '40px',
                        paddingTop: '20px',
                        padding: '20px',
                        flex: '1',
                      }}
                    >
                      <div
                        className="sm-flex-wrap-new"
                        style={{
                          display: 'flex',
                          // flexWrap: 'wrap',
                          // flexDirection: isSubUnlimited ? 'row' : 'column',
                          gap: '40px',
                          padding: '20px',
                          flex: '1',
                        }}
                      >
                        <CustomNewDashboardBox
                          minHeight="120px"
                          maxWidth="250px"
                          color="#837fe6"
                          img={dimg2}
                          title={`Planned annual budget ${currentYearString}`}
                          subtitle="Value"
                          value={`${CurrSymbol} ${user?.budget?.totalBudget?.toFixed(2)}`}
                          isRed={user?.budget?.totalBudget?.toFixed(2) < 0}
                          onClick={navigateSubHandler}
                        />
                        <CustomNewDashboardBox
                          minHeight="120px"
                          maxWidth="250px"
                          color="#67a878"
                          img={dimg1}
                          title={`Forecasted budget variance ${currentYearString}`}
                          subtitle="Value"
                          value={`${CurrSymbol} ${user?.budget?.varianceFromBudget?.toFixed(2)}`}
                          isRed={user?.budget?.varianceFromBudget?.toFixed(2) < 0}
                          isGreen={user?.budget?.varianceFromBudget?.toFixed(2) > 0}
                          onClick={totalVarianceBudgetHandler}
                        />
                      </div>
                      <div
                        className="sm-flex-wrap-new"
                        style={{
                          display: 'flex',
                          // flexDirection: isSubUnlimited ? 'row' : 'column',
                          // flexWrap: 'wrap',
                          gap: '40px',
                          padding: '20px',
                          flex: '1',
                        }}
                      >
                        <CustomNewDashboardBox
                          minHeight="120px"
                          maxWidth="250px"
                          color="#2588eb"
                          img={dimg7}
                          title={`Forecasted monthly cost ${currentMonthYearString}`}
                          subtitle="Value"
                          value={`${CurrSymbol} ${user?.budget?.costPerMonth?.toFixed(2)}`}
                          onClick={navigateSubHandler}
                        />
                        <CustomNewDashboardBox
                          minHeight="120px"
                          maxWidth="250px"
                          color="#ba68c8"
                          img={dimg4}
                          title={`Forecasted annual cost ${currentYearString}`}
                          subtitle="Value"
                          value={`${CurrSymbol} ${user?.budget?.constPerYear?.toFixed(2)}`}
                          onClick={navigateSubHandler}
                        />
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
            </AccordionDetails>
          </Accordion>

          {/* Your Assets */}
          <Accordion
            defaultExpanded
            sx={{
              borderRadius: '12px',
              backgroundColor: 'white',
              flex: 1,
              '&.Mui-expanded': {
                margin: 0,
                borderRadius: '12px',
              },
            }}
          >
            <AccordionSummary
              sx={{
                fontSize: '14px',
                alignItems: 'center',
                fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                fontWeight: 'bold',
                backgroundColor: '#3d71ff',
                borderRadius: '12px',
                minHeight: '40px !important',
                height: '40px !important',
                color: 'white',
              }}
              expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
            >
              Your Assets <FaqModal marginLeft="4px" module="Dashboard - Asset" />
            </AccordionSummary>
            <AccordionDetails
              sx={{
                backgroundColor: 'white',
                minHeight: 0,
                // height: '100px',
                display: 'flex',
                padding: '32px',
                alignItems: 'center',
                gap: '16px',
                flexWrap: 'wrap',
                overflow: 'hidden',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  // justifyContent: isAssetUnlimited && 'space-evenly',
                  flexDirection: isAssetUnlimited ? 'row' : 'column',
                  gap: !isAssetUnlimited && '40px',
                  flex: '1',
                }}
              >
                <div
                  className="sm-flex-wrap-new"
                  style={{
                    width: isAssetUnlimited && '100%',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '40px',
                    justifyContent: isAssetUnlimited && 'space-evenly',
                    // flexWrap: isAssetUnlimited && 'wrap',
                  }}
                >
                  {isGift && (
                    <CustomNewDashboardBox
                      onClick={onAssetClick}
                      minHeight="120px"
                      color="#2588eb"
                      img={dimg5}
                      title="Gift Card"
                      subtitle="Value"
                      value={`${CurrSymbol} ${dashboardInfo.gift.value?.toFixed(2)}`}
                      count={dashboardInfo.gift.count}
                      isUnlimited={dashboardInfo?.assetLimits?.gift?.isUnlimited}
                    />
                  )}
                  {isReward && (
                    <CustomNewDashboardBox
                      onClick={onAssetClick}
                      minHeight="120px"
                      color="#ba68c8"
                      img={dimg6}
                      title="Reward Certificate"
                      subtitle="Value"
                      value={`${CurrSymbol} ${dashboardInfo.reward.value?.toFixed(2)}`}
                      count={dashboardInfo.reward.count}
                      isUnlimited={dashboardInfo?.assetLimits?.reward?.isUnlimited}
                    />
                  )}
                  {isAssetUnlimited && isLoyalty && (
                    <CustomNewDashboardBox
                      onClick={onAssetClick}
                      minHeight="120px"
                      color="#837fe6"
                      img={dimg3}
                      title="Loyalty card"
                      subtitle="Points"
                      value={`${dashboardInfo.loyalty.value}`}
                      count={dashboardInfo.loyalty.count}
                      isUnlimited={dashboardInfo?.assetLimits?.loyalty?.isUnlimited}
                    />
                  )}
                  {isAssetUnlimited && (
                    <CustomNewDashboardBox
                      onClick={onAssetClick}
                      minHeight="120px"
                      mix
                      color="#a3a700"
                      img={dimg8}
                      title="Expiry in 7 days"
                      value1={dashboardInfo.loyalty.expire}
                      value2={dashboardInfo.gift.expire}
                      value3={dashboardInfo.reward.expire}
                    />
                  )}
                </div>
                {!isAssetUnlimited && (
                  <div
                    className="sm-flex-wrap-new"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '40px',
                      margin: isAssetUnlimited && '0px auto',
                    }}
                  >
                    {isLoyalty && (
                      <CustomNewDashboardBox
                        onClick={onAssetClick}
                        minHeight="120px"
                        color="#837fe6"
                        img={dimg3}
                        title="Loyalty card"
                        subtitle="Points"
                        value={`${dashboardInfo.loyalty.value}`}
                        count={dashboardInfo.loyalty.count}
                        isUnlimited={dashboardInfo?.assetLimits?.loyalty?.isUnlimited}
                      />
                    )}
                    <CustomNewDashboardBox
                      onClick={onAssetClick}
                      minHeight="120px"
                      mix
                      color="#a3a700"
                      img={dimg8}
                      title="Expiry in 7 days"
                      value1={dashboardInfo.loyalty.expire}
                      value2={dashboardInfo.gift.expire}
                      value3={dashboardInfo.reward.expire}
                    />
                  </div>
                )}
              </div>
              {!isAssetUnlimited && <NewAssetsLimitChart data={dashboardInfo} />}
            </AccordionDetails>
          </Accordion>
          {/* Your Platform Pulse */}
          <Accordion
            defaultExpanded
            sx={{
              borderRadius: '12px',
              backgroundColor: 'white',
              flex: 1,
              '&.Mui-expanded': {
                margin: 0,
                borderRadius: '12px',
              },
            }}
          >
            <AccordionSummary
              sx={{
                fontSize: '14px',
                alignItems: 'center',
                fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                fontWeight: 'bold',
                backgroundColor: '#3d71ff',
                borderRadius: '12px',
                minHeight: '40px !important',
                height: '40px !important',
                color: 'white',
              }}
              expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
            >
              Your Platform Pulse <FaqModal marginLeft="4px" module="Dashboard - Platform Pulse" />
            </AccordionSummary>
            <AccordionDetails
              sx={{
                marginTop: '16px',
                backgroundColor: 'white',
                minHeight: 0,
                padding: '0px',
              }}
            >
              <div style={{ display: 'flex', gap: '16px', flexWrap: 'wrap' }}>
                {/* Your Communication */}
                <Accordion
                  disabled
                  defaultExpanded
                  sx={{
                    height: '100%',
                    backgroundColor: 'white',
                    flex: 1,
                    '&.Mui-expanded': {
                      margin: 0,
                    },
                  }}
                >
                  <AccordionSummary
                    sx={{
                      fontSize: '14px',
                      alignItems: 'center',
                      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                      fontWeight: 'bold',
                      backgroundColor: '#93B0FF',
                      borderRadius: '12px',
                      minHeight: '40px !important',
                      height: '40px !important',
                      color: '#3D71FF',
                      '&.Mui-disabled': {
                        opacity: 1,
                      },
                    }}
                  >
                    Your Communication
                  </AccordionSummary>
                  <AccordionDetails
                    className="sm-flex-wrap-center"
                    sx={{
                      backgroundColor: 'white',
                      minHeight: 0,
                      justifyContent: 'space-evenly',
                      // height: '100px',
                      display: 'flex',
                      padding: '8px',
                      gap: '12px',
                    }}
                  >
                    <NewCommunicationChart />
                  </AccordionDetails>
                </Accordion>
                {/* Your Storage */}
                <Accordion
                  disabled
                  className="w-full-720"
                  defaultExpanded
                  sx={{
                    backgroundColor: 'white',
                    flex: 'none',
                    '&.Mui-expanded': {
                      margin: 0,
                    },
                  }}
                >
                  <AccordionSummary
                    sx={{
                      fontSize: '14px',
                      alignItems: 'center',
                      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                      fontWeight: 'bold',
                      backgroundColor: '#93B0FF',
                      borderRadius: '12px',
                      minHeight: '40px !important',
                      height: '40px !important',
                      color: '#3D71FF',
                      '&.Mui-disabled': {
                        opacity: 1,
                      },
                    }}
                  >
                    Your Storage
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      backgroundColor: 'white',
                      minHeight: 0,
                      // height: '100px',
                      display: 'flex',
                      padding: '8px 100px',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <NewStorageChart />
                  </AccordionDetails>
                </Accordion>
              </div>
              <div style={{ display: 'flex', gap: '16px', flexWrap: 'wrap' }}>
                {/* Your Upload & Download */}
                <Accordion
                  disabled
                  defaultExpanded
                  sx={{
                    backgroundColor: 'white',
                    flex: 1,
                    '&.Mui-expanded': {
                      margin: 0,
                    },
                  }}
                >
                  <AccordionSummary
                    sx={{
                      fontSize: '14px',
                      alignItems: 'center',
                      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                      fontWeight: 'bold',
                      backgroundColor: '#93B0FF',
                      borderRadius: '12px',
                      minHeight: '40px !important',
                      height: '40px !important',
                      color: '#3D71FF',
                      '&.Mui-disabled': {
                        opacity: 1,
                      },
                    }}
                  >
                    Your Upload & Download
                  </AccordionSummary>
                  <AccordionDetails
                    className="sm-flex-wrap-center"
                    sx={{
                      backgroundColor: 'white',
                      minHeight: 0,
                      // height: '100px',
                      display: 'flex',
                      padding: '8px',
                      gap: '12px',
                      justifyContent: 'space-evenly',
                      // flexWrap: 'wrap',
                    }}
                  >
                    <NewDownloadsUploadsChart />
                  </AccordionDetails>
                </Accordion>
                {/* Your Safal AI */}
                <Accordion
                  disabled
                  className="w-full-720"
                  defaultExpanded
                  sx={{
                    backgroundColor: 'white',
                    flex: 'none',
                    '&.Mui-expanded': {
                      margin: 0,
                    },
                  }}
                >
                  <AccordionSummary
                    sx={{
                      fontSize: '14px',
                      alignItems: 'center',
                      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                      fontWeight: 'bold',
                      backgroundColor: '#93B0FF',
                      borderRadius: '12px',
                      minHeight: '40px !important',
                      height: '40px !important',
                      color: '#3D71FF',
                      '&.Mui-disabled': {
                        opacity: 1,
                      },
                    }}
                  >
                    Your Safal AI
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      backgroundColor: 'white',
                      minHeight: 0,
                      // height: '100px',
                      display: 'flex',
                      padding: '8px 100px',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <NewSafalAiLimitsChart data={dashboardInfo} />
                  </AccordionDetails>
                </Accordion>
              </div>
              <div style={{ display: 'flex', gap: '16px', flexWrap: 'wrap' }}>
                {/* Your Audio Call & Your Video Call */}
                <Accordion
                  disabled
                  defaultExpanded
                  sx={{
                    backgroundColor: 'white',
                    flex: 1,
                    '&.Mui-expanded': {
                      margin: 0,
                    },
                  }}
                >
                  <AccordionSummary
                    sx={{
                      fontSize: '14px',
                      alignItems: 'center',
                      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                      fontWeight: 'bold',
                      backgroundColor: '#93B0FF',
                      borderRadius: '12px',
                      minHeight: '40px !important',
                      height: '40px !important',
                      color: '#3D71FF',
                      '&.Mui-disabled': {
                        opacity: 1,
                      },
                    }}
                  >
                    Your Audio & Video Call
                  </AccordionSummary>
                  <AccordionDetails
                    className="sm-flex-wrap-center"
                    sx={{
                      backgroundColor: 'white',
                      minHeight: 0,
                      // height: '100px',
                      display: 'flex',
                      padding: '8px',
                      gap: '12px',
                      justifyContent: 'space-evenly',
                      // flexWrap: 'wrap',
                    }}
                  >
                    <NewCallChart />
                  </AccordionDetails>
                </Accordion>
                {/* Your Subscriptions Label */}
                <Accordion
                  disabled
                  className="w-full-720"
                  defaultExpanded
                  sx={{
                    backgroundColor: 'white',
                    flex: 'none',
                    '&.Mui-expanded': {
                      margin: 0,
                    },
                  }}
                >
                  <AccordionSummary
                    sx={{
                      fontSize: '14px',
                      alignItems: 'center',
                      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                      fontWeight: 'bold',
                      backgroundColor: '#93B0FF',
                      borderRadius: '12px',
                      minHeight: '40px !important',
                      height: '40px !important',
                      color: '#3D71FF',
                      '&.Mui-disabled': {
                        opacity: 1,
                      },
                    }}
                  >
                    Your Subscriptions Label
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      backgroundColor: 'white',
                      minHeight: 0,
                      // height: '100px',
                      display: 'flex',
                      padding: '8px 100px',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <NewLabelLimitsChart data={dashboardInfo} />
                  </AccordionDetails>
                </Accordion>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      </Page>
      <UserAssessmentModal
        scenario="MonthlyFeedback"
        open={openUserAssessmentModal}
        onClose={onCloseUserAssessmentModal}
      />
    </>
  );
}

const AttachmentHOC = (props) => {
  const attachmentRef = useRef(null);
  const [openDownload, setOpenDownload] = useState(false);

  const handleClickOutside = (event) => {
    if (attachmentRef.current && !attachmentRef.current.contains(event.target)) {
      handleCloseDownload();
    }
  };
  useEffect(() => {
    console.log(' props.childref ================>', props);
    props.childref.current = handleCloseDownload;
  }, []);

  useEffect(() => {
    window.addEventListener('click', handleClickOutside);

    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleClickDownload = (event) => {
    setOpenDownload(event?.currentTarget);
  };

  const handleCloseDownload = () => {
    setOpenDownload(null);
  };
  return (
    <div ref={attachmentRef}>
      <Button onClick={(e) => handleClickDownload(e)}>
        <Attachment
          openDownload={openDownload}
          setOpenDownload={setOpenDownload}
          handleCloseDownload={handleCloseDownload}
          {...props}
        />
      </Button>
    </div>
  );
};
