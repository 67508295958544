import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';

import {
  CustomTableCellForBody,
  CustomTableCellForNoData,
  CustomTableView,
} from '../../../components/UI/CustomTableView';
import useUserTimeZone, { getTimeZoneAbbreviation } from '../../../hooks/useUserTimeZone';
import ThreeDotMenu from '../../../components/UI/ThreeDotMenu';
import useSortHook from '../../../hooks/useSortHook';

const tableCellPStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '250px',
  maxHeight: '100px',
};

const TableView = ({ data, onDisplay }) => {
  const { formatDateTime } = useUserTimeZone();
  const { handleSort, sortState, newSortData } = useSortHook(data, ['createdAt']);

  const headData = ['Invoice No', 'Name', 'Amount', 'Status', 'Created Date', 'Actions'];

  return (
    <TableContainer>
      <CustomTableView
        isSort
        sort={{
          order: sortState.order,
          orderBy: sortState.orderBy,
          createSortHandler: (ex) => handleSort(ex),
          sortHeadMap: ['invoice_number', 'fullName', 'invoice_amount_due', 'invoice_status', 'createdAt', ''],
        }}
        headData={headData}
      >
        {newSortData?.length === 0 && <CustomTableCellForNoData message="You Don't have any data." />}
        {newSortData?.map((e, index) => (
          <TableRow key={index}>
            <CustomTableCellForBody align="center">
              <p style={tableCellPStyle}>{e?.invoice_number}</p>
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              <p style={tableCellPStyle}>{e?.fullName}</p>
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              <p style={tableCellPStyle}>
                {e?.invoice_amount_due !== '' ? e?.invoice_amount_due / 100 : ''} {e?.invoice_currency?.toUpperCase()}
              </p>
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              <p style={tableCellPStyle}>{e?.invoice_status}</p>
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              <p style={tableCellPStyle}>
                {formatDateTime(e?.createdAt)} {getTimeZoneAbbreviation()}
              </p>
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              <ThreeDotMenu onDisplay={() => onDisplay(e)} />
            </CustomTableCellForBody>
          </TableRow>
        ))}
      </CustomTableView>
    </TableContainer>
  );
};

export default TableView;
