import { TextField, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
// import AddIcon from '@mui/icons-material/Add';
// import toast from 'react-hot-toast';
import toast from 'react-hot-toast';
import { useEffect, useState } from 'react';

import './StripeTransactionsBusiness.css';

import {
  getSignedURL,
  getStripeTransactionsBusiness,
  getStripeTransactionsBusinessDownload,
} from '../../../services/Service';
// import CustomButton from '../../../components/UI/CustomButton';
import TableView from './TableView';
import StripeTransactionsBusinessModal from './StripeTransactionsBusinessModal';
import Inlineloader from '../../../components/Loader/InlineLoader';
import DownloadMenu from './DownloadMenu';

const StripeTransactionsBusiness = () => {
  const [modalOpen, setModalOpen] = useState({
    data: null,
    open: false,
    isDisplay: false,
  });
  const [rawData, setRawData] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [filter, setFilter] = useState({
  //   dateFrom: '',
  //   dateTo: '',
  // });

  const fetchData = async (flag) => {
    try {
      !flag && setLoading(true);
      const res = await getStripeTransactionsBusiness();
      if (res.status === 200) {
        setRawData(res.data.data);
      }
    } catch (err) {
      console.log('fetchData error', err.message);
    } finally {
      !flag && setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onDisplay = (data) => {
    setModalOpen({
      open: true,
      data: data,
      isDisplay: true,
    });
  };
  const onModalClose = (data) => {
    setModalOpen({
      data: null,
      open: false,
      isDisplay: false,
    });
  };

  // useDebounce(
  //   () => {
  //     setFilter((prev) => ({ ...prev, search: searchValue }));
  //   },
  //   [searchValue],
  //   1500
  // );

  const downloadFile = async (type) => {
    try {
      const res = await getStripeTransactionsBusinessDownload({ fileType: type });
      const signedURLRes = await getSignedURL({
        url: res.data.url,
      });
      const link = document.createElement('a');
      link.href = signedURLRes.data;
      link.setAttribute('download', `file`);
      document.body.appendChild(link);
      link.click();

      toast.success('download successful');
    } catch (err) {
      const msg = err?.response?.data?.message || 'download failed, please try again';
      toast.error(msg);
    }
  };

  return (
    <>
      <Helmet>
        <title>Payment Transactions - SafalSubscriptions</title>
      </Helmet>
      {loading && <Inlineloader />}
      <div id="StripeTransactionsBusiness">
        <div className="title-bar">
          <div className="title-search-div">
            <Typography variant="h4" sx={{ fontSize: '40px', fontWeight: 700, color: '#027c34' }}>
              Payment Transactions
            </Typography>
          </div>
        </div>
        <div className="toggle-bar">
          <div className="toggle-bar-dix" />
          <div className="title-action-div">
            {/* <TextField
              sx={{ width: '200px', ml: '12px' }}
              onChange={(e) => setSearchValue(e.target.value)}
              value={searchValue}
              type="search"
              id="search-input"
              size="small"
              label="Search"
              variant="outlined"
            /> */}
            <DownloadMenu downloadFile={downloadFile} />
          </div>
        </div>
        <TableView onDisplay={onDisplay} data={rawData} />
      </div>
      <StripeTransactionsBusinessModal data={modalOpen?.data} onClose={onModalClose} open={modalOpen?.open} />
    </>
  );
};

export default StripeTransactionsBusiness;
